import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../Button/Button'
import PlanningCard from './PlanningCard/PlanningCard'
import RecommendationModal from '../../RecommendationModal/RecommendationModal'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px;
  background-color: #ffffff;
  border-radius: 15px;
  border: 2px solid #e3e9ef;
  width: 250px;
  height: 322px;
  text-align: left;
  position: relative;

  &::before {
    content: '';
    display: block;
    margin-left: -2px;
    background-color: #00bfff;
    overflow: hidden;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 4px;
    height: 45px;
    position: absolute;
    left: 0;
  }

  @media only screen and (max-width: 920px) {
    height: 280px;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f5993;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  svg {
    height: 20px;
    fill: white;
  }
`

const Title = styled.h1`
  font-weight: 900;
  margin-left: 16px;
  font-size: 12px;
  flex: 1;
  line-height: 1;
  text-transform: uppercase;
  color: #244d85;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 21px;
  margin-top: 20px;
  color: #4b5882;
  font-size: 14px;
  font-weight: 500;
`

const Estimation = styled.p`
  margin-bottom: 8px;
  background-color: #e5eefa;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  margin-left: -27px;
  padding-left: 27px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  height: 20px;

  @media only screen and (max-width: 920px) {
    margin-bottom: 16px;
  }
`

const Paragraph = styled.p`
  margin-bottom: 8px;

  @media only screen and (max-width: 920px) {
    margin-bottom: 16px;
  }
`

const Status = styled.p`
  --red: #ff4140;
  --yellow: #f3b349;

  font-size: 12px;
  font-weight: 900;
  background-color: ${p => (p.highPriority ? 'var(--red)' : 'var(--yellow)')};
  padding: 4px 15px;
  border-radius: 15px;
  color: #fff;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  text-transform: uppercase;
  position: absolute;
  top: -2px;
  right: -2px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const GeneralCard = ({
  id,
  title,
  description,
  Icon,
  status,
  recStatus,
  estimation,
  activeCard,
  setActiveCard,
  awsPillar,
  shortDescription,
  highPriority,
}) => {
  const history = useHistory()
  const { state } = useLocation()
  const [isOpen, setOpen] = useState(false)
  const toggle = () => {
    setOpen(open => !open)
    history.replace()
  }

  useEffect(() => {
    if (state?.rec?.id !== id) return
    setOpen(true)
    // eslint-disable-next-line
  }, [state?.rec?.id])

  return (
    <Card className="rec-card">
      {highPriority ? (
        <Status highPriority={true}>High Priority</Status>
      ) : (
        status && <Status>{status}</Status>
      )}
      <Row>
        <IconContainer>
          <Icon />
        </IconContainer>
        <Title>{title}</Title>
      </Row>
      <Description>
        <Paragraph dangerouslySetInnerHTML={{ __html: shortDescription }} />
        {estimation && <Estimation>Estimated {estimation} hours</Estimation>}
        <Button className="rec-view-details-btn" isTertiary onClick={toggle}>
          View details
        </Button>
      </Description>
      <Button
        className="rec-plan-btn"
        onClick={() => setActiveCard(title)}
        style={{ marginTop: 'auto' }}
        isLarge
      >
        Plan this with Trek10
      </Button>
      {isOpen && (
        <RecommendationModal
          isOpen={isOpen}
          toggle={toggle}
          id={id}
          title={title}
          estimation={estimation}
          shortDescription={shortDescription}
          awsPillar={awsPillar}
          description={description}
          status={status}
          recStatus={recStatus}
          Icon={Icon}
        />
      )}
      {activeCard === title && (
        <PlanningCard
          id={id}
          setActiveCard={setActiveCard}
          title={title}
          description={description}
          shortDescription={shortDescription}
          status={status}
          estimation={estimation}
          awsPillar={awsPillar}
          Icon={Icon}
        />
      )}
    </Card>
  )
}

export default GeneralCard
