import { useState } from 'react'
import { toast } from 'react-toastify'
import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Link = styled.a`
  cursor: pointer;
  pointer-events: ${p => p.isLoading ? 'none' : ''};
  outline: none;
  border: none;
  background-color: #2853f6;
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 6px 14px;
  transition: 0.2s;

  display: flex;

  ${p =>
    p.isLoading &&
    css`
      &::before {
        content: '';
        margin-right: 5px;
        border: 2px solid #fff;
        border-radius: 50%;
        border-top: 2px solid #00bfff;
        width: 12px;
        height: 12px;
        animation: ${spin} 2s linear infinite;
      }
    `}

  &:hover {
    filter: brightness(110%);
  }
`

const DocLink = ({ handler, children: btnText, ...rest }) => {
  const [loading, setLoading] = useState(false)
  const notifyErrorMsg = () => toast.error(`Failed to ${btnText}`)

  const handleClick = async () => {
    try {
      setLoading(true)
      await handler()
      setLoading(false)
    } catch (err) {
      notifyErrorMsg()
      setLoading(false)
    }
  }

  return <Link onClick={handleClick} isLoading={loading} {...rest}>{btnText}</Link>
}

export default DocLink
