import React from 'react'
import ReactDOM from 'react-dom'
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { getToken } from './utils/storage'
import '../src/assets/fonts/fonts.css'
import Global from './components/Global/Global'
import AuthContext from './context/AuthContext'
import useAuthState from './hooks/useAuthState'

import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

const toLink = (handler) => typeof handler === 'function' ? new ApolloLink(handler) : handler

const awsLink = createAuthLink({
  url: process.env.REACT_APP_API_ENDPOINT,
  region: process.env.REACT_APP_API_REGION,
  auth: {
    type: 'OPENID_CONNECT',
    jwtToken: () => getToken(),
  },
})

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_ENDPOINT })
const recommendationsHttpLink = createHttpLink({ uri: process.env.REACT_APP_API_RECOMMENDATIONS_ENDPOINT })
const documentsHttpLink = createHttpLink({ uri: process.env.REACT_APP_API_DOCUMENTS_ENDPOINT })
const reportsHttpLink = createHttpLink({ uri: process.env.REACT_APP_API_REPORT_ENDPOINT })
const prioritiesHttpLink = createHttpLink({ uri: process.env.REACT_APP_API_PRIORITIES_ENDPOINT })

const mainLink = ApolloLink.from([awsLink, httpLink])
const recommendationsLink = ApolloLink.from([awsLink, recommendationsHttpLink])
const documentsLink = ApolloLink.from([awsLink, documentsHttpLink])
const reportsLink = ApolloLink.from([awsLink, reportsHttpLink])
const prioritiesLink = ApolloLink.from([awsLink, prioritiesHttpLink])


const client = new ApolloClient({
  link: new ApolloLink((operation) => {
    const context = operation.getContext()

    switch (context.version) {
      case 1:
        return toLink(recommendationsLink).request(operation)
      case 2:
        return toLink(documentsLink).request(operation)
      case 3:
        return toLink(reportsLink).request(operation)
      case 4:
        return toLink(prioritiesLink).request(operation)
      default:
        return toLink(mainLink).request(operation)
    }
  }),
  cache: new InMemoryCache({
    typePolicies: {
      ZendeskTicketField: {
        keyFields: false
      }
    }
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    }
  }
})

const MainApp = () => {
  const value = useAuthState()

  return (
    <AuthContext.Provider value={value}>
      <Global />
      <App />
    </AuthContext.Provider>
  )
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <MainApp />
    </Router>
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
