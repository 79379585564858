import { useState, useEffect } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import { parseUrl } from 'query-string'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import SideNav from './components/SideNav/SideNav'
import Header from './components/Header/Header'
import MobileHeader from './components/Header/MobileHeader'
import Footer from './components/Footer/Footer'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'

import SignIn from './components/SignIn/SignIn'
import SignOut from './components/SignOut/SignOut'
import Welcome from './components/Welcome/Welcome'

import Dashboard from './pages/Dashboard/Dashboard'
import Recommendations from './pages/Recommendations/Recommendations'
import PrioritiesBoard from './pages/PrioritiesBoard'
import SupportCases from './pages/SupportCases'
import Documents from './pages/Documents/Documents'
import Users from './pages/Users'
import EditProfile from './components/EditProfileForm/EditProfile'
import useWindowSize from './hooks/useWindowSize'
import useAuth from './hooks/useAuth'
import CollapseContext from './context/CollapseContext'
import BoardContext from './context/BoardContext'
import Reports from './pages/Reports'
import { getZendeskTicketId } from './utils'
import getListStatuses from './graphql/getListStatuses'
// Not using Google Analytics right now
//import ReactGA from 'react-ga'
//import routeChangeTracker from './routeChangeTracker';

const Col = styled.div`
  display: flex;
  width: 75vw;
  flex-direction: column;
  flex-grow: 1;

  @media only screen and (max-width: 920px) {
    width: 100%;
  }
`

const StyledToastContainer = styled(ToastContainer)`
  --red: #ff4140;
  --green: #00c866;
  --blue: #00bfff;
  --yellow: #f7b500;

  width: max-content !important;
  font-size: 14px !important;

  .Toastify__toast--info {
    background-color: var(--blue);
  }

  .Toastify__toast--success {
    background-color: var(--green);
  }

  .Toastify__toast--warning {
    background-color: var(--yellow);
  }

  .Toastify__toast--error {
    background-color: var(--red);
  }
`

const App = () => {
  const [collapsed, setCollapsed] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowSize()
  const { user, isAuthenticated, selectedOrg } = useAuth()
  const [fetchListStatuses, { data }] = useLazyQuery(getListStatuses, {
    variables: { organizationId: selectedOrg.value },
    context: { version: 4 }
  })

  const isMobile = width < 921;

  // Pendo initialization snippet
  (function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  }   )('1eab8def-c491-4771-4635-f955653a0900');
  if (isAuthenticated) {
    window.pendo?.initialize({
      visitor: {
        id: user['https://harbortrek10/users']['impersonated'] === true ?  "impersonator:" + user['https://harbortrek10/users']['impersonated_by']['user_id'].split('|')[1] :  user['https://harbortrek10/users'].id,
        firstName: user['https://harbortrek10/users'].firstName,
        lastName: user['https://harbortrek10/users'].lastName,
        organizationIDs: user['https://harbortrek10/organizations'].map((o) => { return o.organization.id}).join(','),
        organizationNames: user['https://harbortrek10/organizations'].map((o) => { return o.organization.name}).join(','),
      }
    });
  }

  useEffect(() => {
    const { query: { return_to: url } } = parseUrl(decodeURIComponent(location.search))
    const ticketId = getZendeskTicketId(url)

    if (ticketId) return history.push(`/support-cases/${ticketId}`)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isAuthenticated && selectedOrg.value) fetchListStatuses()
    // eslint-disable-next-line
  }, [isAuthenticated, selectedOrg.value])

  // Google Analytics initialization
  // const trackingId = "264342275";
  // ReactGA.initialize(trackingId);
  // if (isAuthenticated) {
  //   ReactGA.set({
  //     userId: user['https://harbortrek10/users'].id,
  //   })
  // }
  return (
    <CollapseContext.Provider value={{ collapsed, setCollapsed }}>
      <BoardContext.Provider value={data?.listStatuses?.items || []}>
        <div className='appWrapper'>
          {!isMobile && isAuthenticated && <SideNav />}
          <Col>
            {!isAuthenticated ? null : isMobile ? <MobileHeader /> : <Header />}
            <Switch>
              <ProtectedRoute exact path='/'>
                <Dashboard />
              </ProtectedRoute>
              <ProtectedRoute path='/recommendations'>
                <Recommendations />
              </ProtectedRoute>
              <ProtectedRoute path='/priority-board'>
                <PrioritiesBoard />
              </ProtectedRoute>
              <ProtectedRoute path='/support-cases'>
                <SupportCases />
              </ProtectedRoute>
              <ProtectedRoute path='/documents'>
                <Documents />
              </ProtectedRoute>
              <ProtectedRoute path='/users'>
                <Users />
              </ProtectedRoute>
              <ProtectedRoute path='/profile'>
                <EditProfile />
              </ProtectedRoute>
              <ProtectedRoute path='/reports'>
                <Reports />
              </ProtectedRoute>
              <Route path='/sign-in'>
                <SignIn />
              </Route>
              <Route path='/logged-out'>
                <SignOut />
              </Route>
              <Route path='/welcome'>
                <Welcome />
              </Route>
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            </Switch>
            {isAuthenticated && <Footer />}
          </Col>
          <StyledToastContainer
            position='bottom-left'
            autoClose='3000'
            hideProgressBar={true}
            pauseOnHover={true}
          />
        </div>
        {/* Quill element to be instantiated and used to convert html to delta
        <div style={{ display: 'none' }} className='quill-instance'/> */}
      </BoardContext.Provider>
    </CollapseContext.Provider>
  )
}

export default App
