const CostIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="70 70 320 340"
    >
      <path
        d="M213.7 404.3c26.4 0 46.9-2.5 63.3-7.7-.3-1.9-.6-3.8-.6-5.7 0-8.8 3.7-16.7 9.5-22.4-5.8-5.7-9.5-13.6-9.5-22.4s3.7-16.7 9.5-22.4c-5.8-5.7-9.5-13.6-9.5-22.4s3.7-16.7 9.5-22.4c-5.8-5.7-9.5-13.6-9.5-22.4 0-10.4 5.1-19.5 12.9-25.2-5.3-4.9-11-9.3-17-13.1 2.4-3.1 4.1-6.8 4.1-11 0-9.9-8-17.9-17.9-17.9h-89.7c-9.9 0-17.9 8-17.9 17.9 0 4.2 1.7 7.9 4.1 10.9-34.8 22.4-58.5 63-58.5 105.6.1 72.2 67.2 80.6 117.2 80.6zm-.1-87.1c-14.8 0-26.9-12.1-26.9-26.9 0-11.7 7.6-21.6 18-25.3v-13.2c0-5 4-9 9-9s9 4 9 9v13.3c10.4 3.7 17.8 13.6 17.8 25.2 0 5-4 9-9 9s-9-4-9-9c0-4.9-4-9-9-9-4.9 0-9 4-9 9s4 9 9 9c14.8 0 26.9 12.1 26.9 26.9 0 11.6-7.5 21.5-17.8 25.2v8c0 5-4 9-9 9s-9-4-9-9v-8c-10.5-3.7-18-13.6-18-25.3 0-5 4-9 9-9s9 4 9 9c0 4.9 4 9 9 9 4.9 0 9-4 9-9 0-4.8-4-8.9-9-8.9zM389.2 377.4h-81.3c-7.4 0-13.4 6-13.4 13.4s6 13.4 13.4 13.4h81.3c7.4 0 13.4-6 13.4-13.4s-6-13.4-13.4-13.4zM389.2 332.5h-81.3c-7.4 0-13.4 6-13.4 13.4s6 13.4 13.4 13.4h81.3c7.4 0 13.4-6 13.4-13.4 0-7.3-6-13.4-13.4-13.4zM389.2 287.7h-81.3c-7.4 0-13.4 6-13.4 13.4s6 13.4 13.4 13.4h81.3c7.4 0 13.4-6 13.4-13.4s-6-13.4-13.4-13.4zM389.2 242.9h-81.3c-7.4 0-13.4 6-13.4 13.4s6 13.4 13.4 13.4h81.3c7.4 0 13.4-6 13.4-13.4s-6-13.4-13.4-13.4zM239 116.2c-3.7-10.4-13.6-17.9-25.2-17.9-11.7 0-21.7 7.5-25.4 17.9H160c-2.9 0-5.6 1.4-7.3 3.7s-2.1 5.3-1.2 8.1l14 43.2h96.6l14-43.2c.9-2.7.5-5.7-1.2-8.1-1.7-2.3-4.4-3.7-7.3-3.7H239z"
      />
    </svg>
  )
}

export default CostIcon
