import styled from 'styled-components'
import dayjs from 'dayjs'
import { useApolloClient } from '@apollo/client'
import useAuth from '../../hooks/useAuth'
import viewDoc from '../../graphql/viewDoc'
import downloadDoc from '../../graphql/downloadDoc'
import ScreenLoader from '../ScreenLoader/ScreenLoader'
import DocLink from './DocLink/DocLink'

const Table = styled.table`
  width: 100%;
  color: #4b5882;
  font-size: 12px;
  text-align: left;
  border: 1px solid #244d85;
  border-spacing: 0;
`

const Header = styled.tr`
  background-color: #244d85;
  color: #fff;
`

const Head = styled.th`
  min-height: 45px;
  padding: 10px;
  text-transform: capitalize;
`

const Doc = styled.tr`
  &:nth-child(even) {
    background-color: #f1f4f7;
  }
`

const Col = styled.td`
  height: 45px;
  padding: 10px;

  &:last-child {
    width: 100px;
  }
`

const Links = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, max-content);
`

const Fallback = styled.p`
  font-size: 14px;
  color: #a5acc2;
  text-align: center;
  margin-top: 22px;
`

const ErrorMsg = styled.p`
  color: red;
`

const DOC_DATE = 'MMMM D, YYYY'

const DocsTable = ({ docs = [], loading, error }) => {
  const { selectedOrg } = useAuth()
  const client = useApolloClient()


  const queryOperation = (operation, fileId) => {
    let query
    if (operation === 'view') query = viewDoc
    if (operation === 'download') query = downloadDoc

    return client.query({
      query,
      variables: {
        organizationId: selectedOrg.value,
        fileId,
      },
      context: {
        version: 2,
      },
    })
  }

  const handleView = async fileId => {
    try {
      const { data } = await queryOperation('view', fileId)
      window.open(data.viewDocument.url)
    } catch (err) {
      throw err
    }
  }

  const handleDownload = async fileId => {
    try {
      const { data } = await queryOperation('download', fileId)
      window.open(data.downloadDocument.url, '_self')
    } catch (err) {
      throw err
    }
  }

  if (loading) return <ScreenLoader />
  if (error) return <ErrorMsg>{error.message}</ErrorMsg>

  return (
    <>
      <Table>
        <thead>
          <Header>
            <Head>Name</Head>
            <Head>Email</Head>
            <Head>Published On</Head>
            <Head>Type</Head>
            <Head />
          </Header>
        </thead>
        <tbody>
          {docs.map(doc => {
            return (
              <Doc key={doc.id}>
                <Col>{doc.name}</Col>
                <Col>{doc.publishedBy ? doc.publishedBy.id : ''}</Col>
                <Col>{dayjs(doc.publishedDate).format(DOC_DATE)}</Col>
                <Col>
                  {doc.type
                    ? doc.type.split('_').map(capitalize).join(' ')
                    : ''}
                </Col>
                <Col>
                  <Links>
                    <DocLink className='documents-view-button' handler={() => handleView(doc.id)}>View</DocLink>
                    <DocLink className='documents-download-button' handler={() => handleDownload(doc.id)}>
                      Download
                    </DocLink>
                  </Links>
                </Col>
              </Doc>
            )
          })}
        </tbody>
      </Table>
      {docs.length < 1 && <Fallback>No documents found!</Fallback>}
    </>
  )
}

function capitalize(str) {
  if (!str) return
  return str[0].toUpperCase() + str.slice(1).toLowerCase()
}

export default DocsTable
