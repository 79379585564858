import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import TopRecommendations from '../../components/TopRecommendations/TopRecommendations'
import RecommendationSummary from '../../components/RecommendationSummary/RecommendationSummary'
import QuickLinks from '../../components/QuickLinks/QuickLinks'
import useAuth from '../../hooks/useAuth'
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader'
import TOP_RECOMMENDATIONS from '../../graphql/topRecommendations'
import GET_RECOMMENDATIONS from '../../graphql/listRecommendations'
import getTickets from '../../graphql/getTickets'
import { useEffect, useState } from 'react'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 44px;

  @media only screen and (max-width: 920px) {
    padding: 0 20px;
  }
`

const Error = styled.span`
  color: red;
`

const Row = styled.div`
  margin-top: 80px;
  display: flex;

  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    width: 100%;
  }
  `
  
  const Banner = styled.div`
  display: flex;

  @media only screen and (max-width: 1400px) {
    margin-top: 10px;
    flex-direction: column;
    width: 95%;
    max-width: 500px;
  }
  padding: 20px;
  background-color: #4CAF50; // Green background
  color: white;
  text-align: left; // Align text to the left
  align-items: center; // Vertically align text in the middle
  border-radius: 4px;
  width: 80vw;
  max-width: 1015px;
  @media only screen and (max-width: 1400px) {
    width: 75%;
  }
`;

const getUrlParameter = (name) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const Dashboard = () => {
  const { selectedOrg, user } = useAuth()
  const { banner, setBanner } = useState(null)
  const [ setError] = useState(null);

  
  const marketplace_subscription_token = getUrlParameter('x-amzn-marketplace-token')
  useEffect( () =>{
    if (marketplace_subscription_token){
      const payload = {
        regToken: marketplace_subscription_token.replace(" ", "+"),
        name: user.name,
        companyName: selectedOrg.label,
        email: user.email
      };
    
      // Use fetch to send a POST request
      fetch(process.env.REACT_APP_API_ENTITLEMENTS_ENDPOINT + "/subscribe", {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.status === 'error') {
          throw new Error('Server returned an error status');
        }
        if (data.status === 'ok') {
          setBanner({message: "Thanks for subscribing to CloudOps!"});
        }
      })
      .catch(err => {
        setError(err.message);
      });
    }
  },)

  const topRecommendationsResponse = useQuery(TOP_RECOMMENDATIONS, {
    variables: {
      organizationId: selectedOrg.value.split('o:')[1],
    },
    context: {
      version: 1,
    },
  })

  const recommendationsResponse = useQuery(GET_RECOMMENDATIONS, {
    variables: {
      organizationId: selectedOrg.value.split('o:')[1],
    },
    context: {
      version: 1,
    },
  })

  const ticketsReponse = useQuery(getTickets, {
    variables: {
      input: {
        currentUser: true,
        organizationId: selectedOrg.value,
        statuses: ['open'],
        solvedAfterDays: 90,
        limit: 300,
      },
    },
  })

  const isLoading = topRecommendationsResponse.loading || recommendationsResponse.loading || ticketsReponse.loading
  const isError = topRecommendationsResponse.error || recommendationsResponse.error || ticketsReponse.error

  if (isLoading) {
    return <ScreenLoader />
  }

  if (isError) {
    return (
      <Box>
        <Error>{isError.message}</Error>
      </Box>
    )
  }

  return (
    <Box>
      { banner ? (
          <Banner>{banner.message}</Banner>
      ): (
        <></>
      )}
      <RecommendationSummary listRecommendations={recommendationsResponse?.data?.listRecommendations} />
      <Row>
        <TopRecommendations recommendations={topRecommendationsResponse.data.listTopRecommendations} />
        <QuickLinks tickets={ticketsReponse.data.listZendeskTickets} />
      </Row>
    </Box>
  )
}

export default Dashboard
