const SecurityIcon = () => {
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 290 322.051"
    >
      <g>
        <path d="M154.58 174.944h119.941l3.259-27.677h-123.2zM285.553 81.269l4.381-37.193a9.58 9.58 0 00-6.824-10.315L187.471 5.784A151.364 151.364 0 00154.58 0v81.268h130.973zM154.58 100.43v27.677h125.457l3.259-27.677zM154.58 221.781h109.759a119.021 119.021 0 007.832-27.677H154.58v27.677zM135.419 0a151.355 151.355 0 00-32.891 5.785L6.891 33.761A9.58 9.58 0 00.067 44.076L17.22 189.725c3.926 33.339 21.855 63.636 49.188 83.124l69.011 49.201V0zM154.58 322.051l69.011-49.202a119.15 119.15 0 0030.588-31.906H154.58v81.108z" />
      </g>
    </svg>
  )
}

export default SecurityIcon
