import { Switch, Route, useRouteMatch } from 'react-router-dom'
import PrioritiesBoard from './PrioritiesBoard'
import PriorityDetails from '../PriorityDetails/PriorityDetails'

const PrioritiesPage = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={match.path} exact>
        <PrioritiesBoard />
      </Route>
      <Route path={`${match.path}/:id`}>
        <PriorityDetails />
      </Route>
    </Switch>
  )
}

export default PrioritiesPage
