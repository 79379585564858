import { useState, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import Editor from '../Editor/Editor'
import Heading from '../Heading/Heading'
import Button from '../Button/Button'
import useDropzone from '../../hooks/useDropzone'
import createZendeskTicket from '../../graphql/createZendeskTicket'
import createPriority from '../../graphql/createPriority'
import useAuth from '../../hooks/useAuth'
import useListStatuses from '../../hooks/useListStatuses'
import { quillIndent, htmlToText } from '../../utils'

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 45px;
  margin-bottom: 44px;

  button {
    align-self: flex-start;
  }

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`

const inputStyles = css`
  border: 1px solid #ccc;
  padding: 8px 15px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
`

const Input = styled.input`
  ${inputStyles}
`

const Textarea = styled.textarea`
  ${inputStyles}
  max-width: 100%;
`

const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000;
`

const Note = styled.span`
  font-size: 12px;
  margin-bottom: 10px;
  color: #000;
`

const ErrorMsg = styled.span`
  color: red;
  margin-bottom: 12px;
`

const Box = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px 12px;
  border-radius: 5px;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.09);
`

const StyledSelect = styled(Select)`
  margin-bottom: 10px;
  color: #000;
  width: 200px;
  z-index: 99;
`

const NewSupportCase = () => {
  const { selectedOrg } = useAuth()
  const history = useHistory()
  const [createTicket] = useMutation(createZendeskTicket)
  const [createTask] = useMutation(createPriority)
  const { dropzone, initializeDropzone, finalizePackage } = useDropzone()
  const editorRef = useRef()
  const listListatuses = useListStatuses()

  // eslint-disable-next-line
  useEffect(() => initializeDropzone('new-case-dropzone'), [])

  const [form, setForm] = useState({
    values: {
      comment: '',
      priority: null,
      cc: '',
      subject: '',
    },
    touched: {},
    errors: {},
  })
  const [isSubmitting, setSubmitting] = useState(false)
  const isTeamSupportSelected =  form.values.priority?.value === 'Support'

  const validate = values => {
    const errors = {}

    if (!values.comment) errors.comment = 'Please fill comment field!'
    if (!values.subject) errors.subject = 'Please fill subject field!'

    return errors
  }

  const onChange = (name, value) => {
    const isSelecting = name === 'priority'

    setForm(form => {
      const values = {
        ...form.values,
        [name]: value,
        ...isSelecting && value.value === 'Support' && {
          comment: htmlToText(form.values.comment)
        },
      }

      const errors = validate(values)

      const newForm = {
        values,
        touched: {
          ...form.touched,
          [name]: true,
        },
        errors,
      }

      return newForm
    })

    if (
        isSelecting
        && value.value !== 'Support'
        && isTeamSupportSelected
      ) editorRef.current.setText(form.values.comment)
  }

  const handleChange = e => {
    const { name, value } = e.target

    onChange(name, value)
  }

  const onSubmit = async () => {
    try {
      const errors = validate(form.values)

      if (!!Object.keys(errors).length) {
        const touched = Object.keys(errors).reduce((obj, key) => {
          obj[key] = true
          return obj
        }, {})

        setForm(form => ({
          ...form,
          errors,
          touched,
        }))
        return
      }

      setSubmitting(true)

      const variables = isTeamSupportSelected
        ? {
            organizationId: selectedOrg.value,
            issue: {
              title: form.values.subject,
              status: listListatuses.find(status => status.type === 'backlog')?.name,
              description: form.values.comment
            },
          }
        : {
            ...form.values,
            priority: form.values.priority ? form.values.priority.value : undefined,
            cc: form.values.cc.split(',').map(i => i.trim()),
            organizationId: selectedOrg.value,
            comment: quillIndent(form.values.comment)
          }

      if (!!dropzone.nbrOfFilesAttached) {
        const message = `This ${isTeamSupportSelected ? 'task' : 'ticket'} contains a file uploaded using SendSafely. To view the attachment go to the below link:`
        const attachmentURL = await finalizePackage(message)

        if (isTeamSupportSelected) {
          const newlines = '\n\n'
          variables.issue.description += newlines + htmlToText(attachmentURL)
        } else {
          variables.comment += attachmentURL
          // Add this to every comment that is made
          variables.comment += '<br><span style="color:white">System note: sent from Harbor ref-46947</span>'
        }
      }

      const { data } = isTeamSupportSelected
        ? await createTask({ variables, context: { version: 4 } })
        : await createTicket({ variables })

      const redirectPath = isTeamSupportSelected
        ? `/priority-board/${data.createIssue.id}`
        : `/support-cases/${data.createZendeskTicket.id}`

      history.push(redirectPath)
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  const subjectError = form.touched.subject ? form.errors.subject : ''
  const commentError = form.touched.comment ? form.errors.comment : ''
  const disabled = isSubmitting || !!Object.keys(form.errors).length

  return (
    <Col>
      <Heading>Support Cases - New Case</Heading>
      <Box>
        <Label>Subject</Label>
        <Input
          name='subject'
          value={form.values.subject}
          onChange={handleChange}
        />
        {!!subjectError && (
          <ErrorMsg data-testid='subject-error'>{subjectError}</ErrorMsg>
        )}
        <Label>Description</Label>
        <Editor
          style={{ display: isTeamSupportSelected ? 'none' : 'block' }}
          onChange={(value, _, editor) => {
            editorRef.current = editor
            onChange('comment', value, editor)
          }}
        />
        {isTeamSupportSelected && (
          <Textarea
            type='textarea'
            name='comment'
            value={form.values.comment}
            rows="4"
            placeholder='Enter description here'
            onChange={handleChange}
          />
        )}
        {!!commentError && (
          <ErrorMsg data-testid='subject-error'>{commentError}</ErrorMsg>
        )}
        <Note>
          Please enter the details of your request. A member of our support
          staff will respond as soon as possible.
        </Note>
        {!isTeamSupportSelected && (
          <>
            <Label>CC (Optional)</Label>
            <Input
              placeholder='Add emails'
              name='cc'
              value={form.values.cc}
              onChange={handleChange}
            />
            <Note>Enter e-mail addresses separated by comma.</Note>
          </>
        )}
        <Label>Priority</Label>
        <StyledSelect
          options={[
            {
              value: 'Normal',
              label: <p title='One business day reply'>Normal</p>
            },
            {
              value: 'High',
              label: <p title='Four hour, 24x7 response'>High</p>,
            },
            {
              value: 'Urgent',
              label: <p title='15 min, 24x7 response (site down, production impaired)'>Urgent</p>,
            },
            {
              value: 'Support',
              label: <p title='To be reviewed with Team Support'>Team Support Backlog</p>
            },
          ]}
          value={form.values.priority}
          onChange={value => onChange('priority', value)}
        />
        <Label>Attachments</Label>
      <div className='new-case-dropzone'></div>
      </Box>
      <Button disabled={disabled} isSubmitting={isSubmitting} onClick={onSubmit}>
        Submit
      </Button>
    </Col>
  )
}

export default NewSupportCase
