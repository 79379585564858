import { createGlobalStyle } from 'styled-components'

const Global = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
  }

  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-text-size-adjust: 100%; 
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(black, 0);
  }

  body {
    margin: 0;
    font-family: 'Aeonik', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  hr {
    box-sizing: content-box; 
    height: 0; 
    overflow: visible;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box; 
    padding: 0;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .appWrapper {
    display: flex;
  }

  .tooltip-link {
    cursor: pointer;
    display: block;
    color: #fff;
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    background-color: transparent;
    transition: 0.2s;

    &:hover {
      color: #00bfff;
    }
  }

  ol, ul {
    padding: revert;
  }

  .ql-editor li[data-list=ordered].ql-indent-1 > .ql-ui:before,
  .ql-editor li[data-list=ordered].ql-indent-4 > .ql-ui:before,
  .ql-editor li[data-list=ordered].ql-indent-7 > .ql-ui:before {
    content: counter(list-1, decimal) '. ';
  }

  .ql-editor li[data-list=ordered].ql-indent-2 > .ql-ui:before,
  .ql-editor li[data-list=ordered].ql-indent-5 > .ql-ui:before,
  .ql-editor li[data-list=ordered].ql-indent-8 > .ql-ui:before {
    content: counter(list-2, decimal) '. ';
  }
`

export default Global
