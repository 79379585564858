import styled, { css } from "styled-components";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCog,
} from "@fortawesome/free-solid-svg-icons";

import SecurityIcon from "../RecommendationTabs/icons/SecurityIcon";
import ReliabilityIcon from "../RecommendationTabs/icons/ReliabilityIcon";
import PerformanceIcon from "../RecommendationTabs/icons/PerformanceIcon";
import TrainingIcon from "../RecommendationTabs/icons/TrainingIcon";
import OperationsIcon from "../RecommendationTabs/icons/OperationsIcon";
import CostIcon from "../RecommendationTabs/icons/CostIcon";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  border-radius: 15px;
  border: ${(p) => (p.isDragging ? "2px solid white" : "2px solid #e3e9ef")};
  ${(p) => p.isDragging && "box-shadow: 0 0 51px 0 rgba(0, 0, 0, 0.2)"};
  width: 100%;
  height: 180px;
  position: relative;

  margin-top: 22px;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Row = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  display: -webkit-box;
  -webkit-line-clamp: ${(p) => p.lineClamp};
  -webkit-box-orient: vertical;
  hyphens: auto;
  overflow: hidden;

  font-size: 14px;
  font-weight: 900;
  line-height: 1.2;
  color: #244d85;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const Status = styled.p`
  font-size: 12px;
  font-weight: 900;
  background-color: #f3b349;
  padding: 4px 15px;
  border-radius: 15px;
  color: #fff;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  text-transform: uppercase;
  position: absolute;
  top: -2px;
  right: -2px;
`;

const Description = styled.h2`
  color: #a5acc2;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 2px;
`;

const ID = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #a5acc2;
  margin-left: auto;
`;

const IconContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => (p.highPriority ? "#FD2020" : "#f1f4f7")};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    filter: brightness(95%);
  }

  svg {
    height: 17px;
    fill: #a5acc2;
  }
`;

const IconContainer = styled.div`
  ${IconContainerStyles}
`;

const LinkIconContainer = styled(Link)`
  ${IconContainerStyles}
`;

const Span = styled.span`
  color: #244d85;
  font-weight: 900;
  font-size: 16px;
  margin-left: 8px;
`;

const PrioritiesBoardGeneralCard = ({ card, index, org }) => {
  const features = org?.harborFeatures || [];
  const status =
    dayjs().diff(dayjs(card.updatedDateTime), "day") < 11 && "new update";
  const Icon = getIcon(card.awsPillar);

  const hours = [card.hoursEstimate, card.hoursWorked];
  const lineClamp = hours.every(Boolean) ? 2 : hours.some(Boolean) ? 3 : 5;
  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
          className="priority-card"
        >
          {status && <Status>{status}</Status>}
          <Title lineClamp={lineClamp} title={card.title}>
            {card.title}
          </Title>
          {!features.includes("PRIORITIES_HideHoursEstimated") &&
            card.hoursEstimate && (
              <Description>
                Estimated hours<Span>{card.hoursEstimate}</Span>
              </Description>
            )}
          {!features.includes("PRIORITIES_HideHoursWorked") &&
            card.hoursWorked && (
              <Description>
                Hours worked<Span>{card.hoursWorked}</Span>
              </Description>
            )}
          <Row>
            <LinkIconContainer
              title="Details"
              to={`/priority-board/${card.id}`}
            >
              <FontAwesomeIcon icon={faCog} color="#a5acc2" />
            </LinkIconContainer>
            {card.priority === "high" && (
              <IconContainer title="High priority" highPriority={true}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </IconContainer>
            )}
            {card.awsPillar && (
              <IconContainer title={capitalize(card.awsPillar)}>
                <Icon />
              </IconContainer>
            )}
            <ID>{card.id}</ID>
          </Row>
        </Card>
      )}
    </Draggable>
  );
};

function getIcon(pillar) {
  if (!pillar) return;

  switch (pillar) {
    case "cost":
      return CostIcon;
    case "reliability":
      return ReliabilityIcon;
    case "performance":
      return PerformanceIcon;
    case "training":
      return TrainingIcon;
    case "operations":
      return OperationsIcon;
    case "security":
      return SecurityIcon;
    default:
      return SecurityIcon;
  }
}

function capitalize(str) {
  if (!str) return;

  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export default PrioritiesBoardGeneralCard;
