import styled from 'styled-components'
import { useEffect, useState } from 'react'
import useAuth from '../../hooks/useAuth'


const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 22px 32px 0px 27px;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 23px 34px 0 rgba(0, 0, 0, 0.09);
  max-width: 234px;
`

const Title = styled.span`
  color: #4b5882;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 10px;
  text-align: center;
`

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0 15px;
`

const InfoRow = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  svg {
    fill: #a5acc2;
    width: 15px;
    height: auto;
  }

  span {
    margin-left: 14px;
    color: #4b5882;
    font-size: 12px;
    font-weight: 500;
  }
`

const EntitlementStatus = () => {
  const { selectedOrg } = useAuth()
  const [error, setError] = useState(null);
  const [hide, setHide] = useState(true);
  const [exp, setExp] = useState(null);
  const [sku, setSku] = useState(null);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_ENTITLEMENTS_ENDPOINT + "/status?"  + new URLSearchParams({
          orgId: selectedOrg.value
      })
    )
      .then(response => response.json())
      .then(data => {

        if (data.status === 'error') {
          throw new Error('Server returned an error status');
        }
        if (data.expirationDate && data.sku) {
          const expDate = new Date(data.expirationDate).toLocaleDateString();
          setExp(expDate);
          setSku(data.sku);
          setHide(false)
          setError(null);
        }
      })
      .catch(err => {
        // Handle errors (either fetch error or custom error thrown above)
        setError(err.message);
      });
  },[selectedOrg]);

  return (
    <>
      {( (error || hide) ) ? (
        <></>
      ) : (
        <>
          <Box className='entitlement-status'>
          <InfoCol>
            <Title>CloudOps</Title>
            {sku && (
              <InfoRow>
                <span><b>SKU: </b></span>
                <span>{sku}</span>
              </InfoRow>
            )}
            {exp && (
              <InfoRow>
                <span><b>Expires on: </b></span>
                <span>{exp}</span>
              </InfoRow>
            )}
          </InfoCol>
          </Box>
        </>
      )}
    </>
  )
}

export default EntitlementStatus
