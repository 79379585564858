import { useContext } from 'react'
import BoardContext from '../context/BoardContext'

const useListStatuses = () => {
  const state = useContext(BoardContext)

  return state
}

export default useListStatuses
