import styled from 'styled-components'
import dayjs from 'dayjs'
import { useHistory, Link } from 'react-router-dom'
import { truncate, getTicketLinkedResourcePath, DATE_FORMAT } from '../../utils'
import ScreenLoader from '../ScreenLoader/ScreenLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const CellStatusToBackgroundMap = p => {
  if (!p.status) return p.status;
  switch (p.status) {
    case 'Awaiting Reply':
      return '#f3b349';
    case 'Open':
      return '#00bfff';
    case 'Recommendation Created':
      return '#008ccc';
    case 'Moved to Team Support Board':
      return '#008ccc';
    default:
      return 'gray'
  }
}

const StatusCellIconMap = status => ['Recommendation Created', 'Moved to Team Support Board'].includes(status)


const Table = styled.table`
  width: 100%;
  color: #4b5882;
  font-size: 12px;
  text-align: left;
  border: 1px solid #244d85;
  border-spacing: 0;
`

const Head = styled.th`
  min-height: 45px;
  padding: 10px;
  text-transform: capitalize;
`

const Header = styled.tr`
  background-color: #244d85;
  color: #fff;
`

const Ticket = styled.tr`
  cursor: pointer;

  &:nth-child(even) {
    background-color: #f1f4f7;
  }

  transition: 0.2s;

  &:hover {
    background-color: #00bfff;
    color: #fff;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;

  height: 45px;
  width: 100%;
  margin: -20px 0;

  text-decoration: none;
  color: currentColor;

  span {
    flex: 1;
  }
`

const Cell = styled.td`
  height: 45px;
  padding: 10px;

  &::first-letter {
    text-transform: capitalize;
  }

  background-color: ${CellStatusToBackgroundMap};

  ${p =>
    p.status && `
      text-align: center; 
      color: #fff; 
      font-weight: bold
    `};

  &:nth-child(1) {
    width: 60px;
  }

  &:nth-child(3) {
    width: 230px;
  }

  &:nth-child(4) {
    width: 130px;
  }
`

const Fallback = styled.p`
  font-size: 14px;
  color: #a5acc2;
  text-align: center;
  margin-top: 22px;
`

const ErrorMsg = styled.p`
  color: red;
`

const SupportCasesTable = ({ tickets = [], loading, error }) => {
  const history = useHistory()

  if (loading) return <ScreenLoader />
  if (error) return <ErrorMsg>{error.message}</ErrorMsg>

  return (
    <>
      <Table>
        <thead>
          <Header>
            <Head>#</Head>
            <Head>Subject</Head>
            <Head>Last Updated</Head>
            <Head>Status</Head>
          </Header>
        </thead>
        <tbody>
          {tickets.map(ticket => {
            const resourcePath = getTicketLinkedResourcePath(ticket.linkedTo)
            const ticketPath = `/support-cases/${ticket.id}`

            return (
              <Ticket onClick={() => history.push(ticketPath)} key={ticket.id}>
                <Cell>{ticket.id}</Cell>
                <Cell><StyledLink to={ticketPath}>{truncate(ticket.subject)}</StyledLink></Cell>
                <Cell>{dayjs(ticket.updated).format(DATE_FORMAT)}</Cell>
                <Cell
                  status={ticket.status}
                  onClick={event => event.stopPropagation()}
                >
                  <StyledLink style={{ justifyContent: 'center' }} to={resourcePath || ticketPath}>
                    <span>{ticket.status}</span>{StatusCellIconMap(ticket.status) && <FontAwesomeIcon icon={faExternalLinkAlt} size='lg'/>}
                  </StyledLink>
                </Cell>
              </Ticket>
            )
          })}
        </tbody>
      </Table>
      {tickets.length < 1 && <Fallback>No cases found!</Fallback>}
    </>
  )
}

export default SupportCasesTable
