// import Quill from 'quill'

export const DATE_FORMAT = 'MMMM D, YYYY, h:mmA Z'

export const truncate = (str, limit = 100) => {
  if (str.length <= limit) return str

  const ellipsis = '...'
  return str.slice(0, limit - ellipsis.length).concat(ellipsis)
}

// TODO: Uncomment after ClickUp supports delta descriptions
// export const htmlToDelta = ({ html, to }) => {
//   const clipboard = new Quill('.quill-instance', { bounds: '.app' }).clipboard

//   return convertDelta({
//     delta: clipboard.convert({ html }),
//     to
//   })
// }

const statuses = {
  OPEN: 'OPEN',
  ARCHIVED: 'ARCHIVED',
  CLIENT_HANDLED: 'CLIENT_HANDLED',
  T10_HANDLED: 'T10_HANDLED',
  T10_DO_IT: 'T10_DO_IT',
  T10_ESTIMATE_IT: 'T10_ESTIMATE_IT',
  T10_DISCUSS_IT: 'T10_DISCUSS_IT',
  T10_TRAIN_CLIENT: 'T10_TRAIN_CLIENT'
}

const swimlanes = {
  BACKLOG: 'backlog',
  INPROGRESS: 'inProgress',
}

export const valueToStatus = selected => {
  let status

  if (selected === 'archive') status = statuses.ARCHIVED
  else if (selected === 'fixit') status = statuses.CLIENT_HANDLED
  else if (selected === 'gettraining') status = statuses.T10_TRAIN_CLIENT
  else if (selected === 'discuss') status = statuses.T10_DISCUSS_IT
  else if (selected === 'workwithtrek') status = statuses.T10_ESTIMATE_IT
  else if (selected === 'doit') status = statuses.T10_DO_IT

  return status
}

export const valueToSwimlane = (selected, listStatuses) => {
  let swimlane

  if (selected === 'doit') {
    const inProgress = listStatuses.find(column => column.type === swimlanes.INPROGRESS)
    swimlane = inProgress.name
  }

  if (['workwithtrek', 'discuss', 'gettraining'].includes(selected)) {
    const backlog = listStatuses.find(column => column.type === swimlanes.BACKLOG)
    swimlane = backlog.name
  }

  return swimlane
}

// jessi todo: investigate if this can be converted to an object access pattern
export const mapTicketStatus = status => {
  switch (status) {
    case 'closed':
    case 'solved':
      return 'Solved'
    case 'new':
    case 'open':
    case 'hold':
      return 'Open'
    case 'pending':
      return 'Awaiting Reply'
    case 'recommendation':
      return 'Recommendation Created'
    case 'teamsupport':
      return 'Moved to Team Support Board'
    default:
      break
  }
}

export const mapRecStatus = status => {
  switch (status) {
    case 'ARCHIVED':
      return 'archive'
    case 'CLIENT_HANDLED':
      return 'client will handle it'
    case 'T10_HANDLED':
      return 'Trek10 will handle it'
    case 'T10_DO_IT':
      return 'Trek10 should do it'
    case 'T10_ESTIMATE_IT':
      return 'Trek10 should estimate it'
    case 'T10_DISCUSS_IT':
      return 'We should discuss it'
    case 'T10_TRAIN_CLIENT':
      return 'Trek10 should train client'
    default:
      break
  }
}

export const getZendeskTicketId = url => {
  const match = url?.match(/requests\/(\d+)/) || url?.match(/tickets\/(\d+)/)
  return match ? +match[1] : undefined
}

export const transformEstimate = x => {
 if(x != null){
     return !x.includes('-')
  ? Number(x)
  : x
    .split('-')
    .map(Number)
    .reduce((acc, cur, _, arr) => +((acc + cur) / arr.length).toFixed(2))
 }
    return x
}

export const formatDescription = x => x
  .split('\n')
  .map(x => x.trim())
  .join('\n')

export const htmlToText = html => {
  const traverse = (node, cb) => {
    cb(node)
    node = node.firstChild

    while (node) {
      traverse(node, cb)
      node = node.nextSibling
    }
  }

  const extractText = node => {
    const textNodes = []
    traverse(node, cur => cur.nodeType === 3 ? textNodes.push(cur.nodeValue) : null)

    return textNodes
      .map(x => x.trim())
      .filter(Boolean)
      .join('\n')
  }

  const template = document.createElement('template')
  template.innerHTML = html.trim()

  return extractText(template.content)
}

export const convertDelta = ({ delta, to }) => {
  if (!to) return delta

  if (to === 'clickup') {
    const newDelta = { ...delta, comment: [...delta.ops] }
    delete newDelta.ops

    newDelta.comment.forEach(operation => {
      if (operation.insert) {
        operation['text'] = operation.insert
        delete operation.insert
      }

      if (operation.attributes?.list) {
        operation.attributes.list = { list: operation.attributes.list }
      }

      if (operation.attributes?.['code-block']) {
        operation.attributes['code-block'] = { 'code-block': 'plain' }
      }
    })

    return JSON.stringify(newDelta)
  } else if (to === 'quill') {
    const newDelta = { ...delta, ops: [...delta.comment] }
    delete newDelta.comment

    newDelta.ops.forEach(operation => {
      if (operation.text) {
        operation['insert'] = operation.text
        delete operation.text
      }

      if (operation.attributes?.list) {
        operation.attributes.list = operation.attributes.list.list
      }

      if (operation.attributes?.['code-block']) {
        operation.attributes['code-block'] = true
      }
    })

    return JSON.stringify(newDelta)
  }
}

export const checkIsDelta = delta => {
  try {
    return Array.isArray(JSON.parse(delta))
  } catch (_) {
    return false
  }
}

// Quill uses classes to make lists appear as nested
// We need to nest lists semantically
const searchLastEl = list => list[list.length - 1]
const addChildToCurParent = (parentStack, child) => searchLastEl(parentStack).appendChild(child)
const getListLevel = list => +(list.className || '0').replace(/[^\d]/g, '')
const getIndentClass = level => `ql-indent-${level}`

export const quillIndent = html => {
  if (!html || html.length === 0) return html

  const temp = document.createElement('p')
  temp.setAttribute('style', 'display: none')
  temp.innerHTML = html

  ;['ul', 'ol'].forEach(listType => {
    Array.from(temp.querySelectorAll(listType)).forEach(outerList => {
      const innerLists = Array.from(outerList.children).filter(el => el.tagName === 'LI')

      let lastListLevel = 0

      const parentElementsStack = []
      const root = document.createElement(listType)

      parentElementsStack.push(root)

      innerLists.forEach(list => {
        const curListLevel = getListLevel(list)
        list.className = list.className.replace(getIndentClass(curListLevel), '')

        const difference = curListLevel - lastListLevel
        lastListLevel = curListLevel

        if (difference > 0) {
          let currentDiff = difference

          while (currentDiff > 0) {
            let lastListInCurLevel = searchLastEl(parentElementsStack).lastElementChild

            if (!lastListInCurLevel) {
              lastListInCurLevel = document.createElement('li')
              addChildToCurParent(parentElementsStack, lastListInCurLevel)
            }

            const newList = document.createElement(listType)
            lastListInCurLevel.appendChild(newList)
            parentElementsStack.push(newList)

            currentDiff--
          }
        }

        if (difference < 0) {
          let currentDiff = difference

          while (currentDiff < 0) {
            parentElementsStack.pop()

            currentDiff++
          }
        }

        addChildToCurParent(parentElementsStack, list)
      })

      outerList.innerHTML = root.innerHTML
    })
  })

  const indented = temp.innerHTML
  temp.remove()

  return indented
}

export const transformTicket = ticket => {
  const fieldId = '360045264492'
  const linkedTo = ticket.customfields.find(field => field.id === fieldId)

  const status = linkedTo.value?.includes('rec')
    ? 'recommendation'
    : linkedTo.value?.includes('task')
      ? 'teamsupport'
      : ticket.status

  return {
    ...ticket,
    linkedTo: linkedTo.value,
    status: mapTicketStatus(status)
  }
}

export const getTicketLinkedResourcePath = linkedTo => {
  if (!linkedTo) return

  if (linkedTo.includes('rec')) return `/recommendations/${linkedTo.split(':')[1]}`
  if (linkedTo.includes('task')) return `/priority-board/${linkedTo.split(':')[1]}`
}

// This RegExp belongs with removeHiddenMessage - do not separate them!
const hiddenMsg = new RegExp('<br><span style="color: ?white">System note: sent from Harbor ref-46947</span>', 'i')
export const removeHiddenMessage = htmlBody => htmlBody.replace(hiddenMsg, "")
