import styled from 'styled-components'

const StyledHeading = styled.h1`
  word-wrap: break-word;
  padding: 15px 0;
  font-size: 21px;
  font-weight: 900;
  line-height: 1;
  text-transform: capitalize;
  color: #4b5882;
  position: relative;

  &::after {
    content: '';
    height: 3px;
    width: 40px;
    background-color: #00bfff;
    border-radius: 20px;
    position: absolute;
    top: 92%;
    left: 0;
  }

  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }
`

const Heading = ({ children, ...rest }) => (
  <StyledHeading {...rest}>{children}</StyledHeading>
)

export default Heading
