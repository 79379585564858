const OperationsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 290 302.589"
    >
      <g>
        <path d="M127.484 228.627c-.326-.07-.687-.038-1.011-.112v74.075h35.459v-74.076c-.316.074-.667.041-.985.11-5.368 1.163-10.941 1.864-16.745 1.864-5.776 0-11.337-.692-16.718-1.861M81.764 150.704c0 25.401 15.219 47.917 38.767 57.38 3.615 1.567 7.443 2.737 11.372 3.513 11.659 2.279 24.472 1.14 34.978-3.418 23.772-9.558 38.992-32.074 38.992-57.475 0-20.587-10.103-39.399-26.595-50.904v50.904a8.868 8.868 0 01-3.948 7.376l-26.595 17.73a8.844 8.844 0 01-9.835 0l-26.595-17.73a8.866 8.866 0 01-3.947-7.376V99.799c-16.491 11.506-26.594 30.318-26.594 50.905" />
        <path d="M1.187 203.028l26.595 46.056c1.914 3.299 5.758 5.039 9.454 4.25l41.477-8.484c5.775 4.095 11.79 7.61 17.946 10.483l12.882 38.637v-72.156c-26.963-13.244-44.324-40.452-44.324-71.111 0-32.498 19.556-61.535 49.822-73.975 5.765-2.372 12.232 1.825 12.232 8.199v61.032L145 157.776l17.73-11.817V84.927c0-6.421 6.55-10.579 12.233-8.199 30.265 12.441 49.822 41.477 49.822 73.975 0 30.588-17.286 57.74-44.324 71.099v72.184l12.847-38.643c6.173-2.874 12.19-6.389 17.981-10.492l41.476 8.484c3.697.762 7.532-.943 9.454-4.25l26.596-46.056a8.855 8.855 0 00-1.048-10.311l-28.066-31.65c.337-3.411.545-6.857.545-10.363 0-3.506-.209-6.951-.546-10.362l28.066-31.651a8.855 8.855 0 001.048-10.31l-26.595-46.057c-1.914-3.298-5.732-4.977-9.454-4.25l-41.477 8.484c-5.775-4.094-11.79-7.61-17.946-10.483L180 6.06A8.853 8.853 0 00171.595 0h-53.19a8.863 8.863 0 00-8.415 6.068L96.693 46.065c-6.173 2.874-12.19 6.389-17.981 10.492l-41.477-8.485a8.88 8.88 0 00-9.454 4.25L1.187 98.38a8.852 8.852 0 001.048 10.31l28.066 31.651c-.337 3.411-.545 6.856-.545 10.362 0 3.506.209 6.952.545 10.363l-28.066 31.65a8.858 8.858 0 00-1.048 10.312" />
      </g>
    </svg>
  )
}

export default OperationsIcon
