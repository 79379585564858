import { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import dayjs from 'dayjs'
import useAuth from '../../hooks/useAuth'
import Heading from '../../components/Heading/Heading'
import Filter from '../../components/Filter/Filter'
import DocsTable from '../../components/DocsTable/DocsTable'
import getDocuments from '../../graphql/getDocuments'

const Box = styled.div`
  color: #4b5882;
  font-size: 14px;
  padding-right: 45px;
  margin-bottom: 44px;

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: max-content 180px max-content;
  grid-gap: 20px;
  align-items: center;

  margin-bottom: 35px;

  @media only screen and (max-width: 920px) {
    margin-top: 10px;
  }

  @media only screen and (max-width: 610px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`

const Row = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 5px;
  }
`

const Selectables = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 5px;
`

const Selectable = styled.p`
  cursor: pointer;
  ${p => !p.active && 'color: #00bfff'};
  font-weight: bold;
`

const docTypes = [
  { label: 'Meeting Notes', value: 'MEETING_NOTES' },
  { label: 'Cost Analysis', value: 'COST_ANALYSIS' },
  { label: 'War Report', value: 'WAR_REPORT' },
  { label: 'Other', value: 'OTHER' },
]

const UNIX_START = '1970-01-01'
const AWS_DATE = 'YYYY-MM-DD'
const selectables = [
  {
    label: 'YTD',
    value: [
      dayjs().startOf('year').format(AWS_DATE),
      dayjs().format(AWS_DATE)
    ]
  },
  {
    label: 'Last 90 days',
    value: [
      dayjs().subtract(90, 'd').format(AWS_DATE),
      dayjs().format(AWS_DATE)
    ]
  },
  {
    label: 'Last Year',
    value: [
      dayjs().startOf('year').subtract(1, 'y').format(AWS_DATE),
      dayjs().endOf('year').subtract(1, 'y').format(AWS_DATE),
    ],
  },
  {
    label: 'All',
    value: [UNIX_START, dayjs().format(AWS_DATE)],
  },
]

const Documents = () => {
  const [filters, setFilters] = useState({
    selected: undefined,
    dateFilter: { between: selectables[0].value },
  })
  const { selectedOrg } = useAuth()
  const { data, loading, error } = useQuery(getDocuments, {
    variables: {
      organizationId: selectedOrg.value,
      filter: {
        type: filters.selected,
        publishedDate: { ...filters.dateFilter },
      },
    },
    context: {
      version: 2,
    },
  })
  const sorted = data && [...data.listDocuments.files].sort(byDateDescending)

  const handleFilter = (e, value) => {
    const selected = !value ? e?.value : filters.selected
    const dateFilter = !value
      ? filters.dateFilter
      : !Array.isArray(value)
        ? { since: value }
        : { between: value }

    setFilters({ selected, dateFilter })
  }

  const { selected, dateFilter } = filters
  return (
    <Box>
      <Grid>
        <Heading>Documents</Heading>
        <Filter
          className='filter-documents-type'
          placeholder='Document Type'
          options={docTypes}
          handleFilter={e => handleFilter(e)}
          selected={selected}
        />
        <Selectables>
          {selectables.map(({ label, value }, idx) => (
            <Row key={idx}>
              <Selectable
                className='filter-documents-time-range'
                active={
                  dateFilter.since === value || dateFilter.between === value
                }
                onClick={e => handleFilter(e, value)}
              >
                {label}
              </Selectable>
              {idx !== selectables.length - 1 && <span>-</span>}
            </Row>
          ))}
        </Selectables>
      </Grid>
      <DocsTable docs={sorted} loading={loading} error={error} />
    </Box>
  )
}

function byDateDescending(a, b) {
  return dayjs(a.publishedDate).isAfter(dayjs(b.publishedDate)) ? -1 : 1
}

export default Documents
