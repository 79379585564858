import { gql } from '@apollo/client'

const getPriorities = gql`
  query($limit: Int, $organizationId: String, $filter: IssuesFilter) {
    listIssues(
      limit: $limit
      organizationId: $organizationId
      filter: $filter
    ) {
      items {
        id
        title
        description
        status
        priority
        type
        hoursEstimate
        hoursWorked
        awsPillar
        createdDateTime
        updatedDateTime
        index
      }
    }
  }
`

export default getPriorities
