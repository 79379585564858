import { gql } from '@apollo/client'

const createPriority = gql`
  mutation createPriority($organizationId: String!, $issue: IssueInput!) {
    createIssue(organizationId: $organizationId, issue: $issue) {
      id
      title
      description
      status
      priority
      type
      hoursEstimate
      hoursWorked
      awsPillar
      createdDateTime
      updatedDateTime
    }
  }
`

export default createPriority
