import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  margin-left: auto;
`

const Button = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  border: 1px solid #f1f4f7;
  border-radius: 5px;
  background-color: #fff;
  color: #4b5882;
  width: 80px;
  height: 32px;
  transition: 0.2s;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    color: #fff;
    background-color: #00bfff;
  }

  &:disabled {
    color: #cdcfd1;
    cursor: default;
    background-color: #f1f4f7;
  }
`

const StyledPagination = styled.div`
  width: 100%;
  margin-top: 35px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #4b5882;
`

const Page = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f1f4f7;
  border-radius: 5px;
  color: ${p => (p.active ? '#fff' : '#4b5882')};
  background-color: ${p => (p.active ? '#00bfff' : '#fff')};
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: 0.2s;

  ${p => !p.active && '&:hover { background-color: #f1f4f7; }'}

  &:not(:last-child) {
    margin-right: 10px;
  }
`

const Ellipsis = styled.span`
  margin-right: 10px;
`

const Pagination = ({
  paginate: { currentPage, pages, maxPage, next, prev, jump },
}) => {
  return (
    <StyledPagination>
      {pages.map(page => {
        if (page === '...') return <Ellipsis>{page}</Ellipsis>
        return (
          <Page
            key={page}
            active={currentPage === page}
            onClick={() => jump(page)}>
            {page}
          </Page>
        )
      })}
      <Row>
        <Button disabled={currentPage === 1} onClick={prev}>
          Previous
        </Button>
        <Button disabled={currentPage === maxPage} onClick={next}>
          Next
        </Button>
      </Row>
    </StyledPagination>
  )
}

export default Pagination
