import { useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import useAuth from '../../hooks/useAuth'

const StyledSelect = styled(Select)`
  width: 48px;
  align-self: stretch;

  --gray: #f1f4f7;

  .org-switcher {
    &__control {
      justify-content: center;
      background-color: #f1f4f7;
      border: none;
      border-radius: 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      cursor: pointer;
      height: 100%;

      &--is-focused {
        border: revert;
        box-shadow: revert;
      }
    }

    &__indicators {
      width: 100%;
    }

    &__indicator {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
      color: #a5acc2;
    }

    &__menu {
      color: #a5acc2;
      font-size: 14px;
      font-weight: bold;
      margin-top: 15px;
      right: 0;
      min-width: 200px;
      border-radius: 10px;
      box-shadow: 0 2px 8px 0 rgba(36, 77, 133, 0.13),
        0 -2px 8px 0 rgba(36, 77, 133, 0.13);
    }

    &__menu-list {
      position: revert;
      border-radius: 10px;
      padding-top: 0;
      padding-bottom: 0;
      overflow-y: auto;
    }

    &__option {
      padding: 12px;
      cursor: pointer;
      color: #a5acc2;

      &:hover,
      &--is-focused,
      &--is-selected {
        background-color: var(--gray);
      }

      &:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        &::after {
          content: '';
          width: 20px;
          height: 20px;
          clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
          background-color: ${p => (p.isFirstActive || p.isFirstFocused ? 'var(--gray)' : 'white')};
          position: absolute;
          top: 0;
          right: 30px;
          transform: translateY(-100%);
        }

        &:hover::after {
          background-color: var(--gray);
        }
      }

      &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    &__value-container {
      padding: 0;
      height: 0;
      overflow: hidden;
    }

    &__placeholder,
    &__indicator-separator {
      display: none;
    }
  }
`

const OrgSwitcher = ({ handleValueChange, setIsMenuOpen }) => {
  const { orgOptions, selectedOrg, setOrg, routeToBasePath} = useAuth()
  const [focused, setFocused] = useState('')
  const styles = {
    option: (styles, { data, isFocused }) => {
      if (isFocused) setFocused(data)
      return { ...styles }
    }
  }

  const onOrgChange = org => {
    setOrg(org)
    routeToBasePath()
  }

  return (
    <StyledSelect
      classNamePrefix='org-switcher'
      onChange={onOrgChange}
      onInputChange={handleValueChange}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      value={selectedOrg}
      options={orgOptions}
      isFirstActive={selectedOrg.value === orgOptions?.[0]?.value}
      isFirstFocused={focused.value === orgOptions?.[0]?.value}
      defaultValue={selectedOrg}
      noOptionsMessage={() => "No organizations found!"}
      styles={styles}
    />
  )
}

export default OrgSwitcher
