import styled, { keyframes } from 'styled-components'

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #244d85;
    border-color: #244d85 transparent #244d85 transparent;
    animation: ${animation} 1.2s linear infinite;
  }
`

const ScreenLoader = () => {
  return (
    <Box className='spinner'>
      <Spinner />
    </Box>
  )
}

export default ScreenLoader
