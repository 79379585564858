import { gql } from '@apollo/client'

const downloadDoc = gql`
  query downloadDoc($organizationId: String!, $fileId: String!) {
    downloadDocument(organizationId: $organizationId, fileId: $fileId) {
      url
    }
  }
`

export default downloadDoc
