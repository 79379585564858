import auth0 from 'auth0-js'

const client = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT,
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
  responseType: 'token id_token',
})

export const authorize = () => client.authorize({scope:"openid name email user_id"})
export const changePassword = (options, cb) => client.changePassword(options, cb)