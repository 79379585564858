import { useContext } from 'react'
import ReactModal from 'react-modal'
import CollapseContext from '../../context/CollapseContext'
import useWindowSize from '../../hooks/useWindowSize'

const Drawer = ({ isOpen, children }) => {
  const { collapsed } = useContext(CollapseContext)
  const { width } = useWindowSize()

  const isMobile = width < 921

  const customStyles = {
    overlay: {
      left: isMobile ? 0 : collapsed ? '50px' : '293px',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      transition: 'all .5s ease'
    },
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      width: '100%',
      background: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      border: 0,
    }
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  )
}

export default Drawer
