import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import Drawer from '../Drawer/Drawer'
import Button from '../Button/Button'
import Radio from '../Radio/Radio'
import UPDATE_RECOMMENDATION from '../../graphql/updateRecommendation'
import createPriority from '../../graphql/createPriority'
import useAuth from '../../hooks/useAuth'
import useListStatuses from '../../hooks/useListStatuses'
import {
  valueToStatus,
  valueToSwimlane,
  transformEstimate,
  mapRecStatus,
  formatDescription,
  htmlToText,
} from '../../utils'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  max-width: 544px;
  width: 100%;
  background: #fff;
  border-radius: 12px;
  border: 2px solid #e3e9ef;
`

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 27px;
`

const Line = styled.div`
  height: 45px;
  width: 4px;
  background-color: #00bfff;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-right: 27px;
  margin-left: -2px;
`

const IconBox = styled.div`
  height: 40px;
  width: 40px;
  background-color: #2f5993;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 16px;

  svg {
    height: 20px;
    fill: white;
  }
`

const Title = styled.span`
  width: 80%;
  color: #244d85;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
`

const Desc = styled.span`
  color: #4b5882;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-left: 32px;
`

const Estimate = styled.span`
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
  padding: 5px 20px 5px 24px;
  color: #2f5993;
  font-size: 12px;
  font-weight: bold;
  background-color: #e5eefa;
  align-self: flex-start;
  margin: 11px 0 15px;
  margin-left: -2px;
`

const Text = styled.span`
  color: #4b5882;
  font-size: 14px;
  margin: 0 32px;
  max-height: 30vh;
  overflow: scroll;
`

const RadioRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  margin: 25px 40px 0;
`

const RadioBox = styled.div`
  width: 50%;
  margin-bottom: 12px;
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 47px;
`

const StyledButton = styled(Button)`
  background-color: ${p => (p.light ? '#F1F4F7' : '#244D85')};
  color: ${p => (p.light ? '#244D85' : '#fff')};
  font-weight: bold;
  letter-spacing: 2px;
  padding: ${p => (p.light ? '10px 19px' : '10px 32px')};
  border-radius: 99px;

  ${p => p.center && 'align-self: center'};

  &:hover { color: #fff; } 
`

const Area = styled.textarea`
  margin: 4px 55px 27px;
  padding: 13px;
  color: #4b5882;
  font-size: 11px;
  resize: none;
  border: 1px solid #e3e3e3;

  ::placeholder {
    color: #4b5882;
  }
`

const Message = styled.span`
  color: #4b5882;
  font-size: 14px;
  font-weight: 500;
  padding: 0 60px 0 25px;
  width: calc(100% - 27px);
`

const MessageLink = styled(Link)`
  color: #4b5882;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  margin: 0 0 40px 55px;
`

const radioOptions = [
  {
    value: 'doit',
    label: 'Trek10 - Do It',
    className: 'modal-radio-do-it'
  },
  {
    value: 'gettraining',
    label: 'Train Us',
    className: 'modal-radio-train-us'
  },
  {
    value: 'workwithtrek',
    label: 'Trek10 - Estimate It',
    className: 'modal-radio-work-with-trek'
  },
  {
    value: 'fixit',
    label: 'My Team Will Do It',
    className: 'modal-radio-my-team-will-do-it'
  },
  {
    value: 'discuss',
    label: 'Discuss',
    className: 'modal-radio-discuss'
  },
  {
    value: 'archive',
    label: 'Archive It',
    className: 'modal-radio-archive-it'
  },
]

const RecommendationModal = ({
  isOpen,
  toggle,
  id,
  title,
  description,
  estimation,
  awsPillar,
  shortDescription,
  recStatus,
  Icon,
}) => {
  const { user, selectedOrg } = useAuth()
  const history = useHistory()
  const [areaInput, setAreaInput] = useState('')
  const [selected, setSelected] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [updateRec, { loading }] = useMutation(UPDATE_RECOMMENDATION)
  const [createIssue, { loading: issueLoading }] = useMutation(createPriority)
  const listStatuses = useListStatuses()

  const notifyErrorMsg = message => toast.error(message)

  const handleSubmit = async () => {
    try {
      if (!selected) return
      setSubmitting(true)

      if (!['ARCHIVED', 'CLIENT_HANDLED'].includes(valueToStatus(selected))) {
        const priority = await createIssue({
          variables: {
            organizationId: selectedOrg.value,
            issue: {
              active: true,
              title,
              description: formatDescription(
                `Created from the Trek10 recommendation ${title}.
                ${description ? htmlToText(description) : ''}
                Original Estimate ${transformEstimate(estimation)} hours
                Created by: ${user['email']}`
              ),
              status: valueToSwimlane(selected, listStatuses),
              hoursEstimate: transformEstimate(estimation),
              awsPillar: awsPillar?.toLowerCase(),
              type: 'recommendations',
              comment: areaInput.trim().length < 1 ? undefined : areaInput,
              originalLink: 'rec:' +  id
            },
          },
          context: { version: 4 },
        })

        if (priority?.errors) {
          setSubmitting(false)
          throw new Error('Failed to create priority')
        }
      }

      const rec = await updateRec({
        variables: {
          id,
          recommendation: {
            status: valueToStatus(selected),
          },
        },
        context: {
          version: 1,
        },
      })

      if (rec?.errors) {
        setSubmitting(false)
        throw new Error('Failed to plan recommendation')
      }

      setSubmitting(false)
      setSubmitted(true)
      if (!['ARCHIVED', 'CLIENT_HANDLED'].includes(valueToStatus(selected))) return history.push('/priority-board')
      else toggle()
    } catch (err) {
      notifyErrorMsg(err.message)
    }
  }

  const isStatusOpen = recStatus === 'OPEN'

  return (
    <Drawer isOpen={isOpen}>
      <Box className="rec-modal">
        {submitted ? (
          <>
            <Head>
              <Line />
              <Message>
                Success! This recommendation has been added to the “To Do”
                column on your Priorities Board.
              </Message>
            </Head>
            <MessageLink to='/'>Click here to view.</MessageLink>
            <StyledButton fontSize="10px" light center onClick={toggle}>
              CLOSE
            </StyledButton>
          </>
        ) : (
          <>
            <Head>
              <Line />
              <IconBox>
                <Icon />
              </IconBox>
              <Title>{title}</Title>
            </Head>
            <Desc dangerouslySetInnerHTML={{ __html: shortDescription }} />
            {estimation && (
              <Estimate>{`Estimated ${estimation} hour`}</Estimate>
            )}
            <Text dangerouslySetInnerHTML={{ __html: description }} />
            {isStatusOpen ? (
              <>
                <RadioRow>
                  {radioOptions.map(item => (
                    <RadioBox key={item.value}>
                      <Radio
                        className={item.className}
                        active={selected === item.value}
                        value={item.value}
                        label={item.label}
                        onChange={value => setSelected(value)}
                      />
                    </RadioBox>
                  ))}
                </RadioRow>
                <Area
                  onChange={e => setAreaInput(e.target.value)}
                  rows={5}
                  placeholder='We are ready to get started on this recommendation'
                />
                <ButtonRow>
                  <StyledButton fontSize="10px" light onClick={toggle} className='rec-modal-close-btn'>
                    CANCEL
                  </StyledButton>
                  <StyledButton
                    fontSize="10px"
                    className='rec-modal-save-btn'
                    onClick={handleSubmit}
                    disabled={loading || issueLoading || !selected}
                    isSubmitting={isSubmitting}
                  >
                    SAVE
                  </StyledButton>
                </ButtonRow>
              </>
            ) : (
              <>
                <Desc style={{ marginTop: '25px' }}>Client has selected <strong>{mapRecStatus(recStatus)}</strong></Desc>
                <ButtonRow>
                  <StyledButton style={{ marginLeft: 'auto' }} fontSize="10px" light onClick={toggle} className='rec-modal-close-btn'>
                    CANCEL
                  </StyledButton>
              </ButtonRow>
              </>
            )}
          </>
        )}
      </Box>
    </Drawer>
  )
}

export default RecommendationModal
