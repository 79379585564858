import { useState, useEffect } from 'react'

const DROPZONE_ID = process.env.REACT_APP_DROPZONE_ID
const URL = 'https://trek10.sendsafely.com'
const getMessage = (message, url) =>
  `<br /><strong>${message}</strong><br /><a href="${url}" rel="noopener noreferrer">${url}</a>`

const useDropzone = () => {
  const [wrapper, initializeDropzone] = useState(null)
  const [widget, setWidget] = useState(null)

  useEffect(() => {
    if (
      !wrapper || 
      !window.jQuery || 
      !window.SendSafelyDropzone
    ) return

    const widgetWrapper = window.$(`.${wrapper}`)
    const widget = new window.SendSafelyDropzone(DROPZONE_ID, widgetWrapper)
    const MAX_WIDTH = '300px'

    widget.BACKGROUND_COLOR = '#ffffff'
    widget.DROP_TEXT_COLOR = '#4b5882'
    widget.HEIGHT = 'revert;'
    widget.WIDTH = '100%;'
    widget.FILE_ROW_STYLE += `color: ${widget.DROP_TEXT_COLOR}; max-width: ${MAX_WIDTH};`
    widget.DROPZONE_STYLE += `border-radius: 5px; max-width: ${MAX_WIDTH};`

    widget.url = URL
    widget.disableAutoSubmit = true
    widget.initialize()

    setWidget(widget)
  }, [wrapper])

  const finalizePackage = async message => {
    return new Promise(resolve => widget.finalizePackage(function (url) {
      resolve(getMessage(message, url))
    }))
  }

  return { dropzone: widget, initializeDropzone, finalizePackage }
}

export default useDropzone