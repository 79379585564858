import { useState } from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import styled from 'styled-components'
import Heading from '../../components/Heading/Heading'
import UsersTable from '../../components/UsersTable/UsersTable'
import Search from '../../components/Search/Search'
import Button from '../../components/Button/Button'
import useAuth from '../../hooks/useAuth'

const Box = styled.div`
  padding-right: 45px;
  margin-bottom: 44px;

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`

const Header = styled.div`
  margin-bottom: 35px;
  display: grid;
  grid-template-columns: max-content 400px 1fr;
  grid-gap: 20px;
  align-items: center;

  & > *:last-child {
    justify-self: end;
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: max-content 1fr max-content;
  }

  @media only screen and (max-width: 920px) {
    margin-top: 10px;
  }

  @media only screen and (max-width: 660px) {
    grid-template-columns: 1fr;

    & > *:last-child {
      justify-self: start;
    }
  }
`

const Users = ({ data = [], loading, error }) => {
  const match = useRouteMatch()
  const { isSuperAdmin, isOrgAdmin } = useAuth()
  const [searched, setSearched] = useState([])
  const users = searched.length > 0 ? searched : data
  const sorted = [...users].sort(alphabetically)
  const handleSearch = e => {
    const input = e.target.value.toLowerCase().trim()
    const searched = data.filter(
      ({ roles, user: { firstName, lastName, email } }) => {
        return (
          firstName?.toLowerCase().includes(input) ||
          lastName?.toLowerCase().includes(input) ||
          email?.toLowerCase().includes(input) ||
          roles?.map(role => role.toLowerCase()).includes(input)
        )
      },
    )

    setSearched(searched)
  }

  return (
    <Box>
      <Header>
        <Heading>Manage Users</Heading>
        <Search
          style={{ width: '100%' }}
          handleChange={handleSearch}
          placeholder='Search by first/last name, email, or role'
        />
        {(isSuperAdmin || isOrgAdmin) && (
          <Link to={`${match.path}/new`}>
            <Button>Invite User</Button>
          </Link>
        )}
      </Header>
      <UsersTable users={sorted} loading={loading} error={error} />
    </Box>
  )
}

function alphabetically(a, b) {
  return a.user.firstName?.localeCompare(b.user.firstName)
}

export default Users
