/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import decode from 'jwt-decode'
import qs from 'query-string'
import { authorize, changePassword } from '../services/auth0'
import getSuperAdminOrgs from '../graphql/getSuperAdminOrgs'
import {
  setToken,
  getToken,
  clearToken,
  persistSelectedOrg,
  getPersistedOrg,
  clearPersistedOrg,
} from '../utils/storage'

const localToken = getToken()
let localDecoded

try {
  localDecoded = decode(localToken)
} catch (err) {
  console.log(err)
}

const localOrgOptions = getOrgOptions(localDecoded)
const isOrgAdmin = checkOrgAdmin(localDecoded, localOrgOptions?.[0])
const isSuperAdmin = checkSuperAdmin(localDecoded)
const persistedOrg = getPersistedOrg()

const initialState = {
  isAuthenticated: !!localDecoded,
  user: localDecoded || null,
  orgOptions: localOrgOptions || [],
  selectedOrg: persistedOrg || localOrgOptions?.[0] || {},
  isOrgAdmin: isOrgAdmin || false,
  isSuperAdmin: isSuperAdmin || false,
}

const useAuthState = () => {
  const [state, setState] = useState(initialState)
  const history = useHistory()
  const { search } = useLocation()
  const hash = !!window.location.hash ? window.location.hash.substr(1) : null
  const [fetchOrgs, { data: superAdminOrgs }] = useLazyQuery(getSuperAdminOrgs, {
    variables: { filter: { isActive: { eq: true } } },
  })

  const params = useMemo(() => (hash ? hash.substr(1).split('&') : null), [
    hash,
  ])

  const id_token = params
    ? params.find(i => i.startsWith('id_token')).split('id_token=')[1]
    : null

  const setOrg = org => {
    setState(state => ({ ...state, selectedOrg: org }))
    persistSelectedOrg(org)
  }

  const routeToBasePath = () => {
    const basePath = window.location.pathname.split('/')[1]
    history.push(`/${basePath}`)
  }

  const login = () => {
    const queryParams = qs.parse(search)
    if (queryParams.next) {
      localStorage.setItem('next', queryParams.next)
    }
    authorize()
  }

  const logOut = () => {
    localStorage.setItem('next', window.location.pathname);
    clearToken()
    clearPersistedOrg()
    window.location.href = `${window.location.origin}/logged-out`
  }

  const checkExpire = time => {
    if (Date.now() > time * 1000) {
      logOut()
    }
  }

  useEffect(() => {
    if (id_token) {
      try {
        let decoded = decode(id_token)
        const orgOptions = getOrgOptions(decoded)
        const isOrgAdmin = checkOrgAdmin(decoded, orgOptions[0])
        const isSuperAdmin = checkSuperAdmin(decoded)
        const persistedOrg = getPersistedOrg()

        setState({
          isAuthenticated: true,
          user: decoded,
          orgOptions,
          selectedOrg: persistedOrg || orgOptions[0],
          isOrgAdmin,
          isSuperAdmin,
        })
        setToken(id_token)

        const next = localStorage.getItem('next')

        history.push(next || '/')
        localStorage.removeItem('next')
      } catch (err) {
        history.push('/sign-in')
      }
    }
  }, [id_token])

  const superOptions = superAdminOrgs?.listOrganizations.items
    .map(createSuperOrgOption)
    .sort(alphabetically)

  useEffect(() => {
    if (!state.isSuperAdmin) return
    fetchOrgs()
  }, [state.isSuperAdmin])

  useEffect(() => {
    if (!superAdminOrgs?.listOrganizations.items.length) return
    setState(state => ({ ...state, orgOptions: superOptions }))
  }, [superAdminOrgs?.listOrganizations.items])

  useEffect(() => {
    if (state.isAuthenticated) {
      checkExpire(state.user.exp)

      setInterval(() => checkExpire(state.user.exp), 30000)
    }
  }, [state.isAuthenticated])

  useEffect(() => {
    setState(state => ({ ...state, isOrgAdmin: checkOrgAdmin(state.user, state.selectedOrg) }))
  }, [state.selectedOrg])

  return { ...state, setOrg, login, logOut, changePassword, routeToBasePath }
}

function getOrgOptions(user) {
  if (!user) return

  const orgs = user['https://harbortrek10/organizations']
  return orgs.map(createOrgOption)
}

function createOrgOption({ organization: { name, id } }) {
  return { label: name, value: id }
}

function createSuperOrgOption({ name, id }) {
  return { label: name, value: id }
}

function checkOrgAdmin(user, selectedOrg) {
  if (!user) return

  return user['https://harbortrek10/organizations']
    .filter(org => org.roles.includes('ADMIN'))
    .map(org => org.organization.id)
    .includes(selectedOrg?.value)
}

function checkSuperAdmin(user) {
  if (!user) return
  return user['https://harbortrek10/users'].superAdmin
}

function alphabetically(a, b) {
  return a.label.localeCompare(b.label, 'en', { ignorePunctuation: true })
}

export default useAuthState
