import styled from 'styled-components'

const Row = styled.div`
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
`

const Circle = styled.span`
  height: 17px;
  width: 17px;
  background-color: ${p => p.active ? '#FFFFFF' : '#CAD4DE'};
  ${p => p.active && 'border: 6px solid #00BFFF'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
`

const Text = styled.span`
  color: #4B5882;
  font-size: 12px;
  font-weight: bold;
`

const Radio = ({ value, label, active, onChange, className }) => {

  return (
    <Row onClick={() => onChange(value)}>
      <Circle active={active} className={className} />
      <Text className={className}>{label}</Text>
    </Row>
  )
}

export default Radio
