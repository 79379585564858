import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Heading from '../Heading/Heading'
import addIcon from '../../assets/icons/add.svg'
import arrowRightIcon from '../../assets/icons/arrow-right.svg'

const Col = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;

  ${p => (p.mt ? 'margin-top: 35px' : 'margin-left: 100px')};

  @media only screen and (max-width: 1400px) {
    ${p => !p.mt && 'margin-top: 80px'};
    margin-left: revert;
  }
`

const LinkBox = styled.div`
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(36, 77, 133, 0.16);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

const BigLink = styled(Link)`
  width: 100%;
  background-color: #f1f4f7;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;

  span {
    color: #4b5882;
    font-size: 16px;
    font-weight: bold;
  }
`

const List = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`

const Item = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 12px 20px;

  span {
    color: #4b5882;
    font-size: 16px;
    font-weight: bold;
  }

  img {
    margin-left: auto;
  }
`

const Badge = styled.div`
  background-color: #f1f4f7;
  color: #a5acc2;
  font-size: 16px;
  font-weight: bold;
  padding: 4px 9px;
  margin-left: 13px;
  border-radius: 6px;
`

const QuickLinks = ({ tickets }) => {
  const openTickets = tickets.items.length

  return (
    <Col>
      <Heading>Quick Links</Heading>
      <Col mt>
        <BigLink className='quick-links-open-new-support-case' to='/support-cases/new'>
          <LinkBox>
            <img src={addIcon} alt='icon' />
          </LinkBox>
          <span>Open a Request</span>
        </BigLink>
        <List>
          <Item className='quick-links-view-recent-support-cases' to='/support-cases'>
            <span>View Recent Support Cases</span>
            {!!openTickets && <Badge>{`${openTickets} Open`}</Badge>}
            <img src={arrowRightIcon} alt='icon' />
          </Item>
          <Item className='quick-links-see-trek10s-current-priorities' to='/priority-board'>
            <span>View Team Support Board</span>
            <img src={arrowRightIcon} alt='icon' />
          </Item>
        </List>
      </Col>
    </Col>
  )
}

export default QuickLinks
