import styled from 'styled-components'
import { NavLink, useRouteMatch } from 'react-router-dom'
import SecurityIcon from './icons/SecurityIcon'
import ReliabilityIcon from './icons/ReliabilityIcon'
import PerformanceIcon from './icons/PerformanceIcon'
import TrainingIcon from './icons/TrainingIcon'
import OperationsIcon from './icons/OperationsIcon'
import CostIcon from './icons/CostIcon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 23px;

  @media only screen and (max-width: 920px) {
    display: none;
  }
`

const Divider = styled.div`
  background-color: #f1f4f7;
  width: 100%;
  height: 1px;
`

const Row = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1400px) {
    flex-wrap: wrap;
  }
`

const TabItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;

  section {
    display: flex;
    align-items: center;
    padding: 12px 0 24px;
  }

  svg {
    height: 24px;
    width: auto;
    margin-right: 15px;
    fill: #a5acc2;
  }

  span {
    font-size: 16px;
    color: #a5acc2;
    font-weight: 500;
  }

  aside {
    height: 4px;
    visibility: hidden;
    background-color: transparent;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:not(:last-child) {
    margin-right: 60px;
  }

  &.active {
    svg {
      fill: #00bfff;
    }

    span {
      color: #00bfff;
      font-weight: 900;
    }

    aside {
      visibility: visible;
      background-color: #00bfff;
    }
  }
`

const RecommendationTabs = () => {
  const match = useRouteMatch()

  return (
    <Wrapper>
      <Row>
        <TabItem to={`${match.path}/security`} exact>
          <section>
            <SecurityIcon />
            <span>Security</span>
          </section>
          <aside />
        </TabItem>
        <TabItem to={`${match.path}/operations`}>
          <section>
            <OperationsIcon />
            <span>Operations</span>
          </section>
          <aside />
        </TabItem>
        <TabItem to={`${match.path}/reliability`}>
          <section>
            <ReliabilityIcon />
            <span>Reliability</span>
          </section>
          <aside />
        </TabItem>
        <TabItem to={`${match.path}/performance`}>
          <section>
            <PerformanceIcon />
            <span>Performance</span>
          </section>
          <aside />
        </TabItem>
        <TabItem to={`${match.path}/cost`}>
          <section>
            <CostIcon />
            <span>Cost</span>
          </section>
          <aside />
        </TabItem>
        <TabItem to={`${match.path}/training`}>
          <section>
            <TrainingIcon />
            <span>Training</span>
          </section>
          <aside />
        </TabItem>
      </Row>
      <Divider />
    </Wrapper>
  )
}

export default RecommendationTabs
