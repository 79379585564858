import Select from 'react-select'
import styled from 'styled-components'

const StyledSelect = styled(Select)`
  width: 100%;

  .react-select {
    &__menu {
      position: absolute;
    }

    &__option {
      background-color: #fff;

      &:hover {
        background-color: #f1f4f7;
      }

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
        background-color: ${p =>
    p.selected === p.options?.[0].value ? '#f1f4f7' : '#fff'};
        position: absolute;
        top: 0;
        right: 30px;
        transform: translateY(-95%);
        z-index: 1;
      }

      &:first-child {
        background-color: ${p =>
    p.selected === p.options?.[0].value && '#f1f4f7'};
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &:first-child:hover::after {
        background-color: #f1f4f7;
        z-index: 2;
      }
    }
  }
`

const typographyStyles = {
  color: '#a5acc2',
  fontSize: '14px',
  fontWeight: 'bold',
}

const Filter = ({
  handleFilter,
  selected,
  options,
  placeholder,
  defaultMenuIsOpen = false,
  ...rest
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      ...typographyStyles,
      backgroundColor: `${state.isSelected && '#F1F4F7'} !important`,
      padding: '12px',
      cursor: 'pointer',
    }),
    indicatorSeparator: (_provided, _) => ({ display: 'none' }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      // display: state.hasValue ? 'none' : '',
      marginTop: '2px',
    }),
    singleValue: (provided, _) => ({
      ...provided,
      ...typographyStyles,
    }),
    placeholder: (provided, _) => ({
      ...provided,
      ...typographyStyles,
    }),
    menu: (provided, _) => ({
      ...provided,
      borderRadius: '10px',
      boxShadow:
        '0 2px 8px 0 rgba(36,77,133,0.13), 0 -2px 8px 0 rgba(36,77,133,0.13)',
      marginTop: '15px',
    }),
    menuList: (provided, _) => ({
      ...provided,
      overflowY: 'visible',
      borderRadius: '10px',
      paddingTop: 0,
      paddingBottom: 0,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 8px',
      height: '36px',
      border: '2px solid #E3E9EF',
      borderRadius: '20px',
      cursor: 'pointer',
    }),
  }

  return (
    <StyledSelect
      onChange={handleFilter}
      classNamePrefix='react-select'
      selected={selected}
      placeholder={placeholder}
      options={options}
      styles={customStyles}
      isSearchable={false}
      defaultMenuIsOpen={defaultMenuIsOpen}
      {...rest}
    />
  )
}

export default Filter
