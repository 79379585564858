import { useEffect } from 'react'
import styled from 'styled-components'
import { Switch, Route, Redirect, useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import RecommendationTabs from '../../components/RecommendationTabs/RecommendationTabs'
import RecommendationCards from '../../components/RecommendationCards/RecommendationCards'
import Heading from '../../components/Heading/Heading'
import Tooltip from '../../components/Tooltip/Tooltip'
import useAuth from '../../hooks/useAuth'
import { useQuery, useLazyQuery } from '@apollo/client'
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader'
import GET_RECOMMENDATIONS from '../../graphql/listRecommendations'
import GET_RECOMMENDATION from '../../graphql/getRecommendation'

const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 88px;

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #a5acc2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const Recommendations = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { selectedOrg, setOrg, orgOptions } = useAuth()
  const { data, loading } = useQuery(GET_RECOMMENDATIONS, {
    variables: { organizationId: selectedOrg.value.split('o:')[1] },
    context: { version: 1 }
  })
  const [fetchRecommendation, { data: recommendation, loading: recLoading }] = useLazyQuery(GET_RECOMMENDATION, {
    context: { version: 1 }
  })

  useEffect(() => {
    fetchRecommendation({ variables: { id: location.pathname.split('/').pop() } })
    // eslint-disable-next-line
  }, [])

  const redirectItem = (recommendation?.getRecommendation?.status === "OPEN") ? recommendation?.getRecommendation : null;
  const targetOrg = orgOptions?.find(org => org.value.split(':')[1] === redirectItem?.organizationId)

  useEffect(() => {
    if (!targetOrg) return
    setOrg(targetOrg)
    const path = `${match.path}/${redirectItem.awsPillar.toLowerCase()}`
    history.push(path, { rec: redirectItem })
    // eslint-disable-next-line
  }, [targetOrg])

  if (loading || recLoading) return <ScreenLoader />

  const recIds = data.listRecommendations.items.map(item => item.id)
  const recommendations = [
    ...data.listRecommendations.items,
    !recIds.includes(location.state?.rec.id) ? location.state?.rec : undefined
  ].filter(Boolean)

  const securityItems = recommendations.filter(item => item.awsPillar === 'Security')
  const operationsItems = recommendations.filter(item => item.awsPillar === 'Operations')
  const reliabilityItems = recommendations.filter(item => item.awsPillar === 'Reliability')
  const performanceItems = recommendations.filter(item => item.awsPillar === 'Performance')
  const costItems = recommendations.filter(item => item.awsPillar === 'Cost')
  const trainingItems = recommendations.filter(item => item.awsPillar === 'Training')

  return (
    <Col>
      <Row>
        <Heading style={{ marginRight: '16px' }}>
          Trek10 recommendations
        </Heading>
        <Tooltip
          className='recommendations-tooltip'
          html={true}
          content="The pillars are derived from AWS's 
                    Well Architected Framework and colored 
                    based on Trek10's expert guidance on the 
                    criticality of recommendations in that area.
                    <br />
                    <br />
                    <a class='tooltip-link' href='#'>Learn More</a>
        ">
          <Info>
            <FontAwesomeIcon icon={faQuestion} color='#e3e9ef' size='sm' />
          </Info>
        </Tooltip>
      </Row>
      <RecommendationTabs />
      <Switch>
        <Route path={`${match.path}/security`}>
          <RecommendationCards data={securityItems} />
        </Route>
        <Route path={`${match.path}/operations`}>
          <RecommendationCards data={operationsItems} />
        </Route>
        <Route path={`${match.path}/reliability`}>
          <RecommendationCards data={reliabilityItems} />
        </Route>
        <Route path={`${match.path}/performance`}>
          <RecommendationCards data={performanceItems} />
        </Route>
        <Route path={`${match.path}/cost`}>
          <RecommendationCards data={costItems} />
        </Route>
        <Route path={`${match.path}/training`}>
          <RecommendationCards data={trainingItems} />
        </Route>
        <Route><Redirect to={`${match.path}/security`} /></Route>
      </Switch>
    </Col>
  )
}

export default Recommendations
