import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import styled from 'styled-components'
import Heading from '../Heading/Heading'
import Button from '../Button/Button'
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"
import createNewUser from '../../graphql/createNewUser'
import getUsers from '../../graphql/getUsers'
import { validate as validateEmail } from 'isemail'
import { parseOneAddress as parseEmail } from 'email-addresses'

export const btnStyles = { marginLeft: '10px' }
export const Wrapper = styled.div`
  padding-right: 45px;
  margin-bottom: 44px;

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  align-items: center;

  & > label {
    font-size: 14px;
    color: #000;
    margin-left: 5px;
    margin-top: -2px;
  }
`

export const Box = styled.div`
  margin-top: 35px;
  margin-bottom: 20px;
  padding: 20px 12px;
  border-radius: 5px;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.09);

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media only screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`

export const Input = styled.input`
  border: 1px solid #ccc;
  padding: 8px 15px;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
`

export const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000;
`

export const ErrorMsg = styled.span`
  color: red;
  margin: 12px 0;
`

const NewUser = ({ domainWhitelist = [] }) => {
  const history = useHistory()
  const { isSuperAdmin, isOrgAdmin, selectedOrg } = useAuth()
  const [createUser, { loading, error }] = useMutation(createNewUser)

  const [submitting, setSubmitting] = useState(false)
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    roles: ['DOCUMENTATION', 'SUPPORT', 'MONITOR'],
  })
  const [errors, setErrors] = useState({})

  const [isConfirmationOpen, setConfirmationOpen] = useState(false)
  const toggleConfirmation = () => setConfirmationOpen(open => !open)

  const restrictedTo = [isSuperAdmin, isOrgAdmin]
  if (!restrictedTo.includes(true)) history.push('/users')

  const { local = "", domain = "" } = parseEmail(form.email) ?? {}

  const handleChange = e => {
    const { name, value, checked } = e.target

    const newValues = {
      ...(name.includes('roles')
        ? {
          [name]: checked
            ? [...form.roles, value]
            : [...form.roles].filter(role => role !== value),
        }
        : { [name]: value }),
    }

    setForm(prev => ({ ...prev, ...newValues }))
  }

  const handleSubmit = () => {
    const errors = validate(form)

    if (errors) return setErrors(errors)
    setErrors({})

    if (!isWhitelisted(domain, domainWhitelist)) return toggleConfirmation()

    return inviteUser()
  }

  const inviteUser = async () => {
    try {
      setSubmitting(true)

      const variables = {
        organizationId: selectedOrg.value,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email.trim(),
        roles: form.roles,
      }

      await createUser({
        variables,
        refetchQueries: [
          {
            query: getUsers,
            variables: { id: selectedOrg.value }
          },
        ],
      })

      setSubmitting(false)
      history.push('/users')
    } catch (err) {
      console.log(err)
    }
  }

  if (error) return <ErrorMsg>{error.message}</ErrorMsg>

  return (
    <Wrapper>
      <Heading>Invite User</Heading>
      <Box>
        <Col>
          <Label>First Name</Label>
          <Input
            name='firstName'
            value={form.firstName}
            onChange={handleChange}
          />
          {errors.firstName && <ErrorMsg>{errors.firstName}</ErrorMsg>}
        </Col>
        <Col>
          <Label>Last Name</Label>
          <Input
            name='lastName'
            value={form.lastName}
            onChange={handleChange}
          />
          {errors.lastName && <ErrorMsg>{errors.lastName}</ErrorMsg>}
        </Col>
        <Col>
          <Label>Email</Label>
          <Input name='email' value={form.email} onChange={handleChange} />
          {errors.email && <ErrorMsg>{errors.email}</ErrorMsg>}
        </Col>
        <Col>
          <Label>Roles</Label>
          <Row>
            <input
              type='checkbox'
              id='roles'
              name='roles'
              value={'admin'.toUpperCase()}
              onChange={handleChange}
            />
            <label htmlFor='roles'>Admin</label>
          </Row>
        </Col>
      </Box>
      <Row>
        <Button isSecondary onClick={() => history.push('/users')}>
          Cancel
        </Button>
        <Button disabled={loading} isSubmitting={submitting} onClick={handleSubmit} style={btnStyles}>
          Save
        </Button>
      </Row>
      {isConfirmationOpen && (
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          toggle={toggleConfirmation}
          title='Email Outside Domain'
          message={`
            <p>${local}@<strong>${domain}</strong> is not a whitelisted domain for <strong>${selectedOrg.label}</strong></p>
            <br />
            <p>Are you sure you want to add <strong>${form.firstName} ${form.lastName}</strong>?</p>
            <br />
            <p><strong>Whitelisted Domains</strong>
            <br />
            ${domainWhitelist.join(", ")}</p>
          `}
          handler={inviteUser}
          submitting={submitting}
        />
      )}
    </Wrapper>
  )
}

const validatePhone = (phoneNumber) => {
  return !!phoneNumber.match(/^\d{3}-\d{3}-\d{4}$/g);
}

export function validate(obj) {
  const errors = {}
  const whenCapitalized = /(?=[A-Z])/
  const transform = key => key.split(whenCapitalized).join(' ').toLowerCase()

  for (const [key, value] of Object.entries(obj)) {
    if ((value === "" || value === undefined || value === null) && key !== "phone") errors[key] = `Please fill ${transform(key)} field!`
    if (key === "email" && value && !validateEmail(value)) errors[key] = 'Please provide a valid email'
    if (key === "phone" && value && !validatePhone(value)) errors[key] = 'Please provide a valid phone number'
  }

  return !!Object.keys(errors).length ? errors : null
}

function isWhitelisted(candidateDomain, domainWhitelist = []) {
  return domainWhitelist.includes(candidateDomain)
}

export default NewUser
