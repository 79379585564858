import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import monthlyBudgetIcon from '../../assets/icons/monthly-budget.svg'
import csmIcon from '../../assets/icons/csm.svg'
import logo from '../../assets/images/logo.png'
import logoSmall from '../../assets/images/logo-small.png'
import Nav from './Nav/Nav'
import MonthlyBudget from './MonthlyBudget'
import UserProfile from './UserProfile'
import EntitlementStatus from './EntitlementStatus'
import CollapseContext from '../../context/CollapseContext'

const Box = styled.div`
  width: ${p => (p.collapsed ? '50px' : '293px')};
  background-color: #244d85;
  min-height: 100vh;
  margin-right: 44px;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  transition: width 0.5s ease;
`

const Img = styled.img`
  ${p =>
    p.sm &&
    css`
      width: 40px;
      height: auto;
    `}
`

const IconCol = styled.div`
  display: flex;
  flex-direction: column;
`

const IconBox = styled.div`
  display: flex;
  position: relative;

  :hover {
    background: #244d85;

    section {
      display: flex;
    }
  }

  > img {
    padding: 10px 9px;
    opacity: 0.5;
    cursor: pointer;

    :hover {
      opacity: 1;
    }

    path {
      width: 100% important!;
    }
  }
`

const Abs = styled.section`
  position: absolute;
  bottom: 0;
  left: 50px;
  background: #244d85;
  z-index: 12;
  padding-bottom: 25px;
  display: none;
  width: 293px;
`

const ResizeBox = styled.div`
  padding: 31px 10px 96px 27px;
  width: 100%;
  align-self: flex-start;
  display: flex;
  align-items: center;
  ${p =>
    p.small
      ? `flex-direction: column; padding: 31px 0 96px 0`
      : `justify-content: space-between`};

  & > * {
    cursor: pointer;
  }

  & > *:not(:last-child) {
    margin: ${p => (p.small ? '0 0 10px 0' : '0 10px 0 0')};
  }
`

const SideNav = () => {
  const { collapsed, setCollapsed } = useContext(CollapseContext)

  const onCollapse = () => setCollapsed(i => !i)

  return (
    <Box collapsed={collapsed}>
      {collapsed ? (
        <ResizeBox small={true}>
          <Img sm src={logoSmall} alt='brand-logo' onClick={onCollapse} />
          <FontAwesomeIcon className='sidebar-maximize' onClick={onCollapse} icon={faAngleDoubleRight} />
        </ResizeBox>
      ) : (
        <ResizeBox>
          <Img src={logo} style={{width:"80%"}} alt='brand-logo' onClick={onCollapse} />
          <FontAwesomeIcon className='sidebar-minimize' onClick={onCollapse} icon={faAngleDoubleLeft} />
        </ResizeBox>
      )}
      <Nav collapsed={collapsed} />
      {collapsed ? (
        <IconCol>
          <IconBox>
            <img src={monthlyBudgetIcon} alt='monthly-budget-icon' />
            <Abs>
              <MonthlyBudget />
            </Abs>
          </IconBox>
          <IconBox>
            <img src={csmIcon} alt='csm-icon' />
            <Abs>
              <UserProfile />
            </Abs>
          </IconBox>
        </IconCol>
      ) : (
        <>
          <MonthlyBudget />
          <EntitlementStatus />
          <UserProfile />
        </>
      )}
    </Box>
  )
}

export default SideNav
