import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Quill from 'quill'
import QuillBetterTable from 'quill-better-table'
import Button from '../Button/Button'

Quill.register({
  'modules/better-table': QuillBetterTable
}, true)

const icons = Quill.import('ui/icons');
icons['code-block'] = `
    <svg viewbox="0 -2 15 18">
      <polyline class="ql-even ql-stroke" points="2.48 2.48 1 3.96 2.48 5.45"/>
      <polyline class="ql-even ql-stroke" points="8.41 2.48 9.9 3.96 8.41 5.45"/>
      <line class="ql-stroke" x1="6.19" y1="1" x2="4.71" y2="6.93"/>
      <polyline class="ql-stroke" points="12.84 3 14 3 14 13 2 13 2 8.43"/>
    </svg>
`;

const modules = {
  table: false,
  'better-table': {
    operationMenu: {
      items: {
        unmergeCells: {
          text: 'Another unmerge cells name'
        }
      }
    }
  },
  toolbar: [
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike',],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
    ['blockquote', 'code', 'code-block'],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  }
}

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'code', 'code-block'
]

const EditorWrapper = styled.div`
  color: #000;
  margin-bottom: 10px;
  position: relative;

  .ql-editing {
    top: 0 !important;
    left: 0 !important;
  }
  
  .ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: unset;
  }

  .ql-editor {
    max-height: 300px;
    overflow-y: scroll;
  }
`

const Editor = ({ onChange, tables = false, ...rest }) => {
  const quillRef = useRef()
  const editorRef = useRef()

  useEffect(() => {
    editorRef.current = new Quill(quillRef.current, {
      theme: 'snow',
      modules,
      formats,
      bounds: '.app'
    })

    // Override QuillBetterTable backspace key handler for lists
    const curBinding = editorRef.current.keyboard.bindings['Backspace'].pop()
    editorRef.current.keyboard.bindings['Backspace'].unshift(curBinding)

    // add keyboard binding：Backspace
    // prevent user hits backspace to delete table cell
    editorRef.current.keyboard.addBinding(
      { key: 'Backspace' },
      {},
      function (range, context) {
        if (range.index === 0 || this.quill.getLength() <= 1) return true;
        const [line] = this.quill.getLine(range.index);
        if (context.offset === 0) {
          const [prev] = this.quill.getLine(range.index - 1);
          if (prev != null) {
            if (prev.statics.blotName === 'table-cell-line' &&
              line.statics.blotName !== 'table-cell-line') return false;
          }
        }
        return true
      })
    editorRef.current.keyboard.addBinding(
      { key: 'Backspace' },
      { 
        format: ['blockquote', 'list']},
      function (range, context) {
        if (context.offset === 0) {
          this.quill.format('indent', '-1', Quill.sources.USER);
          this.quill.format('list', false, Quill.sources.USER);
        }
        return true
      })
    // since only one matched bindings callback will execute.
    // expected my binding callback execute first
    let thisBinding = editorRef.current.keyboard.bindings['Backspace'].pop()
    editorRef.current.keyboard.bindings['Backspace'].splice(0, 1, thisBinding)

    editorRef.current.on('text-change', () => {
      const delta = editorRef.current.getContents()
      const content = quillRef.current.querySelector('.ql-editor').innerHTML;

      onChange(content, delta, editorRef.current)
    })
    // eslint-disable-next-line
  }, [])

  const handleTableInsert = () => {
    const tableModule = editorRef.current.getModule('better-table')
    tableModule.insertTable(3, 3)
  }


  return (
    <EditorWrapper {...rest} >
      <div ref={quillRef} />
      {tables && <Button isTertiary onClick={handleTableInsert}>Insert Table</Button>}
    </EditorWrapper>
  )
}

export default Editor