import { Redirect, Route } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const ProtectedRoute = ({ children, location, ...rest }) => {
  const { isAuthenticated } = useAuth()

  const next = `${location.pathname}${location.search}`

  if (!isAuthenticated) {
    return <Redirect to={`/sign-in?next=${next}`} />
  }

  return (
    <Route {...rest} location={location}>
      {children}
    </Route>
  )
}

export default ProtectedRoute
