import styled from 'styled-components'
import { Redirect } from 'react-router-dom'
import React, { useState } from 'react';
import Trek10Logo from './icons/Trek10Logo'
import useAuth from '../../hooks/useAuth'
import { Helmet } from "react-helmet"
import SignUp from '../SignUp/SignUp';

const Box = styled.div`
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  color: #4b5882;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Divider = styled.hr`
  border-top: 1px solid #c7c7c7;
  margin: 10px 0;
  margin-top: 20px;
`

const Header = styled.header`
  margin-top: 20px;
  margin-bottom: 44px;
  width: 75%;
`

const Main = styled.main`
  margin-top: 44px;
  width: 85%;
`

const Footer = styled.footer`
  margin-top: 18px;
  width: 83%;
  font-size: 12px;
  line-height: 18px;
`

const Heading = styled.h1`
  margin-right: auto;
  color: #363636;
  display: inline-block;
  padding-bottom: 20px;
  font-size: 42px;
  font-weight: 400;
  line-height: 0.8;
  text-transform: capitalize;
  position: relative;

  &::after {
    content: '';
    width: 90px;
    height: 3px;
    background-color: #4b5882;
    color: red;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(-100%);
  }
`

const Logo = styled.figure`
  width: 100%;
`

// const Version = styled.p`
//   margin-top: -10px;
//   text-align: right;
//   font-size: 14px;
// `

const Button = styled.button`
  background-color: #244d85;
  border: none;
  outline: none;
  border-radius: 20px;
  cursor: pointer;
  height: 32px;
  width: 100%;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  transition: 0.2s;
  margin-top: 10px;

  &:hover {
    background-color: #1d3e6b;
  }
`

const Link = styled.a`
  text-decoration: none;
  color: #00bfff;
`

const ThankYouBanner = styled.div`
  padding: 20px;
  background-color: #4CAF50; // Green background
  color: white;
  text-align: left; // Align text to the left
  align-items: center; // Vertically align text in the middle
  border-radius: 4px;
`;

const getUrlParameter = (name) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};


const SignIn = () => {
  const { isAuthenticated, login } = useAuth()
  const [ formSubmitted, setFormSubmitted ] = useState(false)
  
  if (isAuthenticated) {
    return <Redirect to='/' />
  }
  
  const handleFormSubmitted = (formData) => {
    setFormSubmitted(true)
  };

  return (
    <Box>
      <Header>
        <Logo>
          <Trek10Logo />
        </Logo>
        {/* <Version>Version 0.1.0</Version> */}
      </Header>
      <Heading>Welcome!</Heading>
      <Main>
      {formSubmitted ? (
        <ThankYouBanner>
          <strong>Success!</strong> 🚀<br />
          We've got your details. A response will be heading your way within 24-48 hours to the email you've shared. Thanks for choosing us!
        </ThankYouBanner>
      ) : (
        <>
        { getUrlParameter('x-amzn-marketplace-token')  ? (
          <>
            <SignUp onFormSubmit={handleFormSubmitted} />
            <Divider />
          </>
          ) : null}
          <Button onClick={login}>Login</Button>
        </>
      )}
        <Helmet>
            <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=48eb206c-82f3-4e21-b050-b77808c28b91"> </script>
        </Helmet>
      </Main>
      <Footer>
        <p>
          Contact our support team at any time by emailing{' '}
          <Link href='mailto: support@trek10.com'>support@trek10.com</Link> or
          phone (888) 736-2446.
        </p>
      </Footer>
    </Box>
  )
}

export default SignIn
