import { useState } from 'react'
import { useSpring } from 'react-spring'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import useGlobalSearch from '../../hooks/useGlobalSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons'
import Progress from '../Progress/Progress'
import MobileNav from '../SideNav/MobileNav'
import logo from '../../assets/images/logo.png'
import Organization from '../Organization/Organization'
import User from '../User/User'
import searchIcon from '../../assets/images/search-icon.png'
import getHoursSummarybyOrg from '../../graphql/getHoursSummary'
import useAuth from '../../hooks/useAuth'

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Row = styled.div`
  height: 75px;
  width: 100%;
  background-color: #244d85;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px !important;
    height: 20px;
    color: #6f91bf;
    cursor: pointer;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Img = styled.img`
  margin-left: 25px;
  height: auto;
  width: 114px;
`

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #3b639a;
  cursor: pointer;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e3e9ef;
  padding: 24px 20px;
`

const Light = styled.span`
  color: #a5acc2;
  font-family: Aeonik;
  font-size: 12px;
  font-weight: 500;
`

const Title = styled.span`
  color: #4b5882;
  font-size: 18px;
  font-weight: 900;
  margin: 8px 0 18px;
`

const IdentityWrapper = styled.div`
  margin: 20px auto 0 auto;
  padding: 0 20px;
  width: 100%;

  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`

const SearchWrapper = styled.div`
  padding: 20px;
  background-color: #fff;
`

const Input = styled.input`
  width: 100%;
  background-color: #f1f4f7;
  padding: 16px 20px 16px 68px;
  color: #a5acc2;
  font-size: 16px;
  font-weight: bold;
  background-image: url(${searchIcon});
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 30px center;
  border: 0;
  outline: none;
  border-radius: 90px;

  &::placeholder {
    color: #a5acc2;
  }
`

const MobileHeader = () => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => setOpen(open => !open)

  const { globalQuery, setGlobalQuery } = useGlobalSearch()
  const [isShowSearch, setIsShowSearch] = useState(false)

  const { selectedOrg } = useAuth()
  const { data, loading } = useQuery(getHoursSummarybyOrg, {
    variables: { organizationId: selectedOrg.value },
    context: { version: 4 },
  })

  const stats = {
    consumed: data?.getHoursSummarybyOrg?.consumed || 0,
    budget: data?.getHoursSummarybyOrg?.budget || 0,
  }

  const percent = loading || stats.consumed === stats.budget ? 0 : stats.consumed === 0 && stats.consumed < stats.budget ? 100 : (stats.consumed / stats.budget) * 100 || 0
  const isOverdue = loading ? false : stats.consumed > stats.budget

  const navAnimation = useSpring({
    left: isOpen ? `0` : `-293px`,
    opacity: isOpen ? 1 : 0,
  })

  return (
    <Col>
      <Row>
        <Flex>
          <FontAwesomeIcon icon={faBars} onClick={toggleOpen} />
          <Img src={logo} alt='brand-logo' />
        </Flex>
        <SearchBox onClick={() => setIsShowSearch(toggle => !toggle)}>
          <FontAwesomeIcon icon={faSearch} />
        </SearchBox>
      </Row>
      {isShowSearch && (
        <SearchWrapper>
          <Input
            type='text'
            placeholder='Search for support tickets'
            onChange={e => setGlobalQuery(e.target.value)}
            value={globalQuery}
            className='global-search'
          />
        </SearchWrapper>
      )}
      <Box>
        <Light>Monthly Budget</Light>
        {isOverdue ? (
          <Title>{checkDecimals(stats.consumed - stats.budget)} hours {isOverdue ? 'overage' : 'remaining'}</Title>
        ) : (
          <Title>{checkDecimals(stats.budget - stats.consumed)} hours {isOverdue ? 'overage' : 'remaining'}</Title>
        )}
        <Progress isOverdue={isOverdue} percent={percent || 1} />
      </Box>
      <IdentityWrapper>
        <Organization />
        <User />
      </IdentityWrapper>
      <MobileNav toggleOpen={toggleOpen} style={navAnimation} />
    </Col>
  )
}

function checkDecimals(num) {
  if (!num.toString().includes('.')) return num
  return num.toFixed(2)
}

export default MobileHeader
