import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import dashboardIcon from '../../../assets/icons/dashboard.svg'
import recommendationsIcon from '../../../assets/icons/recommendations.svg'
import priorityBoardIcon from '../../../assets/icons/priorities-board.svg'
import supportCasesIcon from '../../../assets/icons/support-cases.svg'
import reportsIcon from '../../../assets/icons/reports.svg'
import documentIcon from '../../../assets/icons/documents.svg'

const Col = styled.div`
  display: flex;
  flex-direction: column;

  a {
    padding: ${p => (p.collapsed ? '10px 9px' : '10px 27px')};
    display: flex;
    align-items: center;
    position: relative;

    span {
      ${p => p.collapsed && 'display: none'};
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      top: 50%;
      left: 69px;
      transform: translateY(-50%);
      color: #fff;
    }

    span,
    img {
      opacity: 0.5;
    }

    &:hover {
      background: #244d85
      ${p => p.collapsed && 'background: #244d85'};

      span,
      img {
        opacity: 1;
      }

      span {
        ${p =>
          p.collapsed &&
          css`
            display: flex;
            align-items: center;
            padding: 20px 7px;
            left: 50px;
            height: 100%;
            background: #244d85;
            white-space: nowrap;
            z-index: 9;
            width: 200px;
          `}
      }
    }

    &.active {
      border-right: 6px solid #00bfff;
      background: linear-gradient(
        to right,
        rgba(23, 118, 177, 1) 0%,
        rgba(23, 118, 177, 0) 100%
      );

      span,
      img {
        opacity: 1;
      }

      span {
        font-weight: 900;
      }
    }
  }
`

const Nav = ({ collapsed }) => {
  return (
    <Col collapsed={collapsed}>
      <NavLink to='/' exact>
        <img src={dashboardIcon} alt='dashboard-icon' />
        <span>Dashboard</span>
      </NavLink>
      <NavLink to='/recommendations'>
        <img src={recommendationsIcon} alt='recommendations-icon' />
        <span>Recommendations</span>
      </NavLink>
      <NavLink to='/priority-board'>
        <img src={priorityBoardIcon} alt='priority-board-icon' />
        <span>Team Support Board</span>
      </NavLink>
      <NavLink to='/support-cases'>
        <img src={supportCasesIcon} alt='support-cases-icon' />
        <span>Support Cases</span>
      </NavLink>
      <NavLink to='/reports'>
        <img src={reportsIcon} alt='reports-icon' />
        <span>Reports</span>
      </NavLink>
      <NavLink to='/documents'>
        <img src={documentIcon} alt='documents-icon' />
        <span>Documents</span>
      </NavLink>
    </Col>
  )
}

export default Nav
