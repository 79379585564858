import { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect, useLocation } from "react-router-dom";
import { parseUrl } from "query-string";

import Trek10Logo from "./icons/Trek10Logo";
import useAuth from "../../hooks/useAuth";
import Button from "../Button/Button";
import { validate as validateEmail } from "isemail";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  background-color: #f5f5f5;
  padding: 20px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000;
`;

const Input = styled.input`
  border: ${(p) => (!p.isValid ? "1px solid red" : "1px solid #ccc")};
  outline-color: ${(p) => !p.isValid && "red"};
  padding: 8px 15px;
  width: 100%;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
`;

const Box = styled.div`
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  color: #4b5882;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.header`
  margin-top: 20px;
  margin-bottom: 44px;
  width: 75%;
`;

const Main = styled.main`
  margin-top: 44px;
  font-size: 12px;
  line-height: 18px;

  button {
    margin-left: auto;
  }
`;

const Heading = styled.h1`
  margin-right: auto;
  color: #363636;
  display: inline-block;
  padding-bottom: 20px;
  font-size: 42px;
  font-weight: 400;
  line-height: 0.8;
  position: relative;

  &::after {
    content: "";
    width: 90px;
    height: 3px;
    background-color: #4b5882;
    color: red;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(-100%);
  }
`;

const Logo = styled.figure`
  width: 100%;
`;

const initialState = {
  email: "",
  isValid: true,
  complete: false,
  loading: false,
  error: undefined,
};

const Welcome = () => {
  const location = useLocation();
  const { isAuthenticated, changePassword } = useAuth();
  const [state, setState] = useState(initialState);
  const [parsedEmail, setParsedEmail] = useState(null);

  const handleKeyCheck = (e) => {
    if (e.key === "Enter") handleSubmit();
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyCheck);
    return () => document.removeEventListener("keyup", handleKeyCheck);
    // eslint-disable-next-line
  }, [state.email]);

  useEffect(() => {
    const {
      query: { useremail },
    } = parseUrl(location.search?.replaceAll(/\+/g, "%2B"));
    setParsedEmail(useremail);
    // eslint-disable-next-line
  }, []);

  const checkIsValidEmail = (email) => checkEmail(email, parsedEmail);
  const handleChange = (e) =>
    setState((state) => ({ ...state, email: e.target.value, isValid: true }));
  const onPasswordError = (err) =>
    setState((state) => ({
      ...state,
      loading: false,
      error: err?.message,
      complete: !err,
    }));

  const handleSubmit = () => {
    if (!checkIsValidEmail(state.email))
      return setState((state) => ({ ...state, isValid: false }));

    setState((state) => ({ ...state, loading: true, isValid: true }));
    changePassword(
      { connection: "Harbor", email: state.email },
      onPasswordError
    );
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Box>
      <Header>
        <Logo>
          <Trek10Logo />
        </Logo>
      </Header>
      <Heading>Welcome to Trek10!</Heading>
      <Main>
        {!state.complete && (
          <p>
            Verify your email and generate a link to setup your password by
            entering your email address below: (Be sure to double check the
            email you enter matches the one we emailed you with, as opposed to
            an alias.). If you have any questions about this contact either your
            organization's Trek10 lead, or the Trek10 lead assigned to your
            organization.
          </p>
        )}
        {!state.complete && (
          <Wrapper>
            <Label>
              Email
              <Input
                onChange={handleChange}
                isValid={state.isValid}
                type="email"
                placeholder="Email"
              />
            </Label>
            <Button onClick={handleSubmit}>
              {state.loading ? "Verifying..." : "Verify"}
            </Button>
          </Wrapper>
        )}
        {(state.complete || state.error) && (
          <Wrapper>
            <p>
              If correct, an email has been sent to the account you just
              verified. Follow the link contained in that message to set your
              password.
            </p>
          </Wrapper>
        )}
      </Main>
    </Box>
  );
};

function checkEmail(email, parsedEmail) {
  return validateEmail(email) && email === parsedEmail;
}

export default Welcome;
