import styled from 'styled-components'

import Drawer from '../Drawer/Drawer'
import Button from '../Button/Button'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding: 50px;
  max-width: 544px;
  width: 100%;
  background: #fff;
  border-radius: 12px;
  border: 2px solid #e3e9ef;
`

const Title = styled.h1`
  color: #244d85;
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
`

const Text = styled.span`
  color: #4b5882;
  font-size: 14px;
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`

const StyledButton = styled(Button)`
  background-color: ${p => (p.light ? '#F1F4F7' : '#244D85')};
  color: ${p => (p.light ? '#244D85' : '#fff')};
  font-weight: bold;
  letter-spacing: 2px;
  padding: ${p => (p.light ? '10px 19px' : '10px 32px')};
  border-radius: 99px;

  ${p => p.center && 'align-self: center'};

  &:hover { color: #fff; } 
`

const ConfirmationModal = ({
  isOpen,
  toggle,
  title,
  neutralBtnText = "cancel",
  positiveBtnText = "save",
  message,
  handler,
  submitting
}) => {
  const handleSubmit = async () => {
    try {
      await handler();
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Drawer isOpen={isOpen}>
      <Box className="confirmation-modal">
        <Title>{title}</Title>
        <Text dangerouslySetInnerHTML={{__html: message }} />
        <ButtonRow>
          <StyledButton fontSize="10px" light onClick={toggle} className='confirmation-modal-close-btn'>
            {neutralBtnText}
          </StyledButton>
          <StyledButton
            fontSize="10px"
            className='confirmation-modal-save-btn'
            onClick={handleSubmit}
            isSubmitting={submitting}
          >
            {positiveBtnText}
          </StyledButton>
        </ButtonRow>
      </Box>
    </Drawer>
  )
}

export default ConfirmationModal
