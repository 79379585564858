import { useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import Editor from '../Editor/Editor'
import Button from '../Button/Button'
import updatePriority from '../../graphql/updatePriority'
import { convertDelta } from '../../utils'

const Col = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const Between = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 10px;
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000;
`

const ErrorMsg = styled.span`
  color: red;
  margin-bottom: 12px;
`

const initialState = {
  values: {
    comment: '',
    delta: {},
  },
  touched: {},
  errors: {},
}

const PriorityViewForm = ({ priorityId }) => {
  const [createComment] = useMutation(updatePriority)

  const editorRef = useRef()
  const [form, setForm] = useState(initialState)
  const [isSubmitting, setSubmitting] = useState(false)

  const validate = values => {
    const errors = {}

    if (!values.comment) errors.comment = 'Please fill comment field!'

    return errors
  }

  const onChange = (name, value, delta, editor) => {
    setForm(form => {
      const values = {
        ...form.values,
        delta,
        [name]: value,
      }

      const errors = validate(values)

      const newForm = {
        values,
        touched: {
          ...form.touched,
          [name]: true,
        },
        errors,
      }

      return newForm
    })

    editorRef.current = editor;
  }

  const resetForm = () => {
    setForm(initialState)
    editorRef.current.setContents([])
  }

  const onSubmit = async () => {
    try {
      const errors = validate(form.values)

      if (!!Object.keys(errors).length) {
        const touched = Object.keys(errors).reduce((obj, key) => {
          obj[key] = true
          return obj
        }, {})

        setForm(form => ({
          ...form,
          errors,
          touched,
        }))
        return
      }

      setSubmitting(true)

      const delta = convertDelta({ delta: form.values.delta, to: 'clickup' })
      const variables = {
        id: priorityId,
        issue: { comment: delta },
      }

      await createComment({
        variables,
        context: { version: 4 },
      })

      resetForm()
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  const commentError = form.touched.comment ? form.errors.comment : ''
  const disabled = isSubmitting || !!Object.keys(form.errors).length

  return (
    <Col>
      <Label>Comment</Label>
      <Editor onChange={(value, delta, editor) => onChange('comment', value, delta, editor)} />
      {commentError && <ErrorMsg>{commentError}</ErrorMsg>}
      <Between>
        <span></span> {/*To push button to the right*/}
        <Button disabled={disabled} isSubmitting={isSubmitting} onClick={onSubmit}>
          Add Comment
        </Button>
      </Between>
    </Col>
  )
}

export default PriorityViewForm
