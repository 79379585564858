import { Switch, Route, useRouteMatch } from 'react-router-dom'
import SupportCases from './SupportCases'
import NewSupportCase from '../../components/NewSupportCase/NewSupportCase'
import SupportCaseDetails from '../SupportCaseDetails/SupportCaseDetails'

const SupportCasesPage = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={match.path} exact>
        <SupportCases />
      </Route>
      <Route path={`${match.path}/new`}>
        <NewSupportCase />
      </Route>
      <Route path={`${match.path}/:id`}>
        <SupportCaseDetails />
      </Route>
    </Switch>
  )
}

export default SupportCasesPage
