import { Switch, Route, useRouteMatch } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import getUsers from '../../graphql/getUsers'
import { useQuery } from '@apollo/client'

import Users from './Users'
import NewUser from '../../components/UserForm/NewUser'
import EditUser from '../../components/UserForm/EditUser'

const UsersPage = () => {
  const match = useRouteMatch()
  const { selectedOrg } = useAuth()
  const { data, loading, error } = useQuery(getUsers, {
    variables: { id: selectedOrg.value },
  })

  return (
    <Switch>
      <Route path={match.path} exact>
        <Users
          data={data?.getOrganization.users.items}
          loading={loading}
          error={error}
        />
      </Route>
      <Route path={`${match.path}/new`}>
        <NewUser domainWhitelist={data?.getOrganization.domainWhitelist || []} />
      </Route>
      <Route path={`${match.path}/:id`}>
        <EditUser data={data?.getOrganization.users.items} loading={loading} />
      </Route>
    </Switch>
  )
}

export default UsersPage
