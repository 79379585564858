import { gql } from '@apollo/client'

const GET_RECOMMENDATIONS = gql`
  query Recommendations ($organizationId: String!) {
    listRecommendations(organizationId: $organizationId, filter: {status: {eq: OPEN}}){
      items {
        id
        title
        awsPillar
        status
        hoursEstimate
        createdDateTime
        shortDescription
        description
        highPriority
      }
      nextToken
    }
  }
`

export default GET_RECOMMENDATIONS
