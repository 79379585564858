import { gql } from '@apollo/client'

const updateTicketCC = gql`
  mutation updateZendeskTicket($id: Int!, $emailCCs: [String]!) {
    updateZendeskTicket(input: { id: $id, emailCCs: $emailCCs }) {
      id
      subject
      status
      priority
      assignee {
        ZDUserAttributes {
          id
          name
        }
      }
      submitter {
        ZDUserAttributes {
          id
          name
        }
      }
      comments {
        id
        author {
          ZDUserAttributes {
            id
            name
            photo {
              thumbnails {
                content_url
              }
            }
          }
        }
        html_body
        created
        public
        attachments {
          id
          content_url
          file_name
        }
      }
      organizationId
      emailCCIds
      followers {
        ZDUserAttributes {
          email
        }
      }
    }
  }
`

export default updateTicketCC
