import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faQuestion,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'

import OperationsIcon from '../RecommendationTabs/icons/OperationsIcon'
import PerformanceIcon from '../RecommendationTabs/icons/PerformanceIcon'
import CostIcon from '../RecommendationTabs/icons/CostIcon'
import ReliabilityIcon from '../RecommendationTabs/icons/ReliabilityIcon'
import SecurityIcon from '../RecommendationTabs/icons/SecurityIcon'
import TrainingIcon from '../RecommendationTabs/icons/TrainingIcon'
import Heading from '../Heading/Heading'
import Tooltip from '../Tooltip/Tooltip'

const Box = styled(Link)`
  border: 2px solid #F1F4F7;
  border-radius: 18px;
  background-color: #FFFFFF;
  padding: 28px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 152px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 28px;
  }

  @media only screen and (max-width: 370px) {
    padding: 20px 5px;
  }
`

const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Row = styled.div`
  display: flex;
  align-items: center;
`

const getColor = priority => {
  if (priority === 'high') return '#ff4140'
  if (priority === 'medium') return '#f7b500'
  if (priority === 'low') return '#00c866'
}

const IconBox = styled.div`
  color: ${p => getColor(p.priority)};
  border: 8px solid ${p => getColor(p.priority)};
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 30px;
    width: auto;
    fill: currentColor;
  }

  aside {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -6px;
    right: -6px;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: currentColor;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
      font-size: 12px;
    }
  }
`

const Title = styled.span`
  color: #4b5882;
  font-size: 16px;
  font-weight: 900;
  margin: 23px 0 9px;
`

const Desc = styled.span`
  color: #A5ACC2;
  font-size: 12px;
  letter-spacing: 0;
`

const Grid = styled.div`
  display: flex;
  margin-top: 35px;

  @media only screen and (max-width: 1400px) {
    display: grid;
    grid-gap: 28px;
    grid-template-columns: repeat(3, 152px);
  }

  @media only screen and (max-width: 920px) {
    grid-template-columns: repeat(auto-fit, 152px);
    justify-content: center;
  }

  @media only screen and (max-width: 555px) {
    grid-gap: 14px;
  }

  @media only screen and (max-width: 370px) {
    grid-gap: 5px;
    grid-template-columns: repeat(2, 138px);
  }
`

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #a5acc2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const summaryList = [
  {
    Icon: SecurityIcon,
    title: 'Security',
    url: '/recommendations/security',
  },
  {
    Icon: OperationsIcon,
    title: 'Operations',
    url: '/recommendations/operations',
  },
  {
    Icon: ReliabilityIcon,
    title: 'Reliability',
    url: '/recommendations/reliability',
  },
  {
    Icon: PerformanceIcon,
    title: 'Performance',
    url: '/recommendations/performance',
  },
  {
    Icon: CostIcon,
    title: 'Cost',
    url: '/recommendations/cost',
  },
  {
    Icon: TrainingIcon,
    title: 'Training',
    url: '/recommendations/training',
  },
]

const RecommendationSummary = ({ listRecommendations }) => {
  const summary = useMemo(() => {
    return listRecommendations.items.reduce(
      (res, obj) => {
        const key = obj.awsPillar?.toLowerCase()

        res[key].count = res[key].count + 1

        if (obj.highPriority) {
          res[key].high = true
        }

        return res
      },
      {
        security: {
          count: 0,
          high: false,
        },
        cost: {
          count: 0,
          high: false,
        },
        reliability: {
          count: 0,
          high: false,
        },
        operations: {
          count: 0,
          high: false,
        },
        performance: {
          count: 0,
          high: false,
        },
        training: {
          count: 0,
          high: false,
        },
      },
    )
  }, [listRecommendations])

  return (
    <Col>
      <Row>
        <Heading style={{ marginRight: '16px' }}>
          Recommendations summary
        </Heading>
        <Tooltip
          className='summary-tooltip'
          html={true}
          content="The pillars are derived from AWS's 
                   Well Architected Framework and colored 
                   based on Trek10's expert guidance on the 
                   criticality of recommendations in that area.
                   <br />
                   <br />
                   <a class='tooltip-link' href='#'>Learn More</a>"
        >
          <Info>
            <FontAwesomeIcon icon={faQuestion} color='#e3e9ef' size='sm' />
          </Info>
        </Tooltip>
      </Row>
      <Grid>
        {summaryList.map(({ Icon, title, url }) => {
          let priority
          let asideIcon

          if (summary[title.toLowerCase()].high) {
            priority = 'high'
            asideIcon = faExclamationTriangle
          } else if (summary[title.toLowerCase()].count > 3) {
            priority = 'medium'
            asideIcon = faQuestion
          } else {
            priority = 'low'
            asideIcon = faCheck
          }

          return (
            <Box to={url} key={url} className='rec-summary-box'>
              <IconBox priority={priority}>
                <Icon />
                <aside>
                  <FontAwesomeIcon icon={asideIcon} color='#fff' size='xs' />
                </aside>
              </IconBox>
              <Title>{title}</Title>
              <Desc>{`${summary[title.toLowerCase()].count
                } Recommendations`}</Desc>
            </Box>
          )
        })}
      </Grid>
    </Col>
  )
}

export default RecommendationSummary
