import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faSlackHash } from '@fortawesome/free-brands-svg-icons'
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader'
import { useQuery } from '@apollo/client'
import getTam from '../../graphql/getTam'
import useAuth from '../../hooks/useAuth'
import Gravatar from 'react-gravatar'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 32px 0px 27px;
  padding: 68px 26px 29px 20px;
  background: #fff;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 23px 34px 0 rgba(0, 0, 0, 0.09);
  max-width: 234px;
`

const StyledGravatar = styled(Gravatar)`
  border-radius: 50%;
  border: 6px solid #ffffff;
  height: 71px;
  width: 71px;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
`

const Name = styled.span`
  color: #4b5882;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 6px;
  text-align: center;
`

const Title = styled.span`
  color: #a5acc2;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 0 15px;
`

const InfoRow = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  svg {
    fill: #a5acc2;
    width: 15px;
    height: auto;
  }

  span {
    margin-left: 14px;
    color: #4b5882;
    font-size: 12px;
    font-weight: 500;
  }
`

const Desc = styled.span`
  color: #4b5882;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
`
const ErrorMsg = styled.p`
  color: red;
`

const UserProfile = () => {
  const { selectedOrg } = useAuth()
  const { data, loading, error } = useQuery(getTam, {
    variables: {
      id: selectedOrg.value,
    },
  })

  if (loading) return <ScreenLoader />
  if (error) return <ErrorMsg>{error.message}</ErrorMsg>

  const tam = data?.getOrganization?.tam ?? {
    firstName: 'Brandy',
    lastName: 'Coomer',
    email: 'bcoomer@trek10.com',
    slack: 'bcoomer',
    phone: '(888) 736-2446 x720',
  }

  return (
    <Box>
      <StyledGravatar email={tam.email} default='mp' />
      <Name>
        {tam.firstName} {tam.lastName}
      </Name>
      <Title>Client Success Manager</Title>
      <InfoCol>
        <InfoRow>
          <FontAwesomeIcon icon={faEnvelope} color='#a5acc2' size='sm' />
          <span>{tam.email}</span>
        </InfoRow>
        {tam.phone && (
          <InfoRow>
            <FontAwesomeIcon icon={faPhoneAlt} color='#a5acc2' size='sm' />
            <span>{tam.phone}</span>
          </InfoRow>
        )}
        {tam.slack && (
          <InfoRow>
            <FontAwesomeIcon icon={faSlackHash} color='#a5acc2' size='sm' />
            <span>@{tam.slack}</span>
          </InfoRow>
        )}
      </InfoCol>
      <Desc>
        As your dedicated CSM, I look forward to making sure your projects and
        experience with Trek10 are as smooth and enjoyable as possible. Please
        do not hesitate to reach out via Email, Slack or Phone with any
        questions, thoughts or comments.
      </Desc>
    </Box>
  )
}

export default UserProfile
