const TrainingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 290 388.596"
    >
      <path
        d="M267.417 0h-33.455v113.224c0 7.956-8.042 11.889-14.273 1.901L197.592 79.77l-22.097 35.355c-6.275 9.943-14.275 6.055-14.275-1.901V0H49.055C21.941.073 0 22.074 0 49.188v290.353c.073 27.062 21.993 48.983 49.055 49.055h218.406a22.487 22.487 0 0015.91-6.629 22.493 22.493 0 006.629-15.91V22.584C289.976 10.121 279.879.024 267.417 0m4.199 330.261l-213.103.265a9.324 9.324 0 00-8.66 8.661c-.356 5.138 3.522 9.59 8.66 9.945h213.103l-.044 17.148a3.927 3.927 0 01-1.105 2.74 3.94 3.94 0 01-2.739 1.105H49.055c-16.78 0-30.383-13.604-30.383-30.385s13.604-30.383 30.383-30.383h218.672a3.934 3.934 0 013.888 3.89v17.014z"
      />
    </svg>
  )
}

export default TrainingIcon
