import { useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import GeneralCard from './GeneralCard/GeneralCard'
import { quillIndent } from '../../utils'

import SecurityIcon from '../RecommendationTabs/icons/SecurityIcon'
import ReliabilityIcon from '../RecommendationTabs/icons/ReliabilityIcon'
import PerformanceIcon from '../RecommendationTabs/icons/PerformanceIcon'
import TrainingIcon from '../RecommendationTabs/icons/TrainingIcon'
import OperationsIcon from '../RecommendationTabs/icons/OperationsIcon'
import CostIcon from '../RecommendationTabs/icons/CostIcon'

const StyledCards = styled.div`
  margin-top: 35px;

  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-gap: 16px;

  @media only screen and (max-width: 920px) {
    justify-content: center;
  }
`

const Fallback = styled.p`
  color: #a5acc2;
  text-align: center;
`

const RecommendationCards = ({ data }) => {
  const [activeCard, setActiveCard] = useState(null)

  const cards = data.map(rec => ({
      id: rec.id,
      title: rec.title,
      description: quillIndent(rec.description),
      shortDescription: quillIndent(rec.shortDescription),
      highPriority: rec.highPriority,
      Icon: getIcon(rec.awsPillar?.toLowerCase()),
      status: dayjs().diff(dayjs(rec.createdDateTime), 'day') < 11 ? 'new' : null,
      recStatus: rec.status,
      estimation: rec.hoursEstimate || null,
      awsPillar: rec.awsPillar
    }))

  return (
    <>
      <StyledCards>
        {cards.map(
          ({
            id,
            title,
            description,
            Icon,
            status,
            estimation,
            shortDescription,
            highPriority,
            recStatus,
            awsPillar
          }) => (
            <GeneralCard
              key={id}
              id={id}
              title={title}
              description={description}
              shortDescription={shortDescription}
              highPriority={highPriority}
              Icon={Icon}
              status={status}
              recStatus={recStatus}
              awsPillar={awsPillar}
              estimation={estimation}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
            />
          ),
        )}
      </StyledCards>
      {cards.length < 1 && <Fallback>No recommendations found!</Fallback>}
    </>
  )
}

function getIcon(pillar) {
  if (!pillar) return

  switch (pillar) {
    case 'cost':
      return CostIcon
    case 'reliability':
      return ReliabilityIcon
    case 'performance':
      return PerformanceIcon
    case 'training':
      return TrainingIcon
    case 'operations':
      return OperationsIcon
    case 'security':
      return SecurityIcon
    default:
      return SecurityIcon
  }
}

export default RecommendationCards
