import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import useAuth from "../../hooks/useAuth";
import PriorityInfo from "../../components/PriorityInfo/PriorityInfo";
import PriorityViewForm from "../../components/PriorityViewForm/PriorityViewForm";
import getPriority from "../../graphql/getPriority";
import getOrgFeatures from "../../graphql/getOrgFeatures";
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader";
import Comment from "../../components/Comment/Comment";
import Heading from "../../components/Heading/Heading";

const StyledHeading = styled(Heading)`
  margin-bottom: 30px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 45px;
  margin-bottom: 44px;
  color: #4b5882;
  font-size: 14px;

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 30px;
  margin-bottom: 50px;

  *:nth-child(2) {
    grid-column: 1 / 2;
  }

  & > *:last-child {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;

    & > *:last-child {
      grid-row: revert;
      grid-column: revert;
      max-width: revert;
    }
  }
`;

const DescriptionWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #a5acc2;
  padding: 10px;
  min-height: 100px;
  hyphens: auto;
  line-height: 1.4;
`;

const ErrorMsg = styled.p`
  color: red;
`;

const PriorityDetails = () => {
  const params = useParams();
  const { selectedOrg } = useAuth();
  const orgFeatures = useQuery(getOrgFeatures, {
    variables: { id: selectedOrg.value },
  });
  const priority = useQuery(getPriority, {
    variables: { id: params.id },
    context: { version: 4 },
  });
  const loading = priority.loading || orgFeatures.loading;
  const errors = priority.error || orgFeatures.error;
  if (loading) {
    return <ScreenLoader />;
  }
  if (errors) {
    return <ErrorMsg>{errors}</ErrorMsg>;
  }
  return (
    <Box>
      <StyledHeading>{priority.data.getIssue.title}</StyledHeading>
      <Grid>
        {priority.data.getIssue.description && (
          <DescriptionWrapper>
            <p style={{ whiteSpace: "pre-line" }}>
              {priority.data.getIssue.description}
            </p>
          </DescriptionWrapper>
        )}
        <PriorityViewForm priorityId={params.id} />
        <PriorityInfo
          taskId={priority.data.getIssue.id}
          status={priority.data.getIssue.status}
          orgFeatures={orgFeatures.data?.getOrganization?.harborFeatures || []}
          organization={selectedOrg.label}
          hoursWorked={priority.data.getIssue.hoursWorked}
          hoursEstimate={priority.data.getIssue.hoursEstimate}
        />
      </Grid>
      <Heading>Comments {priority.data.getIssue.comments.length}</Heading>
      {priority.data.getIssue.comments.length > 0 &&
        [...priority.data.getIssue.comments].map((comment) => (
          <Comment
            key={comment.id}
            identifier={comment.email}
            text={comment.body}
            created={comment.created}
          />
        ))}
    </Box>
  );
};

export default PriorityDetails;
