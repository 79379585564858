import { gql } from '@apollo/client'

const createTicketComment = gql`
  mutation updateZendeskTicket(
    $attachments: [String]
    $id: Int!
    $status: String
    $comment: String
    $public: Boolean
  ) {
    updateZendeskTicket(
      input: {
        attachments: $attachments
        id: $id
        status: $status
        comment: $comment
        public: $public
      }
    ) {
      id
      subject
      status
      priority
      assignee {
        ZDUserAttributes {
          id
          name
        }
      }
      submitter {
        ZDUserAttributes {
          id
          name
        }
      }
      comments {
        id
        author {
          ZDUserAttributes {
            id
            name
            photo {
              thumbnails {
                content_url
              }
            }
          }
        }
        html_body
        created
        public
        attachments {
          id
          url
          file_name
        }
      }
    }
  }
`

export default createTicketComment
