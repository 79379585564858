import styled from 'styled-components'
import dayjs from 'dayjs'
import QuillDisplay from '../QuillDisplay/QuillDisplay'
import Gravatar from 'react-gravatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { DATE_FORMAT, checkIsDelta, removeHiddenMessage } from '../../utils'

const Box = styled.div`
  display: flex;
  max-width: 800px;
  color: #4b5882;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  margin-top: 15px;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;

    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`

const Col = styled.div`
  width: 85%;
  display: flex;
  flex: 1;
  flex-direction: column;
`
const StyledGravatar = styled(Gravatar)`
  width: 38px;
  height: 38px;
  background-size: cover;
  border-radius: 50%;
  border: 2px solid #f1f4f7;
  margin-right: 8px;
`
const StyleUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Identifier = styled.h1`
  line-height: 1;
  font-size: 14px;

`

const Date = styled.p`
  line-height: 1;
`

const Text = styled.p`
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  margin-top: 8px;
  hyphens: auto;
  line-height: 1.4;

  img {
    height: auto !important;
    width: auto !important;
    max-width: 100% !important;
  }
`

const AttachmentList = styled.ul`
  margin-top: 10px;
  padding: unset;
`

const Attachment = styled.li`
  display: flex;

  svg {
    margin-right: 4px;
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`

const Comment = ({ identifier, text, attachments = [], created }) => {
  const isDelta = checkIsDelta(text)

  return (
    <Box>
      <Col>
        <Row>
          <StyleUser>
            <StyledGravatar email={identifier} default='mp' />
            <Identifier>{identifier}</Identifier>
          </StyleUser>
          <Date>{dayjs(created).format(DATE_FORMAT)}</Date>
        </Row>
        {isDelta ? (
          <QuillDisplay delta={text} />
        ) : (
          <>
          <Text dangerouslySetInnerHTML={{ __html: removeHiddenMessage(text) }} />
          <AttachmentList>
            {attachments.map(attachment => (
              <Attachment key={attachment.id}>
                <FontAwesomeIcon icon={faFile} color='grey' size='lg' />
                <a href={attachment.content_url}>{attachment.file_name}</a>
              </Attachment>
            ))}
          </AttachmentList>
          </>
        )}
      </Col>
    </Box>
  )
}

export default Comment
