import { gql } from '@apollo/client'

const GET_DASHBOARD_URL = gql`
  query getDashboardUrl ($organizationId: String!)  {
    getDashboardUrl(organizationId: $organizationId) {
      url
    }
  }
`

export default GET_DASHBOARD_URL
