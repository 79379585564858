import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../../Button/Button";
import Radio from "../../../Radio/Radio";
import UPDATE_RECOMMENDATION from "../../../../graphql/updateRecommendation";
import createPriority from "../../../../graphql/createPriority";
import useAuth from "../../../../hooks/useAuth";
import useListStatuses from "../../../../hooks/useListStatuses";
import {
  valueToStatus,
  valueToSwimlane,
  transformEstimate,
  formatDescription,
  htmlToText,
} from "../../../../utils";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 51px 0 rgba(0, 0, 0, 0.2);
  width: 250px;
  text-align: left;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 1;

  &::before {
    content: "";
    display: block;
    background-color: #00bfff;
    overflow: hidden;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 4px;
    height: 45px;
    position: absolute;
    left: 0;
  }
`;

const Status = styled.p`
  font-size: 12px;
  font-weight: 900;
  background-color: #f3b349;
  padding: 4px 15px;
  border-radius: 15px;
  color: #fff;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: 0;
`;

const CardForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  margin-bottom: 25px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2f5993;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  svg {
    height: 20px;
    fill: white;
  }
`;

const Title = styled.h1`
  margin-left: 16px;
  font-size: 12px;
  font-weight: 900;
  flex: 1;
  line-height: 1;
  text-transform: uppercase;
  color: #244d85;
`;

const Description = styled.div`
  line-height: 21px;
  max-width: 350px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #4b5882;
  font-size: 14px;
  font-weight: 500;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const radioOptions = [
  {
    value: "doit",
    label: "Trek10 - Do It",
    className: "rec-plan-radio-do-it",
  },
  {
    value: "workwithtrek",
    label: "Trek10 - Estimate It",
    className: "rec-plan-radio-work-with-trek",
  },
  {
    value: "discuss",
    label: "Discuss",
    className: "rec-plan-radio-discuss",
  },
  {
    value: "gettraining",
    label: "Train Us",
    className: "rec-plan-radio-train-us",
  },
  {
    value: "fixit",
    label: "My Team Will Do It",
    className: "rec-plan-radio-my-team-will-do-it",
  },
  {
    value: "archive",
    label: "Archive It",
    className: "rec-plan-radio-archive-it",
  },
];

const PlanningCard = ({
  id,
  title,
  description,
  shortDescription,
  status,
  Icon,
  estimation,
  awsPillar,
  setActiveCard,
}) => {
  const history = useHistory();
  const { user, selectedOrg } = useAuth();
  const [selected, setSelected] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [updateRec, { loading }] = useMutation(UPDATE_RECOMMENDATION);
  const [createIssue, { loading: issueLoading }] = useMutation(createPriority);
  const listStatuses = useListStatuses();

  const notifyErrorMsg = (message) => toast.error(message);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!selected) return;
      setSubmitting(true);

      if (!["ARCHIVED", "CLIENT_HANDLED"].includes(valueToStatus(selected))) {
        const priority = await createIssue({
          variables: {
            organizationId: selectedOrg.value,
            issue: {
              active: true,
              title,
              description: formatDescription(
                `Created from the Trek10 recommendation ${title}.
                ${description ? htmlToText(description) : ""}
                Original Estimate ${transformEstimate(estimation)} hours
                Created by: ${user["email"]}`
              ),
              status: valueToSwimlane(selected, listStatuses),
              hoursEstimate: transformEstimate(estimation),
              awsPillar: awsPillar?.toLowerCase(),
              type: "recommendations",
              originalLink: "rec:" + id,
            },
          },
          context: { version: 4 },
        });

        if (priority?.errors) {
          setSubmitting(false);
          throw new Error("Failed to create priority");
        }
      }

      const rec = await updateRec({
        variables: {
          id,
          recommendation: {
            active: false,
            status: valueToStatus(selected),
          },
        },
        context: {
          version: 1,
        },
      });

      if (rec?.errors) {
        setSubmitting(false);
        throw new Error("Failed to plan recommendation");
      }

      setSubmitting(false);
      setActiveCard(null);

      if (!["ARCHIVED", "CLIENT_HANDLED"].includes(valueToStatus(selected)))
        return history.push("/priority-board");
    } catch (err) {
      notifyErrorMsg(err.message);
    }
  };

  return (
    <Card className="rec-plan-card">
      {status && <Status>{status}</Status>}
      <Row>
        <IconContainer>
          <Icon />
        </IconContainer>
        <Title>{title}</Title>
      </Row>
      <Description>
        <p dangerouslySetInnerHTML={{ __html: shortDescription }}></p>
      </Description>
      <CardForm id="radio-form">
        {radioOptions.map((item, idx) => (
          <Radio
            className={item.className}
            key={idx}
            value={item.value}
            active={selected === item.value}
            label={item.label}
            onChange={(value) => setSelected(value)}
          />
        ))}
      </CardForm>
      <ButtonContainer>
        <Button
          className="rec-plan-cancel"
          fontSize="10px"
          letterSpacing="2px"
          onClick={() => setActiveCard(null)}
          isSecondary
        >
          Cancel
        </Button>
        <Button
          className="rec-plan-save"
          fontSize="10px"
          letterSpacing="2px"
          form="radio-form"
          onClick={handleSubmit}
          disabled={loading || issueLoading || !selected}
          isSubmitting={isSubmitting}
        >
          Save
        </Button>
      </ButtonContainer>
    </Card>
  );
};

export default PlanningCard;
