import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import Report from './Report'
import GET_DASHBOARD_URL from '../../graphql/getDashboardUrl'
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader'
import useAuth from '../../hooks/useAuth'

const ErrorMsg = styled.p`
  color: red;
`

const Reports = () => {
  const { selectedOrg } = useAuth()
  const { data, loading, error } = useQuery(GET_DASHBOARD_URL, {
    variables: {
      organizationId: selectedOrg.value,
    },
    context: {
      version: 3,
    },
  })

  if (loading) return <ScreenLoader />
  if (error)
    return (
      <ErrorMsg>
        {error.message.toLowerCase().includes('parameter validation failed')
          ? 'Sorry, this account is not configured for reports yet.'
          : error.message}
      </ErrorMsg>
    )

  return <Report url={data?.getDashboardUrl.url} />
}

export default Reports
