import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Quill from 'quill'
import { convertDelta } from '../../utils'

const DisplayWrapper = styled.div`
  color: #000;

  .ql-editor,
  .ql-container {
    border: none;
  }

  .ql-toolbar {
    display: none;
  }
`

const QuillDisplay = ({ delta }) => {
  const quillRef = useRef()
  const editor = useRef()

  const quillDelta = JSON.parse(convertDelta({
      delta: { comment: JSON.parse(delta).filter(op => op.type !== 'group_tag' && op.text !== "") },
      to: 'quill'
    }))

  useEffect(() => {
    editor.current = new Quill(quillRef.current, {
      theme: 'snow',
      readOnly: true,
      bounds: '.app',
    })

    editor.current.setContents(quillDelta)
    // eslint-disable-next-line
  }, [])

  return (
    <DisplayWrapper>
      <div ref={quillRef} />
    </DisplayWrapper>
  )
}

export default QuillDisplay
