import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import styled from 'styled-components'
import dayjs from 'dayjs'

import useAuth from '../../hooks/useAuth'
import TicketInfo from '../../components/TicketInfo/TicketInfo'
import Comment from '../../components/Comment/Comment'
import Button from '../../components/Button/Button'
import Heading from '../../components/Heading/Heading'
import SupportCaseViewForm from '../../components/SupportCaseViewForm/SupportCaseViewForm'
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import getTicketDetails from '../../graphql/getTicketDetails'
import createTicketComment from '../../graphql/createTicketComment'
import { transformTicket } from '../../utils'

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`

const ID = styled.p`
  margin-bottom: 22px;
  font-weight: 700;
  color: #4b5882;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;

  padding-right: 45px;
  margin-bottom: 44px;

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 30px;
  margin-bottom: 50px;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;

  & > *:first-child {
    padding-top: 0px;
    align-self: flex-start;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;

  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
`

const ErrorMsg = styled.p`
  color: red;
`

const SupportCaseDetails = () => {
  const params = useParams()
  const { user } = useAuth()

  const [isReplyOpen, setReplyOpen] = useState(false)
  const toggleReply = () => setReplyOpen(open => !open)

  const [isConfirmationOpen, setConfirmationOpen] = useState(false)
  const toggleConfirmation = () => setConfirmationOpen(open => !open)

  const [createComment, { loading: submitting }] = useMutation(createTicketComment)
  const { loading, error, data } = useQuery(getTicketDetails, {
    variables: {
      ticketId: +params.id,
    },
  })

  if (loading) return <ScreenLoader />
  if (error) return <ErrorMsg>{error.message}</ErrorMsg>

  const ticket = transformTicket(data.getZendeskTicket)

  const handleMarkAsSolved = async () => {
    try {
      const author = {
        firstName: user['https://harbortrek10/users'].firstName,
        lastName: user['https://harbortrek10/users'].lastName,
      }

      const currentDate = dayjs().format('MMMM D, YYYY')
      const currentTime = dayjs().format('h:mmA Z')

      const variables = {
        id: +params.id,
        comment: `<p>Case Marked as Solved by ${author.firstName} ${author.lastName} on ${currentDate} at ${currentTime}</p>`,
        status: 'solved'
      }

      await createComment({ variables })
      toggleConfirmation()
    } catch (err) {
      console.log(err)
      toggleConfirmation()
    }
  }

  return (
    <Box>
      <StyledHeading>{ticket.subject}</StyledHeading>
      <ID>Request #{params.id}</ID>
      <Grid>
        <Col>
          <Row>
            <Heading>Comments {ticket.comments.length}</Heading>
            <ButtonRow>
              {ticket.status.toLowerCase() !== 'solved' && (
                <Button
                  className="support-cases-mark-as-solved-button"
                  style={{ height: 38, width: 198 }}
                  isSecondary
                  onClick={toggleConfirmation}
                >
                  Mark as Solved
                </Button>
              )}
              <Button className='support-cases-reply-button' isLarge onClick={toggleReply}>Reply</Button>
            </ButtonRow>
          </Row>
          {ticket.comments.length > 0 &&
            [...ticket.comments].reverse().map(comment => (
              <Comment
                key={comment.id}
                identifier={comment.author.ZDUserAttributes.name}
                photo={comment.author.ZDUserAttributes.photo?.thumbnails[0]?.content_url}
                text={comment.html_body}
                attachments={comment.attachments}
                created={comment.created}
              />
            ))}
        </Col>
        <TicketInfo
          status={ticket.status}
          priority={ticket.priority}
          submittedBy={ticket.submitter.ZDUserAttributes.name}
          assignedTo={ticket.assignee?.ZDUserAttributes.name}
          followers={ticket.followers}
          linkedTo={ticket.linkedTo}
          ticketId={+params.id}
        />
      </Grid>
      {isReplyOpen && <SupportCaseViewForm ticketId={+params.id} isOpen={isReplyOpen} toggle={toggleReply} />}
      {isConfirmationOpen && (
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          toggle={toggleConfirmation}
          title='Mark as Solved'
          positiveBtnText='Yes'
          message='<p>Are you sure you want to mark this case as Solved?</p>'
          handler={handleMarkAsSolved}
          submitting={submitting}
        />
      )}
    </Box>
  )
}

export default SupportCaseDetails
