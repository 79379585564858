import styled from 'styled-components'
import useGlobalSearch from '../../hooks/useGlobalSearch'
import User from '../User/User'
import Organization from '../Organization/Organization'
import searchIcon from '../../assets/images/search-icon.png'

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: -7px 0 64px -35px;
  padding-right: 45px;

  & > * {
    margin: 35px 0 0 35px;
  }
`

const Left = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 35px;
  }
`

const Input = styled.input`
  min-width: 400px;
  background-color: #f1f4f7;
  padding: 16px 20px 16px 68px;
  color: #a5acc2;
  font-size: 16px;
  font-weight: bold;
  background-image: url(${searchIcon});
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 30px center;
  border: 0;
  outline: none;
  border-radius: 90px;

  &::placeholder {
    color: #a5acc2;
  }
`

const Header = () => {
  const { globalQuery, setGlobalQuery } = useGlobalSearch()

  return (
    <Box>
      <Left>
        <Input
          type='text'
          placeholder='Search for support cases'
          onChange={e => setGlobalQuery(e.target.value)}
          value={globalQuery}
          className='global-search'
        />
        <Organization />
      </Left>
      <User />
    </Box>
  )
}

export default Header
