import React from 'react'
import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const ButtonPrimary = css`
  display: block;
  border-radius: 20px;
  background-color: #244d85;
  padding: 12px 18px;
  color: #fff;

  &:hover {
    background-color: #00bfff;
  }
`

const ButtonSecondary = css`
  display: block;
  color: #244d85;
  border-radius: 20px;
  padding: 12px 18px;
  background-color: #f2f5f8;

  &:hover {
    filter: brightness(95%);
    box-shadow: inset 0 0 1px #244d85;
  }
`

const ButtonTertiary = css`
  display: inline-block;
  background-color: transparent;
  color: #00bfff;

  &:hover {
    color: #244d85;
  }

  @media only screen and (max-width: 920px) {
    margin-bottom: 20px;
  }
`

const ButtonLarge = css`
  display: block;
  height: 38px;
  width: 198px;
  background-color: #244d85;
  color: #fff;
  border-radius: 20px;
  font-weight: 900;

  &:hover {
    background-color: #00bfff;
  }

  @media only screen and (max-width: 920px) {
    background-color: #00bfff;
  }
`

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${p => p.fontSize || '12px'};
  font-family: 'Aeonik', sans-serif;
  font-weight: bold;
  text-transform: ${p =>
    p.capitalize ? 'capitalize' : p.lowercase ? 'lowercase' : 'uppercase'};
  line-height: 1;
  cursor: pointer;
  outline: none;
  border: none;
  letter-spacing: ${p => p.letterSpacing || ''};
  transition: 0.2s;
  ${p => p.disabled && 'opacity: .65'};
  ${props =>
    props.isSecondary
      ? { ButtonSecondary }
      : props.isTertiary
      ? { ButtonTertiary }
      : props.isLarge
      ? { ButtonLarge }
      : { ButtonPrimary }}
  ${p => p.isSubmitting && css`
    &::before {
      content: '';
      display: inline-block;
      margin-right: 5px;
      border: 2px solid #fff;
      border-radius: 50%;
      border-top: 2px solid #00bfff;
      width: ${p => p.fontSize || '12px'};
      height: ${p => p.fontSize || '12px'};
      animation: ${spin} 2s linear infinite;
    }
  `}
`

const Button = ({ children, ...rest }) => (
  <StyledButton {...rest}>{children}</StyledButton>
)

export default Button
