import { useEffect } from 'react'
import styled from 'styled-components'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'

const Div = styled.div`
width: calc(100% - 50px);

.grid-layout-block-selected {
  width: auto;
}
`

const Report = ({ url }) => {

  useEffect(() => {
    embedDashboard({
      url,
      container: document.getElementById("aws-reports-dashboard"),
      scrolling: "no",
      height: "AutoFit",
      width: "100%",
      locale: "en-US",
      sheetTabsDisabled: false,
      printEnabled: false,
      defaultEmbeddingVisualType: "AUTO_GRAPH",
    })
  }, [url])

  return (
    <Div id="aws-reports-dashboard" />
  )
}

export default Report
