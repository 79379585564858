import { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Gravatar from 'react-gravatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import useAuth from '../../hooks/useAuth'

const Box = styled.div`
  min-width: 200px;
  padding: 6px 0 6px 6px;
  text-transform: capitalize;
  color: #a5acc2;
  border: 1px solid #a5acc2;
  border-radius: 10px;

  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  align-items: center;
`

const StyledGravatar = styled(Gravatar)`
  grid-row: 1 / -1;

  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #f1f4f7;
`

const Label = styled.h2`
  font-size: 16px;
  color: #4b5882;
  font-weight: 900;
`

const Heading = styled.h1`
  grid-row: 2 / -1;

  line-height: 1;
  font-size: 14px;
  font-weight: 400;
`
const Nav = styled.nav`
  margin-right: -1px;
  width: 48px;
  height: 100%;
  grid-row: 1 / -1;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  margin-left: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dropdown = styled.ul`
  position: absolute;
  top: 60px;
  right: 0;
  padding: 0;
  list-style: none;
  color: #a5acc2;
  font-size: 14px;
  font-weight: bold;
  min-width: 200px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(36, 77, 133, 0.13),
    0 -2px 8px 0 rgba(36, 77, 133, 0.13);
  z-index: 1;
`

const Item = styled.li`
  white-space: nowrap;
  padding: 12px;
  text-transform: capitalize;
  cursor: pointer;

  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:first-of-type::after {
    content: '';
    width: 20px;
    height: 20px;
    clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 30px;
    transform: translateY(-100%);
    z-index: 1;
  }

  &:first-of-type:hover::after,
  &:hover {
    background-color: #f1f4f7;
  }
`

const User = () => {
  const ref = useRef()
  const [showDropdown, setShowDropdown] = useState(false)
  const toggleDropdown = () => setShowDropdown(toggle => !toggle)

  const { user, isSuperAdmin, isOrgAdmin, logOut } = useAuth()
  const history = useHistory()

  const identity = {
    first: user['https://harbortrek10/users'].firstName,
    last: user['https://harbortrek10/users'].lastName,
    email: user.email,
  }
  const handleOutsideClick = e => {
    if (ref.current.contains(e.target)) return
    setShowDropdown(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      <StyledGravatar email={identity.email} default='mp' />
      <Label>
        {identity.first} {identity.last}
      </Label>
      <Heading>{isSuperAdmin || isOrgAdmin ? 'Admin' : 'User'}</Heading>
      <Nav ref={ref} onClick={toggleDropdown}>
        <FontAwesomeIcon icon={faAngleDown} size='lg' />
        {showDropdown && (
          <Dropdown>
            <Item onClick={() => history.push(`/profile`)}>Edit Profile</Item>
            <Item onClick={() => history.push('/users')}>Users</Item>
            <Item onClick={logOut}>Log out</Item>
          </Dropdown>
        )}
      </Nav>
    </Box>
  )
}

export default User
