import { gql } from "@apollo/client";

const getSuperAdminOrgs = gql`
  query superAdminOrgs(
    $limit: Int = 1000
    $filter: ModelOrganizationFilterInput
    $nextToken: String
  ) {
    listOrganizations(limit: $limit, filter: $filter, nextToken: $nextToken) {
      items {
        id
        name
        isActive
        tam {
          id
          firstName
          lastName
          email
          phone
          slack
        }
      }
    }
  }
`;
export default getSuperAdminOrgs;
