import { useState } from 'react'

const usePagination = (data = [], perPage, radius = 1) => {
  const [currentPage, setCurrentPage] = useState(1)

  // If there's no data, maxPage should be at least 1
  const maxPage = Math.max(Math.ceil(data.length / perPage), 1)
  const begin = (currentPage - 1) * perPage
  const end = begin + perPage
  const currentData = data.slice(begin, end)

  let pages = []

  if (maxPage >= 10) {
    let start = currentPage - radius
    let tail = currentPage + radius

    if (start < 1) {
      start = 1
      tail = start + radius * 2
    }

    if (tail > maxPage) {
      start = maxPage - radius * 2
      tail = maxPage
    }

    if (radius * 2 + 1 > maxPage) {
      start = 1
      tail = maxPage
    }

    for (let page = start; page <= tail; page++) {
      if (page === 1 || page === maxPage) continue
      pages.push(page)
    }

    pages.unshift(1)
    pages.push(maxPage)

    const ellipsis = '...'
    if (currentPage > radius * 2) pages.splice(1, 0, ellipsis)
    if (currentPage <= maxPage - radius * 2)
      pages.splice(pages.length - 1, 0, ellipsis)
  } else {
    for (let page = 1; page <= maxPage; page++) {
      pages.push(page)
    }
  }

  if (currentPage > maxPage) {
    setCurrentPage(1)
  }

  const next = () => setCurrentPage(Math.min(currentPage + 1, maxPage))
  const prev = () => setCurrentPage(Math.max(currentPage - 1, 1))
  const jump = page => setCurrentPage(Math.min(Math.max(1, page), maxPage))

  return { currentPage, currentData, maxPage, pages, next, prev, jump }
}

export default usePagination
