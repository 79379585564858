import { gql } from "@apollo/client";

const getOrgFeatures = gql`
  query getOrgFeatures($id: ID!) {
    getOrganization(id: $id) {
      id
      harborFeatures
    }
  }
`;

export default getOrgFeatures;
