import { gql } from '@apollo/client'

const createNewUser = gql`
  mutation createNewHarborUser(
    $organizationId: String!
    $firstName: String!
    $lastName: String!
    $email: AWSEmail!
    $roles: [String!]!
  ) {
    createHarborUser(
      input: {
        organizationId: $organizationId
        firstName: $firstName
        lastName: $lastName
        email: $email
        roles: $roles
        functionOperation: "create"
      }
    ) {
      id
    }
  }
`

export default createNewUser
