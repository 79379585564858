import { useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from 'styled-components'
import Button from '../Button/Button'
import { ErrorMsg } from './SupportCaseViewForm'

import updateTicketCC from '../../graphql/updateTicketCC'

const Label = styled.span`
  font-weight: bold;
`

const Input = styled.input`
  border: 1px solid #ccc;
  padding: 8px 15px;
  width: 100%;
  border-radius: 5px;
`

const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  & > *:first-child {
    flex: 1;
  }

  button {
    margin-left: 20px;
  }
`

const initialState = {
  value: '',
  emails: [],
  touched: false,
  error: '',
}

const CC = ({ ticketId }) => {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(initialState)
  const [addCCs] = useMutation(updateTicketCC, {
    variables: { id: ticketId, emailCCs: form.emails },
  })

  const validate = x => !x.length ? 'Please fill cc field!' : ''
  const handleChange = e => setForm(() => {
    const emails = e.target.value
      .split(',')
      .map(x => x.trim())
      .filter(Boolean)

    return {
      value: e.target.value,
      emails,
      touched: true,
      error: validate(emails)
    }
  })

  const handleClick = async () => {
    try {
      const error = validate(form.emails)
      if (error) return setForm(form => ({ ...form, error }))

      setLoading(true)
      await addCCs();
      setForm(initialState)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Label>Add CC (Optional)</Label>
      <InputRow>
        <Input value={form.value} placeholder='Add emails' onChange={handleChange} />
        <Button className='support-cases-add-cc-button' disabled={loading} isSubmitting={loading} onClick={handleClick}>
          Add CCs
        </Button>
      </InputRow>
      <span>Enter e-mail addresses separated by comma.</span>
      {form.error && <ErrorMsg>{form.error}</ErrorMsg>}
    </>
  )
}

export default CC
