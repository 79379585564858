import { gql } from '@apollo/client'

const getDocuments = gql`
  query getDocs(
    $organizationId: String!
    $filter: MainDocumentsFilter
    $pageSize: Int
    $pageToken: String
  ) {
    listDocuments(
      organizationId: $organizationId
      filter: $filter
      pageSize: $pageSize
      pageToken: $pageToken
    ) {
      files {
        id
        name
        publishedDate
        publishedBy {
          id
        }
        type
      }
    }
  }
`

export default getDocuments
