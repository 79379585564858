import { gql } from '@apollo/client'

const GET_RECOMMENDATION = gql`
  query Recommendation ($id: String!) {
    getRecommendation(id: $id) {
        id
        organizationId
        title
        awsPillar
        status
        hoursEstimate
        createdDateTime
        shortDescription
        description
        highPriority
    }
  }
`

export default GET_RECOMMENDATION
