import { useState } from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import useGlobalSearch from '../../hooks/useGlobalSearch'
import useDebounce from '../../hooks/useDebounce'
import useAuth from '../../hooks/useAuth'
import usePagination from '../../hooks/usePagination'
import Pagination from '../../components/Pagination/Pagination'
import Heading from '../../components/Heading/Heading'
import SupportCasesTable from '../../components/SupportCasesTable/SupportCasesTable'
import Filter from '../../components/Filter/Filter'
import Search from '../../components/Search/Search'
import Button from '../../components/Button/Button'
import getTickets from '../../graphql/getTickets'
import { transformTicket } from '../../utils'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 45px;

  margin-bottom: 44px;

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`

const Col = styled.div`
  display: flex;
  margin-bottom: 35px;

  @media only screen and (max-width: 1410px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

const Row = styled.div`
  flex: 1;
  margin-left: 20px;

  display: grid;
  grid-template-columns: 153px 200px 1fr max-content;
  grid-gap: 20px;
  align-items: center;

  input {
    justify-self: end;
  }

  @media only screen and (max-width: 1410px) {
    margin: 20px 0;
  }

  @media only screen and (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);

    input {
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`
const caseOptions = [
  { label: 'My Cases', value: true },
  { label: 'All Cases', value: false },
]

const statusOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Awaiting Reply', value: 'pending' },
  { label: 'Solved', value: 'solved' },
]

const TICKETS_PER_PAGE = 25

const SupportCases = () => {
  const match = useRouteMatch()
  const { selectedOrg } = useAuth()
  const [searched, setSearched] = useState('')
  const [selected, setSelected] = useState({
    ticketCase: caseOptions[1],
    status: undefined,
  })
  const debouncedQuery = useDebounce(searched)
  const { searchQuery } = useGlobalSearch()
  const { data, loading, error } = useQuery(getTickets, {
    variables: {
      input: {
        currentUser: selected.ticketCase?.value,
        organizationId: selectedOrg.value,
        searchQuery: debouncedQuery || searchQuery,
        ...({
          statuses:
            selected?.status?.value === 'open'
              ? ['open', 'new', 'hold']
              : selected?.status?.value === 'pending'
                ? ['pending']
                : selected?.status?.value === 'solved'
                ? ['solved', 'closed']
                : ['open', 'new', 'hold', 'pending'], // default the view to show all open and pending status tickets.
        }),
        solvedAfterDays: 90,
        limit: 300,
      },
    },
  })

  const tickets = data?.listZendeskTickets.items.map(transformTicket)
  const paginate = usePagination(tickets, TICKETS_PER_PAGE)

  const handleFilter = (event, type) => {
    const ticketCase = type === 'CASE' ? event : selected.ticketCase
    const status = type === 'STATUS' ? event : selected.status

    setSelected({
      ticketCase,
      status,
    })
    if (event?.target) setSearched(event.target.value)
  }

  return (
    <Box>
      <Col>
        <Heading>Support Cases</Heading>
        <Row>
          <Filter
            className='filter-my-support-cases'
            placeholder='All Cases'
            handleFilter={e => handleFilter(e, 'CASE')}
            options={caseOptions}
            defaultValue={selected.ticketCase}
            selected={selected.ticketCase}
          />
          <Filter
            className='filter-support-cases-statuses'
            placeholder='Open & Awaiting Reply'
            handleFilter={e => handleFilter(e, 'STATUS')}
            options={statusOptions}
            selected={selected.status}
            isClearable={true}
          />
          <Search
            className='support-cases-search'
            placeholder='Search Cases'
            searched={searched}
            handleChange={handleFilter}
          />
          <Link to={`${match.path}/new`}>
            <Button>Create</Button>
          </Link>
        </Row>
      </Col>
      <SupportCasesTable
        tickets={paginate.currentData}
        loading={loading}
        error={error}
      />
      {paginate?.currentData.length > 0 && <Pagination paginate={paginate} />}
    </Box>
  )
}

export default SupportCases
