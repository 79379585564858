import { gql } from '@apollo/client'

const UPDATE_RECOMMENDATION = gql`
  mutation updateRecommendation ($id: String!, $recommendation: RecommendationInput!) {
    updateRecommendation (id: $id, recommendation: $recommendation) {
      id
      title
      description
      awsPillar
    }
  }
`

export default UPDATE_RECOMMENDATION
