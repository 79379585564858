import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CC from '../SupportCaseViewForm/CC'
import { getTicketLinkedResourcePath } from '../../utils'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: currentColor;
`

const Box = styled.div`
  max-width: 600px;
  color: #4b5882;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #a5acc2;
  align-self: flex-start;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`

const CCRow = styled(Row)`
  flex-direction: column;
`

const Col = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const Label = styled.span`
  font-weight: bold;
`

const Info = styled.p`
  line-height: 1.4;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Tag = styled.span`
  display: inline-block;
  align-self: flex-start;
  background-color: #00bfff;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  padding: 4px 8px;
`

const TicketInfo = ({
  status,
  priority,
  submittedBy,
  assignedTo,
  linkedTo,
  followers,
  ticketId,
}) => {
  const resourcePath = getTicketLinkedResourcePath(linkedTo)

  return (
    <Box>
      <Row>
        <Label>Status</Label>
        <Col>
          <Tag>{resourcePath ? <StyledLink to={resourcePath}>{status}</StyledLink> : status}</Tag>
        </Col>
      </Row>
      <Row>
        <Label>Priority</Label>
        <Col>
          <Info>{priority}</Info>
        </Col>
      </Row>
      <Row>
        <Label>Submitted by</Label>
        <Col>
          <Info>{submittedBy}</Info>
        </Col>
      </Row>
      {assignedTo && (
        <Row>
          <Label>Assigned to</Label>
          <Col>
            <Info>{assignedTo}</Info>
          </Col>
        </Row>
      )}
      {followers.length > 0 && (
        <Row>
          <Label>CC's</Label>
          <Col>
            {followers.map(({ ZDUserAttributes: { email } }, idx) => (
              <Info key={idx} title={email}>
                {email}
              </Info>
            ))}
          </Col>
        </Row>
      )}
      <CCRow>
        <CC style={{ marginTop: '10px' }} ticketId={ticketId} />
      </CCRow>
    </Box>
  )
}

export default TicketInfo
