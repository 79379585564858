import styled, { css } from 'styled-components'

const contentLeft = css`
  top: 50%;
  right: 42px;
  transform: translateY(-50%);
`

const contentBottom = css`
  top: 42px;
  right: 50%;
  transform: translateX(50%);
`

const triangleLeft = css`
  clip-path: polygon(0 0, 0% 100%, 50% 50%);
  top: 50%;
  right: -21px;
  transform: translateY(-50%);
`

const triangleBottom = css`
  clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
  top: -21px;
  left: 50%;
  transform: translateX(-50%);
`

const StyledTooltip = styled.div`
  position: relative;

  &:hover {
    span:last-child {
      visibility: visible;
      opacity: 0.82;
    }
  }
`

const Text = styled.span`
  display: block;
  visibility: hidden;
  padding: 26px 28px;
  width: 200px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  hyphens: auto;
  background-color: #313943;
  opacity: 0;
  border-radius: 15px;
  position: absolute;
  ${p => (p.position === 'left' ? contentLeft : contentBottom)}
  z-index: 1;
  transition: 0.3s;

  @media only screen and (max-width: 420px) {
    ${contentLeft}
  }

  &::before {
    content: '';
    width: 22px;
    height: 22px;
    background-color: #313943;
    position: absolute;
    ${p => (p.position === 'left' ? triangleLeft : triangleBottom)};
    z-index: 2;

    @media only screen and (max-width: 420px) {
      ${triangleLeft}
      left: initial;
    }
  }
`

const Tooltip = ({ children: Component, content, position, html = false, ...rest }) => {
  return (
    <StyledTooltip {...rest}>
      {Component}
      {html ? (
        <Text
          position={position}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <Text position={position}>{content}</Text>
      )}
    </StyledTooltip>
  )
}

export default Tooltip
