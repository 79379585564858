import { gql } from '@apollo/client'

const getHoursSummarybyOrg = gql`
  query getHoursSummarybyOrg ($organizationId: String!) {
    getHoursSummarybyOrg (organizationId: $organizationId) {
      consumed
      budget
      estimated
    }
  }
`

export default getHoursSummarybyOrg
