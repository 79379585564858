import { gql } from '@apollo/client'

const getUsers = gql`
  query($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      domainWhitelist
      users(limit: 1000) {
        items {
          id
          roles
          user {
            id
            firstName
            lastName
            email
            slack
            phone
            disabled
            recommendationEmailIsEnabled
          }
        }
      }
    }
  }
`

export default getUsers
