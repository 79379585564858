import { gql } from '@apollo/client'

const getTam = gql`
query getOrganization($id: ID!){
    getOrganization(id: $id){
        id
        tam{
            id
            email
            firstName
            lastName
            slack
            phone
        }
    }
}
`

export default getTam