import styled from 'styled-components'
import React, { useState, useEffect } from 'react';

const Button = styled.button`
  background-color: #244d85;
  border: none;
  outline: none;
  border-radius: 20px;
  cursor: pointer;
  height: 32px;
  width: 100%;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  transition: 0.2s;
  margin-top: 10px;

  &:hover {
    background-color: #1d3e6b;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 auto;
`;


const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
`;


const StyledInput = styled.input`
  padding: 10px;
  flex: 1;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;


const StyledLabel = styled.label`
  font-weight: bold;
  width: 100px;
`;


const ErrorText = styled.p`
  color: red;
  font-size: 12px;
  margin-left: 110px; // To align with the inputs
`;


const getUrlParameter = (name) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

const SignUp = ({ onFormSubmit }) => {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        companyName: ''
      });
      
      const [errors, setErrors] = useState({
        email: '',
        name: '',
        companyName: '',
        submit: ''
      });
      
      const [token, setToken] = useState(null);
    
    
      useEffect(() => {
        /* const fetchedToken = getUrlParameter('x-amzn-marketplace-token'); */
        const fetchedToken = getUrlParameter('x-amzn-marketplace-token')
        if (fetchedToken) {
          console.log("Received token:", fetchedToken);
          setToken(fetchedToken);
        }
      }, []);

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
    
        if (value) {
          setErrors(prev => ({ ...prev, [name]: '' }));
        }
    
        if (name === 'email') {
          validateEmail(value);
        }
      };
    
      const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(email)) {
          setErrors(prev => ({ ...prev, email: 'Invalid email format' }));
        } else {
          setErrors(prev => ({ ...prev, email: '' }));
        }
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        let validationErrors = {};
    
        // Check if fields are empty
        if (!formData.email) validationErrors.email = 'Email is required';
        if (!formData.name) validationErrors.name = 'Name is required';
        if (!formData.companyName) validationErrors.companyName = 'Company Name is required';
    
        // Validate email format again
        if (formData.email && !validationErrors.email) {
          validateEmail(formData.email);
        }
    
        // Merge the new validation errors with any existing errors
        setErrors(prev => ({ ...prev, ...validationErrors }));
    
        if (Object.keys(validationErrors).length === 0 && !errors.email) {
          console.log(formData);
          const payload = {
            ...formData,
            regToken: token
          };
          fetch(process.env.REACT_APP_API_ENTITLEMENTS_ENDPOINT + "/subscribe", {
            method: 'POST',
            
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            if (data.status === 'error') {
              throw new Error('Server returned an error status');
            }
            if (data.status === 'ok') {
              onFormSubmit(payload);
            }
          })
          .catch(err => {
            setErrors({submit: err.message});
          });
        }
      };
    
      return (
        <>
        <FormContainer>
            <FieldContainer>
            <StyledLabel>Email:</StyledLabel>
            <StyledInput
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                autocomplete="email"
            />
            </FieldContainer>
            {errors.email && <ErrorText>{errors.email}</ErrorText>}
            
            <FieldContainer>
            <StyledLabel>Name:</StyledLabel>
            <StyledInput
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                autocomplete="name"
            />
            </FieldContainer>
            {errors.name && <ErrorText>{errors.name}</ErrorText>}
            
            <FieldContainer>
            <StyledLabel>Company Name:</StyledLabel>
            <StyledInput
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                autocomplete="organization"
            />
            </FieldContainer>
            {errors.companyName && <ErrorText>{errors.companyName}</ErrorText>}
            
            <Button onClick={handleSubmit}>Sign Up</Button>
        </FormContainer>
        </>
    )
}

export default SignUp