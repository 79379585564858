import { gql } from '@apollo/client'

const viewDoc = gql`
  query viewDoc($organizationId: String!, $fileId: String!) {
    viewDocument(organizationId: $organizationId, fileId: $fileId) {
      url
    }
  }
`

export default viewDoc
