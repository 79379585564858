import { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import OrgSwitcher from '../OrgSwitcher/OrgSwitcher'
import useAuth from '../../hooks/useAuth'

const blink = keyframes`
  0% { background: #4b5882; }
  50% { background: #00bfff; }
  100% { background: #4b5882; }
`

const Box = styled.div`
  min-width: 200px;
  background-color: #e3e9ef;
  border-radius: 10px;
  text-transform: capitalize;
  font-weight: bold;

  display: flex;
  align-items: center;
`

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const Label = styled.p`
  color: #a5acc2;
  font-size: 10px;
  margin-bottom: 2px;

  @media only screen and (max-width: 920px) {
    font-size: 12px;
  }
`

const Org = styled.h1`
  color: #4b5882;
  font-size: 12px;

  ${p => p.isMenuOpen && css`
    &::after {
      content: '';
      display: inline-block;
      background-color: #4b5882;
      margin-left: 2px;
      animation: ${blink} 1.2s infinite;
      width: 2px;
      height: 9px;
    }
  `}

  @media only screen and (max-width: 920px) {
    font-size: 14px;
  }
`

const Organization = () => {
  const [selecting, setSelecting] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { selectedOrg } = useAuth()

  return (
    <Box>
      <Col>
        <Label>Currently Viewing</Label>
        <Org isMenuOpen={isMenuOpen}>{selecting || selectedOrg.label}</Org>
      </Col>
      <OrgSwitcher
        handleValueChange={value => setSelecting(value)}
        setIsMenuOpen={setIsMenuOpen}
      />
    </Box>
  )
}

export default Organization
