import styled from 'styled-components'

const Box = styled.div`
  width: 100%;
  position: relative;
  height: 12px;
`

const Bar = styled.div`
  width: 100%;
  background-color: #F1F4F7;
  border-radius: 12px;
  height: 100%;
`

const Track = styled.div`
  --red: #ff4140;
  --blue: #00BFFF;

  width: ${p => p.width}%;
  height: 100%;
  background-color: ${p => !!p.isOverdue ? 'var(--red)' : 'var(--blue)'};
  box-shadow: 0 6px 16px 0 rgba(0,191,255,0.3);
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
`

const Progress = ({ percent, isOverdue }) => {

  return (
    <Box>
      <Bar />
      <Track isOverdue={isOverdue} width={isOverdue ? 100 : percent} />
    </Box>
  )
}

export default Progress
