import styled from 'styled-components'

const Row = styled.div`
  display: inline-flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
`

const Square = styled.span`
  height: 17px;
  width: 17px;
  background-color: ${p => p.active ? '#FFFFFF' : '#CAD4DE'};
  ${p => p.active &&  'border: 6px solid #00BFFF'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 2px;
`

const Text = styled.span`
  color: #000;
  font-size: 14px;
`

const Checkbox = ({ value, label, onChange }) => {

  return (
    <Row
      onClick={() => onChange(value)}
    >
      <Square active={value} />
      <Text>{label}</Text>
    </Row>
  )
}

export default Checkbox
