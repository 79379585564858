import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import Editor from '../Editor/Editor'
import Checkbox from '../Checkbox/Checkbox'
import Button from '../Button/Button'
import useDropzone from '../../hooks/useDropzone'
import createTicketComment from '../../graphql/createTicketComment'
import Drawer from '../Drawer/Drawer'
import { quillIndent } from '../../utils'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    align-self: flex-start;
  }

  margin-bottom: 10px;

  & > div {
    flex: 1;
    margin-right: 10px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  width: 642px;
  padding: 50px;
  font-size: 14px;

  @media only screen and (max-width: 920px) {
    width: 70%;
  }

  @media only screen and (max-width: 600px) {
    padding: 25px;
    width: 90%;
  }
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 42px;
`

const Title = styled.h1`
  color: #244d85;
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
`

const Icon = styled.div`
  background-color: #a5acc2;
  width: 3px;
  height: 16px;
  transform: rotate(45deg);
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    background-color: #a5acc2;

    width: 3px;
    height: 16px;
    transform: rotate(-90deg);
  }

  &:hover {
    filter: brightness(110%);
  }
`

const Col = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`

const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000;
`

export const ErrorMsg = styled.span`
  color: red;
  margin-bottom: 12px;
`

const initialState = {
  values: {
    comment: '',
    solved: false,
  },
  touched: {},
  errors: {},
}

const SupportCaseViewForm = ({ ticketId, isPriority, isOpen, toggle }) => {
  const [createComment] = useMutation(createTicketComment)
  const { dropzone, initializeDropzone, finalizePackage } = useDropzone()

  const [form, setForm] = useState(initialState)
  const [isSubmitting, setSubmitting] = useState(false)

  // eslint-disable-next-line
  useEffect(() => initializeDropzone('reply-dropzone'), [])

  const validate = values => {
    const errors = {}

    if (!values.comment) errors.comment = 'Please fill comment field!'

    return errors
  }

  const onChange = (name, value) => {
    setForm(form => {
      const values = {
        ...form.values,
        [name]: value,
      }

      const errors = validate(values)

      const newForm = {
        values,
        touched: {
          ...form.touched,
          [name]: true,
        },
        errors,
      }

      return newForm
    })
  }

  const resetForm = () => {
    setForm(initialState)
  }

  const onSubmit = async () => {
    try {
      if (isPriority) return
      const errors = validate(form.values)

      if (!!Object.keys(errors).length) {
        const touched = Object.keys(errors).reduce((obj, key) => {
          obj[key] = true
          return obj
        }, {})

        setForm(form => ({
          ...form,
          errors,
          touched,
        }))
        return
      }

      setSubmitting(true)

      const variables = {
        id: ticketId,
        comment: quillIndent(form.values.comment),
      }

      if (form.values.solved) {
        variables.status = 'solved'
      }

      if (!!dropzone.nbrOfFilesAttached) {
        const message = 'This ticket contains a file uploaded using SendSafely. To view the attachment go to the below link:'
        const attachmentURL = await finalizePackage(message)
        variables.comment += attachmentURL
      }
      // Add this to every comment that is made
      variables.comment += '<br><span style="color:white">System note: sent from Harbor ref-46947</span>'

      await createComment({ variables })
      resetForm()
      toggle()
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  const commentError = form.touched.comment ? form.errors.comment : ''
  const disabled = isSubmitting || !!Object.keys(form.errors).length

  return (
    <Drawer isOpen={isOpen}>
      <Container>
        <Head>
          <Title>Reply</Title>
          <Icon onClick={toggle} />
        </Head>
        <Col>
          <Label>Comment</Label>
          <Editor onChange={value => onChange('comment', value)} />
          {commentError && <ErrorMsg>{commentError}</ErrorMsg>}
          <Wrapper>
            <div className='reply-dropzone'></div>
            <Button disabled={disabled} isSubmitting={isSubmitting} onClick={onSubmit}>
              Add Comment
            </Button>
          </Wrapper>
          <Checkbox
            label='Please consider this solved'
            value={form.values.solved}
            onChange={() => onChange('solved', !form.values.solved)}
          />
        </Col>
      </Container>
    </Drawer>
  )
}

export default SupportCaseViewForm
