import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PrioritiesBoardGeneralCard from "../../PrioritiesBoardGeneralCard/PrioritiesBoardGeneralCard";
import AddWishListModal from "../AddWishListModal/AddWishListModal";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #244d85;
  border-radius: 10px;
  width: 100%;
  padding: 12px 26px;
  margin-bottom: 8px;
`;

const Heading = styled.h1`
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
`;

const Amount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const Span = styled.span`
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  color: #244d85;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(36, 77, 133, 0.16);
  margin-right: 16px;
  font-size: initial;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  margin-top: 22px;
  display: flex;
  align-items: center;
  background-color: #f1f4f7;
  border-radius: 15px;
  padding: 20px;
  color: #a5acc2;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  height: 80px;
  text-transform: capitalize;
`;

const Fallback = styled.p`
  font-size: 14px;
  color: #a5acc2;
  text-align: center;
  margin-top: 22px;
`;

const Swimlane = ({ heading, name, idx, cards = [], org = {} }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen((open) => !open);

  const isFirstColumn = idx === 0;

  return (
    <Box>
      <Header>
        <Heading>{heading}</Heading>
        <Amount>
          <Span>{cards.length}</Span>
        </Amount>
      </Header>
      <Droppable droppableId={name}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {cards.length < 1 ? (
              <Fallback>
                {isFirstColumn
                  ? "No tasks yet. Create one here!"
                  : "No tasks yet. Drop one here!"}
              </Fallback>
            ) : (
              cards.map((card, idx) => (
                <PrioritiesBoardGeneralCard
                  key={card.id}
                  card={card}
                  index={idx}
                  org={org}
                />
              ))
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {isFirstColumn && (
        <Button className="add-wishlist-plus-button" onClick={toggle}>
          <IconContainer>
            <FontAwesomeIcon icon={faPlus} />
          </IconContainer>
          Add new item
        </Button>
      )}
      {isOpen && <AddWishListModal isOpen={isOpen} toggle={toggle} />}
    </Box>
  );
};

export default Swimlane;
