import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Button from '../../../Button/Button'
import Radio from '../../../Radio/Radio'
import { useMutation } from '@apollo/client'
import useAuth from '../../../../hooks/useAuth'
import useListStatuses from '../../../../hooks/useListStatuses'
import UPDATE_RECOMMENDATION from '../../../../graphql/updateRecommendation'
import createPriority from '../../../../graphql/createPriority'
import {
  transformEstimate,
  valueToStatus,
  valueToSwimlane,
  formatDescription,
  htmlToText,
} from '../../../../utils'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f1f4f7;
  padding: 30px;
  width: 570px;
  border-radius: 15px;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.09);

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  svg {
    height: 25px;
    fill: #00bfff;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  @media only screen and (max-width: 340px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
`

const Title = styled.h1`
  display: -webkit-box;
  flex: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-left: 20px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  color: #00bfff;
`

const Description = styled.p`
  margin-top: 9px;
  width: 310px;
  color: #4b5882;
  font-size: 16px;
  line-height: 1.2;

  @media only screen and (max-width: 340px) {
    font-size: 14px;
    width: 236px;
  }
`

const Form = styled.form`
  margin: 18px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
`

const radioOptions = [
  {
    value: 'doit',
    label: 'Trek10 - Do It',
    className: 'top-rec-plan-radio-do-it'
  },
  {
    value: 'workwithtrek',
    label: 'Trek10 - Estimate',
    className: 'top-rec-plan-radio-work-with-trek'
  },
  {
    value: 'discuss',
    label: 'Discuss',
    className: 'top-rec-plan-radio-discuss'
  },
  {
    value: 'gettraining',
    label: 'Train Us',
    className: 'top-rec-plan-radio-train-us'
  },
  {
    value: 'fixit',
    label: 'My Team Will Do It',
    className: 'top-rec-plan-radio-my-team-will-do-it'
  },
  {
    value: 'archive',
    label: 'Archive It',
    className: 'top-rec-plan-radio-archive-it'
  },
]

const RecommendationDetails = ({
  id,
  title,
  shortDescription,
  description,
  awsPillar,
  estimation,
  Icon,
  setActiveCard,
}) => {
  const history = useHistory()
  const { user, selectedOrg } = useAuth()
  const [selected, setSelected] = useState(null)
  const [isSubmitting, setSubmitting] = useState(false)
  const [updateRec, { loading }] = useMutation(UPDATE_RECOMMENDATION)
  const [createIssue, { loading: issueLoading }] = useMutation(createPriority)
  const listStatuses = useListStatuses()

  const notifyErrorMsg = message => toast.error(message)

  const update = async e => {
    e.preventDefault()

    try {
      if (!selected) return
      setSubmitting(true)

      if (!['ARCHIVED', 'CLIENT_HANDLED'].includes(valueToStatus(selected))) {
        const priority = await createIssue({
          variables: {
            organizationId: selectedOrg.value,
            issue: {
              active: true,
              title,
              description: formatDescription(
                `Created from the Trek10 recommendation ${title}.
                ${description ? htmlToText(description) : ''}
                Original Estimate ${transformEstimate(estimation)} hours
                Created by: ${user['email']}`
              ),
              status: valueToSwimlane(selected, listStatuses),
              hoursEstimate: transformEstimate(estimation),
              awsPillar: awsPillar?.toLowerCase(),
              type: 'recommendations',
              originalLink: 'rec:' + id
            },
          },
          context: { version: 4 },
        })

        if (priority?.errors) {
          setSubmitting(false)
          throw new Error('Failed to create priority')
        }
      }

      const rec = await updateRec({
        variables: {
          id,
          recommendation: {
            active: false,
            status: valueToStatus(selected),
          },
        },
        context: {
          version: 1,
        },
      })

      if (rec?.errors) {
        setSubmitting(false)
        throw new Error('Failed to plan recommendation')
      }

      setSubmitting(false)
      setActiveCard(null)

      if (!['ARCHIVED', 'CLIENT_HANDLED'].includes(valueToStatus(selected))) return history.push('/priority-board')
    } catch (err) {
      notifyErrorMsg(err.message)
    }
  }

  return (
    <Container className='top-rec-details'>
      <Col>
        <Row>
          <Icon />
          <Title title={title}>{title}</Title>
        </Row>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </Col>
      <Form>
        {radioOptions.map((item, idx) => {
          return (
            <Radio
              className={item.className}
              key={idx}
              value={item.value}
              active={selected === item.value}
              label={item.label}
              onChange={value => setSelected(value)}
            />
          )
        })}
      </Form>
      <Row>
        <Button
          className='top-rec-cancel-btn'
          onClick={() => setActiveCard(null)}
          style={{ backgroundColor: '#FFFFFF', marginRight: '10px' }}
          letterSpacing='2px'
          fontSize='10px'
          isSecondary
        >
          Cancel
        </Button>
        <Button
          className='top-rec-save-btn'
          onClick={update}
          letterSpacing='2px'
          fontSize='10px'
          disabled={loading || issueLoading}
          isSubmitting={isSubmitting}
        >
          Save
        </Button>
      </Row>
    </Container>
  )
}

export default RecommendationDetails
