import { gql } from '@apollo/client'

const updateUser = gql`
  mutation updateUser(
    $id: String!
    $firstName: String
    $lastName: String
    $phone: String
    $slack: String
    $recommendationEmailIsEnabled: Boolean
  ) {
    updateUser(
      input: {
        auth0UserId: $id
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        slack: $slack
        recommendationEmailIsEnabled: $recommendationEmailIsEnabled
      }
    ) {
      id
    }
  }
`

export default updateUser
