import { gql } from '@apollo/client'

const getPriority = gql`
  query($id: String!) {
    getIssue(id: $id) {
      id
      title
      description
      status
      priority
      type
      comments {
        id
        body
        created
        email
      }
      hoursEstimate
      hoursWorked
      awsPillar
      createdDateTime
      updatedDateTime
    }
  }
`

export default getPriority
