import styled from 'styled-components'

import searchIcon from '../../assets/images/search-icon.png'

const Input = styled.input`
  width: 236px;
  background-color: #f1f4f7;
  padding: 9.5px 20px 9.5px 47px;
  color: #a5acc2;
  font-size: 14px;
  font-weight: bold;
  background-image: url(${searchIcon});
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 20px center;
  border: 0;
  outline: none;
  border-radius: 20px;

  ::-webkit-search-cancel-button {
    display: none;
  }

  &::placeholder {
    color: #a5acc2;
  }

  @media only screen and (max-width: 920px) {
    width: 100%;
  }
`

const Search = ({ searched, placeholder, handleChange, ...rest }) => {
  return (
    <Input
      type='search'
      onChange={handleChange}
      value={searched}
      placeholder={placeholder}
      {...rest}
    />
  )
}

export default Search
