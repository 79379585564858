import { useState } from 'react'
import styled from 'styled-components'
import RecommendationDetails from './RecommendationDetails/RecommendationDetails'
import RecommendationModal from '../../RecommendationModal/RecommendationModal'
import Button from '../../Button/Button'

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background-color: #fff;
  width: 570px;
  border-radius: 15px;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.09);
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  svg {
    height: 25px;
    fill: #00bfff;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`

const Title = styled.h1`
  display: -webkit-box;
  flex: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-left: 20px;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  color: #00bfff;
`

const Description = styled.p`
  margin-top: 9px;
  width: 310px;
  color: #4b5882;
  font-size: 16px;
  line-height: 1.2;

  @media only screen and (max-width: 600px) {
    margin-bottom: 18px;
  }

  @media only screen and (max-width: 400px) {
    width: 236px;
  }
`

const Status = styled.p`
  --red: #ff4140;
  --yellow: #f3b349;

  font-size: 12px;
  font-weight: 900;
  background-color: ${p => (p.highPriority ? 'var(--red)' : 'var(--yellow)')};
  padding: 4px 15px;
  border-radius: 15px;
  color: #fff;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: 0;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const TopRecommendation = ({
  id,
  title,
  shortDescription,
  description,
  Icon,
  highPriority,
  status,
  awsPillar,
  estimation,
  activeCard,
  setActiveCard,
}) => {
  const [isOpen, setOpen] = useState(false)
  const toggle = () => setOpen(open => !open)

  if (activeCard === id)
    return (
      <RecommendationDetails
        id={id}
        title={title}
        shortDescription={shortDescription}
        description={description}
        awsPillar={awsPillar}
        estimation={estimation}
        Icon={Icon}
        setActiveCard={setActiveCard}
      />
    )

  return (
    <>
      <Container onClick={() => toggle()} className='top-recommendation'>
        {highPriority && <Status highPriority={true}>High Priority</Status>}
        <Col>
          <Row style={{ maxWidth: 350, alignItems: "unset" }} >
            <Icon />
            <Title title={title}>{title}</Title>
          </Row>
          <Row style={{ justifyContent: 'space-between' }}>
            <Description dangerouslySetInnerHTML={{ __html: shortDescription || description }} />
          </Row>
        </Col>
        <Button
          className='top-rec-plan-btn'
          onClick={e => {
            e.stopPropagation()
            setActiveCard(id)
          }}
          letterSpacing='2px'
          fontSize='10px'
        >
          Plan This
        </Button>
      </Container>
      {isOpen && (
        <RecommendationModal
          isOpen={isOpen}
          toggle={toggle}
          id={id}
          title={title}
          estimation={estimation}
          shortDescription={shortDescription}
          description={description}
          awsPillar={awsPillar}
          recStatus={status}
          Icon={Icon}
        />
      )}
    </>
  )
}

export default TopRecommendation
