import { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import useAuth from '../../../hooks/useAuth'
import Drawer from '../../Drawer/Drawer'
import Button from '../../Button/Button'
import createPriority from '../../../graphql/createPriority'
import getPriorities from '../../../graphql/getPriorities'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  width: 642px;
  padding: 50px;
  font-size: 14px;

  @media only screen and (max-width: 920px) {
    width: 70%;
  }

  @media only screen and (max-width: 600px) {
    padding: 25px;
    width: 90%;
  }
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Row = styled.div`
  margin-top: 42px;

  display: flex;
  justify-content: flex-end;
`

const Title = styled.h1`
  color: #244d85;
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
`

const InputContainer = styled.div`
  position: relative;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 42px;

  & > *:not(:last-child) {
    margin-bottom: 32px;
  }
`

const Label = styled.label`
  font-weight: bold;
  padding: 0 7px;
  color: #a5acc2;
  position: absolute;
  top: 15px;
  left: 18px;
  opacity: 0;
  z-index: 1;
  transition: 0.2s;

  &::before {
    content: '';
    background-color: #fff;
    width: 100%;
    height: 4px;
    position: absolute;
    top: 8px;
    left: 0;
    z-index: -1;
  }
`

const Input = styled.input`
  background-color: #fff;
  border: 2px solid #e3e9ef;
  border-radius: 10px;
  font-weight: bold;
  padding: 12px 20px;
  width: 100%;
  outline: none;

  &::placeholder {
    color: #a5acc2;
    opacity: 0.36;
  }

  &:focus + label {
    top: -9px;
    opacity: 1;
  }
`

const Textarea = styled.textarea`
  background-color: #fff;
  border: 2px solid #e3e9ef;
  border-radius: 10px;
  font-weight: bold;
  padding: 12px 20px;
  width: 100%;
  height: 110px;
  outline: none;

  &::placeholder {
    color: #a5acc2;
    opacity: 0.36;
  }

  &:focus + label {
    top: -9px;
    opacity: 1;
  }
`

const Icon = styled.div`
  background-color: #a5acc2;
  width: 3px;
  height: 16px;
  transform: rotate(45deg);
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    background-color: #a5acc2;

    width: 3px;
    height: 16px;
    transform: rotate(-90deg);
  }

  &:hover {
    filter: brightness(110%);
  }
`

const ErrorMsg = styled.span`
  color: red;
`

const AddWishListModal = ({ isOpen, toggle }) => {
  const { selectedOrg } = useAuth()
  const [form, setForm] = useState({
    title: '',
    description: '',
  })
  const [isSubmitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState({})
  const [createIssue, { loading, error }] = useMutation(createPriority)

  const handleChange = e => {
    const { name, value } = e.target

    setForm(prev => ({
      ...prev,
      ...{ [name]: value, },
    }))
  }

  const handleSubmit = async () => {
    try {
      const errors = validate(form)
      if (errors) return setErrors(errors)
      setErrors({})
      setSubmitting(true)

      const { title, description } = form
      const context = { version: 4 }

      const variables = {
        organizationId: selectedOrg.value,
        issue: { title, description },
      }

      const refetchQueries = [
        {
          query: getPriorities,
          variables: { organizationId: selectedOrg.value },
          context,
        },
      ]

      await createIssue({ variables, context, refetchQueries })
      setSubmitting(false)
      toggle()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Drawer isOpen={isOpen}>
      <Container>
        <Head>
          <Title>Add Team Support Task</Title>
          <Icon onClick={toggle} />
        </Head>
        <Form>
          <InputContainer>
            <Input
              type='text'
              id='title'
              name='title'
              placeholder='Type title here'
              onChange={handleChange}
            />
            <Label htmlFor='title'>Title</Label>
            {errors.title && <ErrorMsg>{errors.title}</ErrorMsg>}
          </InputContainer>
          <InputContainer>
            <Textarea
              type='textarea'
              id='description'
              name='description'
              placeholder='Type description here'
              onChange={handleChange}
            />
            <Label htmlFor='description'>Description</Label>
            {errors.description && <ErrorMsg>{errors.description}</ErrorMsg>}
          </InputContainer>
        </Form>
        {error && <ErrorMsg>{error.message}</ErrorMsg>}
        <Row>
          <Button
            className='add-wishlist-modal-submit-button'
            style={{ marginRight: '10px' }}
            fontSize='14px'
            capitalize
            onClick={handleSubmit}
            disabled={loading}
            isSubmitting={isSubmitting}
          >
            Submit
          </Button>
          <Button className='add-wishlist-modal-cancel-button'
            onClick={toggle}
            fontSize='14px'
            capitalize
            isSecondary
          >
            Cancel
          </Button>
        </Row>
      </Container>
    </Drawer>
  )
}

function validate(obj) {
  const errors = {}

  for (const [key, value] of Object.entries(obj)) {
    if (!value) errors[key] = `Please fill ${key} field!`
  }

  return !!Object.keys(errors).length ? errors : null
}

export default AddWishListModal
