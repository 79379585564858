import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ScreenLoader from '../ScreenLoader/ScreenLoader'

const Table = styled.table`
  width: 100%;
  color: #4b5882;
  font-size: 12px;
  text-align: left;
  border: 1px solid #244d85;
  border-spacing: 0;
`

const Header = styled.tr`
  background-color: #244d85;
  color: #fff;
`

const Head = styled.th`
  min-height: 45px;
  padding: 10px;
  text-transform: capitalize;
`

const User = styled.tr`
  ${p => p.isAdmin && 'cursor: pointer'};

  &:nth-child(even) {
    background-color: #f1f4f7;
  }
`

const Col = styled.td`
  height: 45px;
  padding: 10px;

  &:last-child {
    width: 70px;
  }
`

const Role = styled.span`
  --cyan: #06caed;
  --purple: #2853f6;

  display: inline-block;
  width: 70px;
  text-align: center;
  background-color: ${p =>
    p.role === 'admin'
      ? 'var(--cyan)'
      : p.role === 'disabled'
        ? 'black'
        : 'var(--purple)'};
  border-radius: 20px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  padding: 6px 0;
  text-transform: uppercase;
`

const Fallback = styled.p`
  font-size: 14px;
  color: #a5acc2;
  text-align: center;
  margin-top: 22px;
`

const Tag = styled.span`
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 0.2s;
  border-radius: 20px;
  background-color: #244d85;
  padding: 4px 8px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;

  img {
    margin-right: 5px;
  }
`

const UsersTable = ({ users = [], loading, error }) => {
  const history = useHistory()
  const { isSuperAdmin, isOrgAdmin } = useAuth()

  if (loading) return <ScreenLoader />
  if (error) return <p style={{ color: 'red' }}>{error.message}</p>

  return (
    <>
      <Table>
        <thead>
          <Header>
            <Head>Name</Head>
            <Head>Email</Head>
            <Head>Roles</Head>
          </Header>
        </thead>
        <tbody>
          {users.map(({ roles, user }) => {
            const onImpersonatedLink = e => {
              e.stopPropagation()
              const prettyId = user.id.split(':')[1]
              const authId = encodeURI(`auth0|${prettyId}`)
              const encoded = btoa(authId)
              const baseUrl = `https://manage.auth0.com/dashboard/us/trek10/users/${encoded}`
              window.open(baseUrl, '_blank')
            }

            return (
              <User
                key={user.id}
                isAdmin={isSuperAdmin || isOrgAdmin}
                {...(isSuperAdmin || isOrgAdmin) && { onClick: () => history.push(`/users/${user.id}`) }}
              >
                <Col>
                  {user.firstName} {user.lastName}
                  {isSuperAdmin && (
                    <Tag onClick={onImpersonatedLink}>
                      <span>SuperAdmin View As</span>
                    </Tag>
                  )}
                </Col>
                <Col>{user.email}</Col>
                <Col>
                  {user.disabled ? (
                    <Role role={'disabled'}>Disabled</Role>
                  ) : roles.includes('ADMIN') ? (
                    <Role role='admin'>Admin</Role>
                  ) : (
                    <Role role='user'>User</Role>
                  )}
                </Col>
              </User>
            )
          })}
        </tbody>
      </Table>
      {users.length < 1 && <Fallback>No users found!</Fallback>}
    </>
  )
}

export default UsersTable
