import { gql } from '@apollo/client'

const disableUser = gql`
  mutation disableEnableHarborUser(
    $organizationId: ID!
    $id: ID!
    $disable: Boolean!
  ) {
    disableEnableHarborUser(
      input: {
        organizationId: $organizationId
        id: $id
        disabled: $disable
        functionOperation: "disable"
      }
    ) {
      id
      disabled
    }
  }
`

export default disableUser
