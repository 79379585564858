import { gql } from '@apollo/client'

const getTicketDetails = gql`
  query getZendeskTicket($ticketId: Int!) {
    getZendeskTicket(ticketId: $ticketId) {
      id
      subject
      status
      priority
      customfields {
        id
        value
        name
      }
      assignee {
        ZDUserAttributes {
          id
          name
        }
      }
      submitter {
        ZDUserAttributes {
          id
          name
        }
      }
      comments {
        id
        author {
          ZDUserAttributes {
            id
            name
            photo {
              thumbnails {
                content_url
              }
            }
          }
        }
        html_body
        created
        public
        attachments {
          id
          content_url
          file_name
        }
      }
      organizationId
      emailCCIds
      followers {
        ZDUserAttributes {
          email
        }
      }
    }
  }
`

export default getTicketDetails
