import { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

import SecurityIcon from './icons/SecurityIcon'
import OperationsIcon from './icons/OperationsIcon'
import PerformanceIcon from './icons/PerformanceIcon'
import ReliabilityIcon from './icons/ReliabilityIcon'
import TrainingIcon from './icons/TrainingIcon'
import CostIcon from './icons/CostIcon'
import Heading from '../Heading/Heading'
import Tooltip from '../Tooltip/Tooltip'
import TopRecommendation from './TopRecommendation/TopRecommendation'
import { quillIndent } from '../../utils'

const Col = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 570px;

  @media only screen and (max-width: 920px) {
    min-width: revert;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Box = styled.div`
  margin-top: 35px;

  @media only screen and (max-width: 920px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
  }
`

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #a5acc2;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`

const Fallback = styled.p`
  color: #a5acc2;
`

const IconMap = {
  Security: SecurityIcon,
  Operations: OperationsIcon,
  Cost: CostIcon,
  Performance: PerformanceIcon,
  Training: TrainingIcon,
  Reliability: ReliabilityIcon,
}

const TopRecommendations = ({ recommendations }) => {
  const [activeCard, setActiveCard] = useState(null)

  const data = recommendations.items.map(rec => ({
    id: rec.id,
    title: rec.title,
    shortDescription: quillIndent(rec.shortDescription),
    description: quillIndent(rec.description),
    status: rec.status,
    estimation: rec.hoursEstimate,
    highPriority: rec.highPriority,
    awsPillar: rec.awsPillar,
    iconKey: rec.awsPillar,
  }))

  return (
    <Col>
      <Row>
        <Heading style={{ marginRight: '16px' }}>Top Recommendations</Heading>
        <Tooltip
          className='top-recommendations-tooltip'
          content="These recommendations are prioritized custom for your organization by
          Trek10's framework and expert team."
        >
          <Info>
            <FontAwesomeIcon icon={faQuestion} color='#e3e9ef' size='sm' />
          </Info>
        </Tooltip>
      </Row>
      <Box>
        {data.map(rec => (
          <TopRecommendation
            key={rec.id}
            id={rec.id}
            title={rec.title}
            shortDescription={rec.shortDescription}
            description={rec.description}
            Icon={IconMap[rec.iconKey]}
            highPriority={rec.highPriority}
            status={rec.status}
            estimation={rec.estimation}
            awsPillar={rec.awsPillar}
            activeCard={activeCard}
            setActiveCard={setActiveCard}
          />
        ))}
      </Box>
      {data.length < 1 && <Fallback>No recommendations found!</Fallback>}
    </Col>
  )
}

export default TopRecommendations
