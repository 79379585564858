import styled from 'styled-components'
import { Redirect } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const Box = styled.div`
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 450px;
  color: #4b5882;
`

const Heading = styled.h1`
  margin-top: 35px;
  margin-right: auto;
  color: #363636;
  display: inline-block;
  padding-bottom: 20px;
  font-size: 42px;
  font-weight: 400;
  line-height: 0.8;
  text-transform: capitalize;
  position: relative;

  &::after {
    content: '';
    width: 90px;
    height: 3px;
    background-color: #4b5882;
    color: red;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(-100%);
  }
`

const Message = styled.p`
  margin-top: 35px;
  font-size: 28px;
`

const Button = styled.button`
  background-color: #244d85;
  border: none;
  outline: none;
  border-radius: 20px;
  cursor: pointer;
  height: 32px;
  width: 100%;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  transition: 0.2s;

  &:hover {
    background-color: #1d3e6b;
  }
`

const SignOut = () => {
  const { isAuthenticated, login } = useAuth()

  if (isAuthenticated) {
    return <Redirect to='/' />
  }

  return (
    <Box>
      <header>
        <Button onClick={login}>Login</Button>
      </header>
      <div>
        <Heading>Logged out</Heading>
        <Message>Thanks for working with Trek10!</Message>
      </div>
    </Box>
  )
}

export default SignOut
