import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { parseUrl } from 'query-string'
import useDebounce from './useDebounce'

const useGlobalSearch = () => {
  const history = useHistory()
  const location = useLocation()
  const { query: { search: searchQuery }} = parseUrl(decodeURIComponent(location.search))
  const [globalQuery, setGlobalQuery] = useState(searchQuery || '')
  const debouncedQuery = useDebounce(globalQuery)

  useEffect(() => {
    if (!debouncedQuery) return history.push(location.pathname)
    history.push(`/support-cases?search=${debouncedQuery}`)

    // eslint-disable-next-line
  }, [debouncedQuery])

  return { globalQuery, searchQuery, setGlobalQuery }
}

export default useGlobalSearch
