const Trek10Logo = () => {
  return (
    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1901.17 405.71">
      <defs>
        <style>{`.b,.c{fill:#00bfff;}.d{fill:#fff;}.e,.f{fill:#244d85;}.f,.c{fill-rule:evenodd;}`}</style>
      </defs>
      <path
        class="f"
        d="M549.82,363.09H113.36c-27.98,0-52.16-16.3-63.75-39.86L225.03,147.82l69.56,69.56,121.15-121.15,204.55,204.55c-4.34,35.06-34.26,62.31-70.47,62.31Z"
      />
      <path
        class="b"
        d="M1543.2,82.8c32.45,0,56.72-9.2,61.45-48.18h39.97V362.3h-22.6c-17.8,0-32.23-14.43-32.23-32.23V124.57h-46.59v-41.77Z"
      />
      <path
        class="b"
        d="M1787.97,31.34c23.08,0,41.94,5.32,56.55,15.93,14.62,10.62,26.01,24.17,34.16,40.63,8.15,16.48,13.69,34.56,16.62,54.25,2.92,19.7,4.38,38.78,4.38,57.25s-1.46,37.55-4.38,57.25c-2.93,19.69-8.47,37.78-16.62,54.24-8.16,16.47-19.55,30-34.16,40.63-14.62,10.62-33.47,15.93-56.55,15.93s-41.94-5.31-56.55-15.93c-14.62-10.62-26.01-24.16-34.16-40.63-8.16-16.47-13.69-34.55-16.62-54.24s-4.38-38.79-4.38-57.25,1.45-37.54,4.38-57.25c2.93-19.69,8.46-37.78,16.62-54.25,8.16-16.46,19.55-30,34.16-40.63,14.62-10.61,33.47-15.93,56.55-15.93Zm0,292.23c11.38,0,20.92-3.3,28.63-9.92,7.68-6.62,13.77-15.54,18.23-26.78,4.46-11.23,7.62-24.39,9.47-39.47,1.85-15.08,2.77-31.08,2.77-48.01s-.92-32.86-2.77-47.78c-1.85-14.93-5.01-28.09-9.47-39.48-4.46-11.38-10.54-20.39-18.23-27-7.7-6.62-17.25-9.93-28.63-9.93s-21.31,3.31-28.85,9.93c-7.55,6.62-13.55,15.62-18.01,27-4.47,11.39-7.62,24.55-9.47,39.48-1.85,14.93-2.77,30.85-2.77,47.78s.92,32.93,2.77,48.01c1.85,15.08,5,28.24,9.47,39.47,4.46,11.24,10.46,20.16,18.01,26.78,7.54,6.62,17.15,9.92,28.85,9.92Z"
      />
      <path
        class="e"
        d="M885.76,124.57v20.55c0,15.32-12.42,27.74-27.74,27.74h-43.41v111.64c0,10.94,1.83,18.23,5.47,21.87,3.65,3.65,10.94,5.47,21.87,5.47,3.65,0,7.13-.15,10.49-.46,3.33-.3,6.53-.75,9.56-1.36v50.12c-5.47,.91-11.55,1.52-18.22,1.83-6.69,.3-13.21,.46-19.59,.46-10.03,0-19.53-.69-28.48-2.06-8.97-1.36-16.86-4.02-23.7-7.97-6.83-3.94-12.23-9.56-16.17-16.85-3.95-7.29-5.92-16.86-5.92-28.71V172.86h-39.18v-48.29h39.18V58.69h28.01c20.26,0,36.68,16.42,36.68,36.68v29.2h71.15Z"
      />
      <path
        class="e"
        d="M1039.61,121.32s-10.06,52.68-10.9,58.35c-2.07-.22-4.1-.39-6.1-.51-2.09-.12-4.14-.18-6.16-.18-11.8,0-21.79,1.98-29.96,5.9-8.17,3.94-14.75,9.31-19.75,16.12-4.99,6.81-8.56,14.76-10.67,23.84-2.12,9.08-3.17,18.92-3.17,29.52v105.79h-64.47v-122.54c0-99.85,62.29-130.01,151.19-116.28Z"
      />
      <path
        class="e"
        d="M1117.12,304.7c9.67,9.48,23.58,14.21,41.72,14.21,13,0,24.19-3.28,33.56-9.85,9.37-6.56,15.12-13.51,17.24-20.85h56.69c-9.07,28.41-22.98,48.74-41.73,60.95-18.75,12.22-41.42,18.33-68.03,18.33-18.45,0-35.08-2.98-49.9-8.93-14.81-5.96-27.36-14.43-37.64-25.44-10.28-10.99-18.22-24.12-23.81-39.41-5.6-15.27-8.39-32.08-8.39-50.4s2.86-34.21,8.61-49.49c5.75-15.27,13.91-28.48,24.5-39.64,10.58-11.14,23.2-19.93,37.87-26.34,14.65-6.42,30.91-9.63,48.76-9.63,19.96,0,37.34,3.9,52.16,11.69,14.81,7.79,26.98,18.26,36.51,31.39,9.52,13.14,16.4,28.11,20.63,44.91,4.24,16.81,5.75,34.37,4.54,52.7h-169.18c.91,21.08,6.19,36.36,15.88,45.82Zm72.79-124.64c-7.71-8.55-19.43-12.83-35.15-12.83-10.28,0-18.83,1.76-25.63,5.27-6.8,3.51-12.24,7.87-16.32,13.06-4.09,5.2-6.96,10.7-8.62,16.5-1.67,5.81-2.65,10.99-2.95,15.57h104.77c-3.02-16.49-8.39-29.01-16.1-37.57Z"
      />
      <path
        class="e"
        d="M1359.06,72.93V209.33l81.56-84.76h76.54l-88.84,86.57,98.87,149h-78.38l-64.7-105.25-25.06,24.15v81.11h-64.7V34.82h26.59c21.05,0,38.11,17.06,38.11,38.11Z"
      />
      <path
        class="c"
        d="M586.99,186.46l-12.28-4.32,.2-13c1.19-80.44-56.45-150.72-135.86-164.8-68.31-12.12-137.75,20.33-172.15,80.46l-8.55,14.93-15.34-7.79c-29.64-15.04-65.44-12.34-92.1,7.82-20.32,15.37-32.73,38.9-34.3,64.27l-.94,15.06-14.98,1.78C44.65,187.51,1.49,235.2,1.49,292.01c0,61.78,50.09,111.88,111.88,111.88h436.46c61.78,0,111.88-50.1,111.88-111.88,0-47.46-30.03-89.82-74.71-105.55Zm-37.17,176.63H113.36c-27.98,0-52.16-16.3-63.75-39.86L225.03,147.82l69.56,69.56,121.15-121.15,204.55,204.55c-4.34,35.06-34.26,62.31-70.47,62.31Z"
      />
      <g>
        <path
          class="d"
          d="M294.28,217.07l-34.04-34.04-35.24-35.18-82.38,82.38c72.34,57.71,71.08-56.96,151.66-13.16Z"
        />
        <path
          class="d"
          d="M498.32,178.81l-82.58-82.58-98.29,98.29c86.35,68.99,84.87-67.74,180.87-15.71Z"
        />
      </g>
    </svg>
  );
};

export default Trek10Logo;
