import { gql } from '@apollo/client'

const getTicket = gql`
  query listZendeskTickets($input: ZendeskTicketsInput) {
    listZendeskTickets(input: $input) {
      items {
        id
        division
        organizationId
        subject
        status
        updated
        customfields {
          id
          value
          name
        }
      }
    }
  }
`

export default getTicket
