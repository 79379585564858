import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import updateUser from '../../graphql/updateUser'
import getUsers from '../../graphql/getUsers'
import disableUser from '../../graphql/disableUser'
import Heading from '../Heading/Heading'
import Button from '../Button/Button'
import useAuth from '../../hooks/useAuth'
import ScreenLoader from '../ScreenLoader/ScreenLoader'
import { validate } from './NewUser'
import {
  Wrapper,
  Box,
  Col,
  Row,
  Label,
  Input,
  ErrorMsg,
  btnStyles,
} from './NewUser'
import InputMask from 'react-input-mask';

const EditWrapper = styled(Wrapper)`
  @media only screen and (max-width: 920px) {
    margin-top: 10px;
  }
`

const DangerBtn = styled(Button)`
  margin-left: auto;
  background-color: red;

  &:hover {
    background-color: #e60505;
  }
`


const RecommendationEmailTitleStyle = {
  fontWeight: "bold"
}
const RecommendationEmailSubtitleStyle = {
  fontWeight: "lighter" // todo this does not work because we don't have a file for "light" Aeonik.
}
const recommendationEmailIsEnabledRowStyle = {
  paddingLeft: 20,
  paddingTop: 20,
  paddingBottom: 60,
}
styled(Button)`
  margin-left: auto;
  background-color: red;

  &:hover {
    background-color: #e60505;
  }
`;
const MaskedInputBox = (props) => (
  <InputMask value={props.form.phone} name="phone" mask="999-999-9999" onChange={props.handleChange}>
    {(inputProps) => <Input {...inputProps}/>}
  </InputMask>
);

const EditUser = ({data = [], loading: usersLoading}) => {
  const params = useParams()
  const history = useHistory()
  const { isSuperAdmin, isOrgAdmin, selectedOrg } = useAuth()
  const [update, { loading, error }] = useMutation(updateUser)
  const [isSubmitting, setSubmitting] = useState(false)
  const [isTogglingEnable, setTogglingEnable] = useState(false)
  const [toggleEnable, { loading: enableLoading }] = useMutation(disableUser)
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    roles: [''],
    recommendationEmailIsEnabled: false
  })
  const [errors, setErrors] = useState({})
  const userInfo = data.find(({ user }) => user.id.includes(params.id))
  const organizationId = userInfo?.id.match(/o:.*/)[0]
  const user = userInfo?.user
  const refetchQueries = [
    { query: getUsers, variables: { id: selectedOrg.value } },
  ]

  useEffect(() => {
    if (user)
      setForm({
        firstName: user.firstName,
        lastName: user.lastName,
        roles: userInfo.roles,
        recommendationEmailIsEnabled: user.recommendationEmailIsEnabled ?? false,
        phone: user.phone ?? null,
      })
  }, [user, userInfo])

  const restrictedTo = [isSuperAdmin, isOrgAdmin]
  if (!restrictedTo.includes(true)) history.push('/users')

  if (usersLoading) return <ScreenLoader/>
  if (error) return <ErrorMsg>{error.message}</ErrorMsg>

  const handleChange = e => {
    const {name, value, checked} = e.target

    const newValues = {
      ...(name.includes('roles')
        ? {
          [name]: checked
            ? [...form.roles, value]
            : [...form.roles].filter(role => role !== value),
        }
        : {[name]: value}),
    }
    if (name === 'recommendationEmailIsEnabled') {
      newValues[name] = checked;
    }
    setForm(prev => ({...prev, ...newValues}))
  }

  const handleSubmit = async () => {
    try {
      const errors = validate(form)
      if (errors) return setErrors(errors)
      setErrors({})
      setSubmitting(true)
      const variables = {
        id: params.id.split(':')[1],
        firstName: form.firstName,
        lastName: form.lastName,
        recommendationEmailIsEnabled: form.recommendationEmailIsEnabled,
        phone: form.phone || null, // consider '' as null.
      }

      await update({ variables, refetchQueries })
      setSubmitting(false)
      history.push('/users')
    } catch (err) {
      console.log(err)
    }
  }

  const handleEnabling = async () => {
    try {
      setTogglingEnable(true)
      const variables = {
        organizationId,
        id: params.id,
        disable: !user.disabled,
      }

      await toggleEnable({ variables, refetchQueries })
      setTogglingEnable(false)
      history.push('/users')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <EditWrapper>
      <Row>
        <Heading>Edit User</Heading>
        {user?.disabled ? (
          <Button
            disabled={enableLoading}
            isSubmitting={isTogglingEnable} 
            style={{ marginLeft: 'auto' }}
            onClick={handleEnabling}>
            Enable
          </Button>
        ) : (
          <DangerBtn
            disabled={enableLoading}
            isSubmitting={isTogglingEnable}
            onClick={handleEnabling}
          >
            Disable
          </DangerBtn>
        )}
      </Row>
      <Box>
        <Col>
          <Label>First Name</Label>
          <Input
            name='firstName'
            value={form.firstName}
            onChange={handleChange}
          />
          {errors.firstName && <ErrorMsg>{errors.firstName}</ErrorMsg>}
        </Col>
        <Col>
          <Label>Last Name</Label>
          <Input
            name='lastName'
            value={form.lastName}
            onChange={handleChange}
          />
          {errors.lastName && <ErrorMsg>{errors.lastName}</ErrorMsg>}
        </Col>
        <Col>
          <Label>Email (Contact support@trek10.com to update email)</Label>
          <Input disabled defaultValue={user?.email}/>
        </Col>
        <Col>
          <Label>Mobile Phone # (for MFA user verification)</Label>
          <MaskedInputBox
            form={form}
            handleChange={handleChange}
          />
          {errors.phone && <ErrorMsg>{errors.phone}</ErrorMsg>}
        </Col>
        <Col>
          <Label>Roles</Label>
          <Row>
            <input
              type='checkbox'
              defaultChecked={userInfo?.roles.includes('admin'.toUpperCase())}
              id='roles'
              name='roles'
              value={'admin'.toUpperCase()}
              onChange={handleChange}
            />
            <label htmlFor='roles'>Admin</label>
          </Row>
        </Col>
      </Box>
      <Row>
        <Heading>Notifications</Heading>
      </Row>
      <Row style={recommendationEmailIsEnabledRowStyle}>
        <input
          type='checkbox'
          defaultChecked={user.recommendationEmailIsEnabled}
          id="recommendationEmailIsEnabled"
          name='recommendationEmailIsEnabled'
          onChange={handleChange}
        />
        <label htmlFor='recommendationEmailIsEnabled'>
          <p style={RecommendationEmailTitleStyle}>Recommendation Email</p>
          <p style={RecommendationEmailSubtitleStyle}>Suggested by Expert Architects to optimize your environment</p>
        </label>
      </Row>
      <Row>
        <Button isSecondary onClick={() => history.push('/users')}>
          Cancel
        </Button>
        <Button disabled={loading} isSubmitting={isSubmitting} onClick={handleSubmit} style={btnStyles}>
          Save
        </Button>
      </Row>
    </EditWrapper>
  )
}

export default EditUser
