const PerformanceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 290 290">
      <g>
        <path d="M73.768 78.316l-25.124 1.941c-6.991.541-13.218 4.204-17.084 10.053L2.584 134.134a15.46 15.46 0 00-1.248 14.847 15.467 15.467 0 0011.825 9.065l23.005 3.562c5.381-28.559 18.263-57.047 37.602-83.292M128.393 253.835l3.563 23.004a15.464 15.464 0 009.064 11.825 15.557 15.557 0 006.294 1.337c2.992 0 5.964-.872 8.554-2.584l43.825-28.976c5.848-3.867 9.512-10.094 10.051-17.083l1.942-25.124c-26.247 19.337-54.734 32.219-83.293 37.601M119.741 237.89c.799 0 1.602-.066 2.403-.199 11.965-2 23.497-5.371 34.497-9.782L62.092 133.36c-4.411 11-7.781 22.532-9.782 34.497-.788 4.717.794 9.521 4.175 12.903l52.757 52.757c2.807 2.805 6.596 4.373 10.499 4.373M266.877 128.575c23.137-44.731 23.993-92 22.784-114.169A14.892 14.892 0 00275.594.339 234.879 234.879 0 00262.884 0c-24.789 0-64.013 3.755-101.459 23.125-29.76 15.393-68.784 49.287-91.807 93.83.271.213.537.438.788.688l101.953 101.953c.251.251.475.515.687.788 44.543-23.025 78.437-62.048 93.831-91.809m-98.26-67.267c16.563-16.563 43.512-16.564 60.075 0 8.025 8.023 12.443 18.692 12.443 30.038 0 11.347-4.418 22.015-12.443 30.037-8.28 8.281-19.161 12.422-30.037 12.423-10.88.001-21.755-4.14-30.037-12.423-8.024-8.023-12.443-18.691-12.443-30.037-.001-11.347 4.418-22.015 12.442-30.038" />
        <path d="M180.632 109.368c9.937 9.937 26.108 9.938 36.046 0 4.814-4.815 7.465-11.214 7.465-18.023 0-6.808-2.651-13.208-7.465-18.022-4.968-4.969-11.495-7.454-18.023-7.454-6.526 0-13.053 2.485-18.022 7.454-4.813 4.814-7.465 11.214-7.465 18.022-.001 6.809 2.65 13.21 7.464 18.023M8.669 238.987a8.466 8.466 0 006.007-2.488l27.738-27.739a8.496 8.496 0 10-12.015-12.015L2.661 224.484a8.496 8.496 0 006.008 14.503M67.835 222.165a8.496 8.496 0 00-12.014 0L2.49 275.495a8.497 8.497 0 006.008 14.503 8.463 8.463 0 006.007-2.489l53.329-53.328a8.496 8.496 0 00.001-12.016zM81.24 247.586l-27.737 27.739a8.494 8.494 0 000 12.014 8.47 8.47 0 006.007 2.489 8.465 8.465 0 006.007-2.489l27.738-27.738a8.496 8.496 0 10-12.015-12.015" />
      </g>
    </svg>
  )
}

export default PerformanceIcon
