import { gql } from '@apollo/client'

const createZendeskTicket = gql`
  mutation createZendeskTicket(
    $attachments: [String]
    $comment: String!
    $organizationId: ID!
    $priority: ZENDESK_TICKET_PRIORITY
    $subject: String!
    $cc: [String]
  ) {
    createZendeskTicket(
      input: {
        attachments: $attachments
        comment: $comment
        organizationId: $organizationId
        priority: $priority
        subject: $subject
        emailCCs: $cc
        tags: ["harborwebform"]
      }
    ) {
        id
    }
  }
`

export default createZendeskTicket
