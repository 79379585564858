import { gql } from '@apollo/client'

const getListStatuses = gql`
  query listStatuses($organizationId: String!) {
    listStatuses(organizationId: $organizationId) {
      items {
        name
        friendlyName
        order
        type
      }
    }
  }
`

export default getListStatuses
