import { gql } from '@apollo/client'

const getUser = gql`
  query($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      disabled
      email
      lastName
      phone
      recommendationEmailIsEnabled
      slack
    }
  }
`

export default getUser
