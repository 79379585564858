import { useState, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation } from "@apollo/client";
import useAuth from "../../hooks/useAuth";
import Search from "../../components/Search/Search";
import Board from "../../components/Board/Board";
import AddWishListModal from "../../components/Board/AddWishListModal/AddWishListModal";
import Heading from "../../components/Heading/Heading";
import getPriorities from "../../graphql/getPriorities";
import updatePriority from "../../graphql/updatePriority";

const Box = styled.div`
  padding-right: 45px;
  margin-bottom: 44px;

  @media only screen and (max-width: 920px) {
    padding: 0 18px;
  }
`;

const Head = styled.div`
  margin-bottom: 35px;

  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 20px;
  align-items: center;

  @media only screen and (max-width: 920px) {
    margin-top: 10px;
  }

  @media only screen and (max-width: 600px) {
    margin-top: 0;
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 20px;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-top: 0;
    grid-template-columns: 1fr max-content;
    width: 100%;
  }
`;

const Button = styled.button`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border: none;
  outline: none;
  border-radius: 50%;
  color: #a5acc2;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(36, 77, 133, 0.16);
`;

const PrioritiesBoard = () => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen((open) => !open);
  const { selectedOrg } = useAuth();
  const context = { version: 4 };
  const { data, loading, error, refetch } = useQuery(getPriorities, {
    variables: { organizationId: selectedOrg.value },
    context,
  });
  const [updateCardStatus] = useMutation(updatePriority);
  const [priorities, setPriorities] = useState([]);
  const [searched, setSearched] = useState([]);
  const cards = searched.length > 0 ? searched : priorities;

  useEffect(() => {
    if (data) setPriorities(data.listIssues.items);
  }, [data]);

  const handleSearch = (e) => {
    const filtered = priorities.filter((card) =>
      card.title.toLowerCase().includes(e.target.value.toLowerCase().trim())
    );

    setSearched(filtered);
  };

  const handleDragEnd = async (result) => {
    try {
      const { destination, source, draggableId } = result;

      // If user draggs a card and drops it outside of a column
      // or drops in the same column, do nothing
      if (!destination) return;
      if (destination.droppableId === source.droppableId)
        return showDndMessage();

      // Update local state
      const localPriorities = priorities.map((card) => {
        if (card.id !== draggableId) return card;
        return { ...card, status: destination.droppableId };
      });

      setPriorities(localPriorities);

      // Fetch after local is updated and replace local
      const variables = {
        id: draggableId,
        issue: { status: destination.droppableId },
      };

      await updateCardStatus({ variables, context });
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const showDndMessage = () =>
    toast.info(
      "Reordering is currently not available please reach out to a Trek10 team member to change the order of priorities."
    );

  return (
    <Box>
      <Head>
        <Heading>Tasks</Heading>
        <Row>
          <Search
            className="priorities-board-search"
            placeholder="Search Tasks"
            handleChange={handleSearch}
          />
          <Button className="priority-plus-button" onClick={toggle}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Row>
      </Head>
      <Board
        priorities={cards}
        loading={loading}
        error={error}
        handleDragEnd={handleDragEnd}
      />
      {isOpen && <AddWishListModal isOpen={isOpen} toggle={toggle} />}
    </Box>
  );
};

export default PrioritiesBoard;
