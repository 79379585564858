import styled from "styled-components";

const Box = styled.div`
  max-width: 600px;
  color: #4b5882;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #a5acc2;
  align-self: flex-start;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`;
const Col = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Info = styled.p`
  line-height: 1.4;
  position: relative;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PriorityInfo = ({
  taskId,
  status,
  orgFeatures = [],
  organization,
  hoursWorked,
  hoursEstimate,
}) => {
  return (
    <Box>
      <Row>
        <Label>Task ID</Label>
        <Col>
          <Info>{taskId}</Info>
        </Col>
      </Row>
      <Row>
        <Label>Status</Label>
        <Col>
          <Info>{capitalize(status)}</Info>
        </Col>
      </Row>
      <Row>
        <Label>Organization</Label>
        <Col>
          <Info>{organization}</Info>
        </Col>
      </Row>
      {!orgFeatures.includes("PRIORITIES_HideHoursWorked") && (
        <Row>
          <Label>Hours Worked</Label>
          <Col>
            <Info>{hoursWorked || "-"}</Info>
          </Col>
        </Row>
      )}
      {!orgFeatures.includes("PRIORITIES_HideHoursEstimated") && (
        <Row>
          <Label>Hours Estimate</Label>
          <Col>
            <Info>{hoursEstimate || "-"}</Info>
          </Col>
        </Row>
      )}
    </Box>
  );
};

function capitalize(str) {
  if (!str) return;

  return str
    .split(/(?=[A-Z])/)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export default PriorityInfo;
