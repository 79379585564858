const ReliabilityIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 290 290">
      <g>
        <path d="M92.017 246.052c-13.269 13.279-34.796 13.279-48.066 0-13.07-13.06-13.278-34.125-.616-47.441l71.631-71.63c-22.153-5.924-46.718-.471-64.429 16.371l-31.849 31.849c-25.324 26.63-24.907 68.759 1.233 94.882 26.539 26.557 69.592 26.557 96.131 0l29.366-29.366c17.8-17.787 23.667-42.992 17.607-65.673l-71.008 71.008zM270.083 19.921c-26.122-26.14-68.252-26.558-94.882-1.233l-31.849 31.849c-16.842 17.711-22.295 42.275-16.371 64.428l71.63-71.631c13.315-12.662 34.38-12.453 47.441.617 13.278 13.27 13.278 34.796 0 48.066l-71.008 71.008c22.68 6.06 47.886.193 65.673-17.607l29.365-29.366c26.557-26.539 26.557-69.592.001-96.131" />
        <path d="M93.329 196.672c6.635 6.64 17.399 6.64 24.033 0l79.31-79.31c6.639-6.634 6.639-17.399 0-24.032-6.531-6.536-17.063-6.64-23.72-.308L93.02 172.953c-6.33 6.656-6.226 17.189.309 23.719" />
      </g>
    </svg>
  )
}

export default ReliabilityIcon
