import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import { useQuery } from "@apollo/client";
import Swimlane from "./Swimlane/Swimlane";
import ScreenLoader from "../ScreenLoader/ScreenLoader";
import useListStatuses from "../../hooks/useListStatuses";
import useAuth from "../../hooks/useAuth";
import getOrgFeatures from "../../graphql/getOrgFeatures";

const Box = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-gap: 22px;

  @media only screen and (max-width: 920px) {
    justify-content: center;
  }
`;

const ErrorMsg = styled.p`
  color: red;
`;

const Board = ({ priorities = [], loading, error, handleDragEnd }) => {
  const listStatuses = useListStatuses();
  const { selectedOrg } = useAuth();
  const orgFeatures = useQuery(getOrgFeatures, {
    variables: { id: selectedOrg.value },
  });

  loading = loading || orgFeatures?.loading;
  const errors = error || orgFeatures?.error;

  if (loading) return <ScreenLoader />;
  if (errors) return <ErrorMsg>{errors.message}</ErrorMsg>;

  const columns = [...listStatuses]
    .sort((a, b) => a.order - b.order)
    .reduce((columns, column) => {
      columns[column.name] = { ...column, cards: [] };
      return columns;
    }, {});

  const swimlanes = Object.values(
    [...priorities]
      .sort((a, b) => a.index - b.index)
      .reduce(
        (swimlanes, card) => {
          swimlanes[card.status]?.cards.push(card);
          return swimlanes;
        },
        { ...columns }
      )
  );

  return (
    <Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        {swimlanes.map((column, idx) => {
          return (
            <Swimlane
              key={column.name}
              idx={idx}
              heading={column.friendlyName}
              name={column.name}
              cards={column.cards}
              org={orgFeatures?.data?.getOrganization}
            />
          );
        })}
      </DragDropContext>
    </Box>
  );
};

export default Board;
