import styled from 'styled-components'
import { animated } from 'react-spring'
import Nav from './Nav/Nav'
import UserProfile from './UserProfile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
} from '@fortawesome/free-solid-svg-icons'


const Box = styled(animated.div)`
  width: 293px;
  background-color: #244D85;
  min-height: 100vh;
  margin-right: 45px;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  transition: width 0.5s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 20px;
  margin-top: 25px;

  svg {
    height: 24px;
    width: 24px !important;
    color: #4871A8;
    cursor: pointer;
  }

  span {
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 900;
    margin-left: 44px;
  }
`

const MobileNav = ({ toggleOpen, style }) => {

  return (
    <Box style={style}>
      <Row>
        <FontAwesomeIcon icon={faTimes} onClick={toggleOpen} />
        <span>Menu</span>
      </Row>
      <Nav />
      <UserProfile />
    </Box>
  )
}

export default MobileNav
