import { gql } from '@apollo/client'

const updatePriority = gql`
  mutation updatePriority($id: String!, $issue: IssueInput!) {
    updateIssue(id: $id, issue: $issue) {
      id
      title
      description
      status
      priority
      type
      comments {
        id
        body
        created
      }
      hoursEstimate
      hoursWorked
      awsPillar
      createdDateTime
      updatedDateTime
    }
  }
`

export default updatePriority
