import styled from 'styled-components'

const StyledFooter = styled.div`
  margin-top: auto;
  margin-left: -46px;
  padding: 18px 44px;
  font-size: 12px;
  background-color: #244d85;
  text-transform: capitalize;

  display: flex;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media only screen and (max-width: 920px) {
    margin-left: 0;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 4px;
    }

    & > *:last-child {
      margin-left: initial !important;
    }
  }
`

const Mail = styled.a`
  color: white;
`
const PrivacyPolicy = styled.a`
  color: white;
`

const Label = styled.span`
  margin-right: 4px;
  font-weight: bold;
`

const Footer = () => {
  return (
    <StyledFooter>
      <p>
        <Label>Call us</Label>(888) 736-2446
      </p>
      <p>
        <Label>Email Us</Label>
        <Mail href='info@trek10.com'>info@trek10.com</Mail>
      </p>
      <p>
        <PrivacyPolicy href="https://www.trek10.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</PrivacyPolicy>
      </p>
      <p style={{ marginLeft: 'auto' }}>
        Copyright &copy; {new Date().getFullYear()} Trek10 Inc.
      </p>
    </StyledFooter>
  )
}

export default Footer
