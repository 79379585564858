import styled, { css, keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faClock } from '@fortawesome/free-solid-svg-icons'
import { useQuery, NetworkStatus } from '@apollo/client'
import getHoursSummarybyOrg from '../../graphql/getHoursSummary'
import useAuth from '../../hooks/useAuth'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 22px 32px 0px 27px;
  padding: 20px;
  background-color: #03c0ff;
  background: linear-gradient(180deg, #1163b6 0%, #00c0fd 100%);
  border-radius: 12px;
  box-shadow: 0 23px 34px 0 rgba(0, 0, 0, 0.09);
  max-width: 234px;
  width: 100%;

  svg.donut {
    width: 51px;
    height: 51px;
    margin-right: 20px;

    .stroke {
      stroke: #fff;
    }

    .ring {
      stroke: #62beec;
    }

    .overdue {
      stroke: #ff4140;
    }
  }
`

const Row = styled.div`
  display: flex;

  ${p =>
    p.ac &&
    css`
      align-items: center;
      margin-top: 20px;

      svg {
        margin-right: 7px;
      }

      span {
        font-size: 12px;
        font-weight: 500;
      }
    `}
`

const Col = styled.div`
  display: flex;
  flex-direction: column;

  ${p =>
    p.warning &&
    css`
      span:not(:first-of-type),
      p { color: #ff4140 !important; }
    `}

  span {
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    color: #fff;
    font-size: 16px;
    font-weight: 900;

    &:last-of-type {
      font-size: 10px;
      font-weight: 500;
    }
  }
`

const RefreshBtn = styled.div`
  margin-left: auto;
  cursor: pointer;

  ${p =>
    p.isLoading &&
    css`
      svg {
        animation: ${spin} 2s ease-in infinite;
      }
    `}
`

const Tool = styled.div`
  position: relative;

  span {
    display: none;
    padding: 2px 5px;
    position: absolute;
    bottom: -65px;
    left: 0;
    background: #000;
    color: #fff;
    width: 190px;
    border-radius: 3px;
    z-index: 55;
  }

  &:hover {
    span {
      display: inline-block;
    }
  }
`

const MonthlyBudget = () => {
  const { selectedOrg } = useAuth()
  const { data, loading, refetch, networkStatus } = useQuery(getHoursSummarybyOrg, {
    variables: { organizationId: selectedOrg.value },
    notifyOnNetworkStatusChange: true,
    context: { version: 4 },
  })

  const stats = {
    consumed: data?.getHoursSummarybyOrg?.consumed || 0,
    budget: data?.getHoursSummarybyOrg?.budget || 0,
    estimated: data?.getHoursSummarybyOrg?.estimated || 0,
  }

  const percent = loading ? 0 : (stats.consumed / stats.budget) * 100 || 0
  const isOverdue = loading ? false : stats.consumed > stats.budget
  const overArray = !isOverdue ? `0 0` : `${percent - 100} ${100 - percent - 100}`
  const remaining = 100 - (percent || 100)
  const dashArray = stats.consumed === stats.budget ? '1 129' : `${(129 * percent) / 100} ${(129 * remaining) / 100}`

  return (
    <Box className='budget-sidebar'>
      <Row>
        <svg width='100%' height='100%' viewBox='0 0 51 51' className='donut'>
          <circle
            className='ring'
            cx='25.5'
            cy='25.5'
            r='20.5'
            fill='transparent'
            strokeWidth='8.5px'
            strokeLinejoin='round'
            strokeLinecap='round'
          ></circle>
          <circle
            className='stroke'
            cx='25.5'
            cy='25.5'
            r='20.5'
            fill='transparent'
            strokeWidth='8.5px'
            strokeDasharray={dashArray}
            strokeDashoffset='0'
            strokeLinejoin='round'
            strokeLinecap='round'
            style={{ transform: 'rotate(-90deg)', transformOrigin: 'center' }}
          ></circle>
          {isOverdue && (
            <circle
              className='overdue'
              cx='25.5'
              cy='25.5'
              r='20.5'
              fill='transparent'
              strokeWidth='8.5px'
              strokeDasharray={overArray}
              strokeDashoffset='0'
              strokeLinejoin='round'
              strokeLinecap='round'
            ></circle>
          )}
        </svg>
        <Col warning={isOverdue}>
          <span>Monthly Budget</span>
          {isOverdue ? (
            <p>{checkDecimals(stats.consumed - stats.budget)} hours</p>
          ) : (
            <p>{checkDecimals(stats.budget - stats.consumed)} hours</p>
          )}
          <p>{isOverdue ? 'Overage' : 'Remaining'}</p>
        </Col>
        <RefreshBtn isLoading={networkStatus === NetworkStatus.refetch} onClick={() => refetch()} className='budget-sidebar-refresh'>
          <FontAwesomeIcon icon={faSync} color='#fff' size='sm' />
        </RefreshBtn>
      </Row>
      <Row ac>
        <Tool>
          <FontAwesomeIcon icon={faClock} color='#fff' size='sm' />
          <span>This is an estimate of hours consumed this month, updated hourly. It is subject to change as we review the data regularly.</span>
        </Tool>
        <span>
          Hours in backlog <b>{checkDecimals(stats.estimated)}</b>
        </span>
      </Row>
    </Box>
  )
}

function checkDecimals(num) {
  if (!num.toString().includes('.')) return num
  return num.toFixed(2)
}

export default MonthlyBudget
