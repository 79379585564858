import { gql } from '@apollo/client'

const TOP_RECOMMENDATIONS = gql`
  query topRecommendations ($organizationId: String!) {
    listTopRecommendations(organizationId: $organizationId, limit: 3) {
      items {
        id
        title
        description
        shortDescription
        hoursEstimate
        awsPillar
        status
        highPriority
      }
      nextToken
    }
  }
`

export default TOP_RECOMMENDATIONS
